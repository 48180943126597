import posthog from 'posthog-js';
import { getRecoil } from 'recoil-nexus';

import { userState } from '../../stores/user';

import segmentAnalytics from './segment';

const isProductionApp = process.env.IS_PRODUCTION_APP === 'true';

const identify = (trackingId: string, metadata: any) => {
  if (!isProductionApp) return;
  segmentAnalytics.identify(trackingId, metadata);
  posthog.identify(trackingId, metadata);
};

const page = (...args) => {
  if (!isProductionApp) return;
  segmentAnalytics.page(...args);
};

const group = (...args) => {
  if (!isProductionApp) return;
  segmentAnalytics.group(...args);
};

const track = (event: string, current_page: string, payload?: any) => {
  if (!isProductionApp) return;

  const userStateValues = getRecoil(userState);
  const theme = localStorage.getItem('joy-mode');

  const properties = {
    userid: userStateValues?.email,
    company: [userStateValues?.company],
    user_type: 'user',
    action: event,
    current_page,
    page_url: window.location.pathname,
    background_color: `${theme}_mode`,
    ...payload,
  };

  segmentAnalytics.track(event, properties);
};

export { identify, page, track, group };

import { useState, useCallback, useRef, useEffect } from 'react';

const useDebouncedInput = (initalValue, callback, delay = 400) => {
  const inputRef = useRef<HTMLDivElement | null>(null);
  const [input, setInput] = useState(initalValue);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const debounce = useCallback(
    (value) => {
      // Clear the previous timeout if any
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      // Set a new timeout
      timeoutRef.current = setTimeout(() => {
        callback(value);
        setInput(value);
      }, delay);
    },
    [callback, delay]
  );

  const updateInput = useCallback(
    (value) => {
      const input = inputRef.current ? (inputRef.current as any).querySelector('input') : null;
      if (!input) return;
      if (input && document.activeElement === input) return;
      input.value = value;
      setInput(value);
    },
    [inputRef.current, setInput]
  );

  const handleChange = useCallback(
    (e) => {
      const value = e.target.value;
      debounce(value);
    },
    [setInput, debounce]
  );

  const clearInput = useCallback(() => {
    updateInput('');
    callback('');
  }, [setInput, updateInput, callback]);

  useEffect(() => {
    if (inputRef.current) {
      (inputRef.current as any).querySelector('input').value = initalValue;
    }
  }, []);

  return [input, handleChange, updateInput, clearInput, inputRef];
};

export default useDebouncedInput;

import { useEffect, useMemo, useRef } from 'react';

import { Box, Card, Divider, Skeleton, Typography, useColorScheme } from '@mui/joy';
import { useRecoilValue } from 'recoil';

import LocationPinLight from '../../../../assets/icons/location-pin-flat-light.svg';
import LocationPin from '../../../../assets/icons/location-pin-flat.svg';
import MapLight from '../../../../assets/icons/map-light.svg';
import Map from '../../../../assets/icons/map.svg';
import { assetBaseUrl } from '../../../../services/axios/endpoints';
import { projectState } from '../../../../stores/project';
import { arrayToEnglishString } from '../../../../utils/functions/array';
import { moveToLatLng } from '../../constants';

const Location = () => {
  const { projectData, isLoading } = useRecoilValue(projectState);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const pointerRef = useRef<HTMLImageElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<HTMLImageElement | null>(null);
  const { mode } = useColorScheme();
  const states = useMemo(
    () => arrayToEnglishString(projectData?.location?.states || []),
    [projectData?.location?.states]
  );

  useEffect(() => {
    const canvas: HTMLCanvasElement | null = canvasRef.current;
    const pointer: HTMLImageElement | null = pointerRef.current;
    const map: HTMLImageElement | null = mapRef.current;
    const container: HTMLDivElement | null = containerRef.current;

    if (!canvas || !pointer || !map || !projectData || !container) return;

    const ctx = canvas.getContext('2d');
    const loadCanvas = () => {
      map.onload = function () {
        // Set canvas dimensions to match the map
        canvas.width = map.width;
        canvas.height = map.height;

        const { left, top } = moveToLatLng(
          map,
          pointer,
          projectData?.location?.longitude,
          projectData?.location?.latitude
        );

        // Draw the map onto the canvas
        ctx?.drawImage(map, 0, 0, canvas.width, canvas.height);

        pointer.onload = function () {
          ctx?.drawImage(pointer, left, top);
          container.style.display = 'none';
        };
        pointer.src = pointer.src;
      };
      map.src = map.src;
      canvas.style.width = '100%';
      canvas.style.height = '100%';
      canvas.style.visibility = 'visible';
      canvas.style.marginTop = '20px';
      canvas.style.marginLeft = '-7px';
      canvas.style.display = 'block';
      canvas.style.marginBottom = '-35px';
    };
    loadCanvas();
  }, [projectData, mode]);

  return (
    <Card sx={{ pb: 5, gap: 0, p: (theme) => theme.spacing(3) }}>
      <Typography fontSize={20} fontWeight={'xl'} color='primary'>
        Project Location
      </Typography>
      <Divider sx={{ width: 1, mx: 'auto', mt: (theme) => theme.spacing(2) }} />
      {isLoading ? (
        <Box sx={{ position: 'relative', minHeight: '200px', p: 2 }}>
          <Skeleton width={'95%'} height={'90%'} />
        </Box>
      ) : (
        <>
          <Typography
            level='body-md'
            sx={{ color: (theme) => theme.palette.text.secondary, mt: 2 }}
          >
            {states}
            {!!projectData?.location.state ? ',' : ''} {projectData?.location.country}
            {projectData?.location?.flag && (
              <Typography
                component={'span'}
                sx={{ display: 'inline-block', width: '18px', height: '18px', ml: 1 }}
              >
                <img
                  src={`${assetBaseUrl}/${projectData?.location?.flag}`}
                  style={{ width: '100%', height: '100%', objectFit: 'contain', paddingTop: 6 }}
                />
              </Typography>
            )}
          </Typography>
          <Box sx={{ position: 'relative', width: '100%' }} ref={containerRef}>
            <img
              ref={mapRef}
              style={{ width: '100%', position: 'absolute', top: 0, left: 0 }}
              src={mode === 'dark' ? Map : MapLight}
            />
            <img
              ref={pointerRef}
              style={{ position: 'absolute' }}
              src={mode === 'dark' ? LocationPin : LocationPinLight}
            />
          </Box>
          <canvas
            ref={canvasRef}
            style={{
              visibility: 'hidden',
            }}
          ></canvas>
        </>
      )}
    </Card>
  );
};

export default Location;

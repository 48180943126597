import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  Box,
  Button,
  Card,
  Chip,
  ChipDelete,
  Dropdown,
  IconButton,
  Input,
  ListDivider,
  Menu,
  MenuButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/joy';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useVirtualizer, Virtualizer } from '@tanstack/react-virtual';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { IoInformationCircleOutline, IoSearch } from 'react-icons/io5';
import { MdError } from 'react-icons/md';
import { RiFilter3Fill } from 'react-icons/ri';

import { FilterSideBar } from '../../components/filter/FilterSideBar';
import MessagePage from '../../components/MessagePage';
import Paywall from '../../components/Paywall';
import { Cell } from '../../components/table/types';
import { track } from '../../services/analytics';
import {
  EVENT_RETIREMENT_INSIGHTS_PAGE,
  EVNT_retirement_insights_filter_panel_opened,
  EVNT_retirement_table_filters_applied,
  EVNT_retirement_table_searched,
  PGV_retirement_insights,
} from '../../services/analytics/events';
import { makeAuthenticatedGetRequest, makeAuthenticatedPostRequest } from '../../services/axios';
import { allRetireeInsights, filterMetadata } from '../../services/axios/endpoints';
import { dateFormat } from '../../utils/functions/date';
import { useFetchUserPermissions } from '../../utils/hooks/useFetchUserPermission';
import useFilters from '../../utils/hooks/useFilters';
import useInfiniteScroll from '../../utils/hooks/useInfiniteScroll';

import {
  cellRenderOrder,
  columns,
  defaultFilters,
  footerCells,
  getColumnDetails,
  getFooterCells,
} from './constants';
import { RetireeInsightsTable } from './Table';

const RetireeInsights = () => {
  const { hasPermissionForRetirementInsights } = useFetchUserPermissions();
  const [data, setData] = useState<any>([]);
  const [totalData, setTotalData] = useState<Cell[]>(footerCells);
  const filterDetails = useFilters(defaultFilters);
  const [latestUpdate, setLatestUpdate] = useState('');
  const [openFilters, setOpenFilters] = useState(false);
  const [isDropdownOpen, handleIsDropdownOpen] = useState(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [tableSearchText, setTableSearchText] = useState<string>('');
  const timerRef = useRef<NodeJS.Timeout | string | number | undefined>();
  const isFilterApplied = useMemo(
    () => filterDetails.appliedFilters.length > 0,
    [filterDetails.appliedFilters]
  );
  const allRetireeCall: any = async (pageProps: any, filter: any) => {
    const nextPage = pageProps?.pageParam || 1;
    if (nextPage === 1) {
      setData([]);
    }
    try {
      const { data }: any = await makeAuthenticatedPostRequest<unknown>(
        allRetireeInsights,
        { ...filter, page: nextPage, limit: filterDetails.paginationState.limit },
        {
          signal: pageProps.signal,
        }
      );

      const { setTotalCount, setPage } = filterDetails.paginationState;
      setLatestUpdate(data?.data?.latestUpdate || '');
      setTotalCount(data?.data?.totals?.total_retirees || 0);
      setPage(data?.data?.current_page || 1);
      setData((prevData) => [...prevData, ...(data?.data?.retireeByVolume || [])]);
      setTotalData(getFooterCells(data?.data?.totals));
      return data;
    } catch (error: any) {
      throw new Error(error?.response?.data?.message || error.message);
    }
  };

  const { data: filterData } = useQuery({
    queryKey: [`ritiree-filter-meta`],
    queryFn: async () =>
      await makeAuthenticatedGetRequest(filterMetadata, {
        params: {
          type: 'all_retiree',
        },
      }),
    select: (data) => ({
      data: data?.data?.data?.metaData,
      columns: getColumnDetails(data?.data?.data?.columns || []),
    }),
    enabled: hasPermissionForRetirementInsights,
  });

  const {
    isFetching: isPending,
    fetchNextPage,
    hasNextPage,
    error,
  } = useInfiniteQuery<unknown, Error>({
    queryKey: ['get-all-retiree', filterDetails.filters],
    queryFn: async (props) => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();
      return allRetireeCall(
        { ...props, signal: abortControllerRef.current.signal },
        filterDetails.filters
      );
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage: any) => {
      const total = lastPage?.data?.totals?.total_retirees;
      if (total <= data.length) return null;
      if ((lastPage?.data?.retireeByVolume || []).length === 0) return null;
      return (lastPage?.data?.current_page || 1) + 1;
    },
    retry: false,
    enabled: hasPermissionForRetirementInsights,
  });

  const rowVirtualizer = useVirtualizer({
    count: hasNextPage ? data.length + 3 : data.length,
    getScrollElement: () => filterDetails.paginationState.scrollParentRef,
    estimateSize: () => 35,
    overscan: 40,
    enabled:
      !!filterDetails.paginationState.scrollParentRef && !!data.length && !!filterDetails.filters,
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => {
            return element?.getBoundingClientRect().height;
          }
        : undefined,
  });

  const fetchMoreOnBottomReached = useCallback(() => {
    if (!isPending && hasNextPage) {
      fetchNextPage();
    }
  }, [isPending, hasNextPage]);

  const loaderRef = useInfiniteScroll({
    scrollParentRef: filterDetails.paginationState.scrollParentRef,
    fetchMoreOnBottomReached,
    enabled: !!filterDetails.paginationState.scrollParentRef && !!data.length,
  });

  useEffect(() => {
    if (filterData?.data) {
      filterDetails.updateFilterMap(filterData.data);
    }
  }, [filterData]);

  useEffect(() => {
    track(PGV_retirement_insights, EVENT_RETIREMENT_INSIGHTS_PAGE);
  }, []);

  const handleOpenFilterDrawer = () => {
    track(EVNT_retirement_insights_filter_panel_opened, EVENT_RETIREMENT_INSIGHTS_PAGE);
    setOpenFilters(true);
  };

  useEffect(() => {
    const filter_project_type_values = filterDetails?.filters?.project_type;
    const filter_country_values = filterDetails?.filters?.country;
    const filter_ghg_rating_values = filterDetails?.filters?.ghg_rating;
    const filter_sdg_rating_values = filterDetails?.filters?.sdg_rating;
    const filter_attributes_values = filterDetails?.filters?.project_attribute;
    const filter_confirmed_sdg_values = filterDetails?.filters?.confirmed_sdg;
    const filter_sdg_cert_values = filterDetails?.filters?.sdg_certificate;
    if (isFilterApplied) {
      track(EVNT_retirement_table_filters_applied, EVENT_RETIREMENT_INSIGHTS_PAGE, {
        filter_attributes_values,
        filter_confirmed_sdg_values,
        filter_country_values,
        filter_ghg_rating_values,
        filter_project_type_values,
        filter_sdg_cert_values,
        filter_sdg_rating_values,
      });
    }
  }, [filterDetails]);

  useEffect(() => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      if (tableSearchText?.length > 0) {
        track(EVNT_retirement_table_searched, EVENT_RETIREMENT_INSIGHTS_PAGE, {
          search_term: filterDetails?.search,
        });
      }
    }, 2000);
  }, [tableSearchText]);

  const handleTableSearch = (e) => {
    setTableSearchText(e?.target?.value);
    filterDetails.handleSearch(e);
  };

  return (
    <Box position={'relative'}>
      <Paywall
        isLoadingComplete={true}
        showPaywall={!hasPermissionForRetirementInsights}
        headingText='Get deeper insights with Retirement Insights'
        bodyText='Upgrade your subscription to dive into the details of who has retired specific carbon credits and get inspired by what other companies are retiring.'
      />
      <Stack gap={'12px'} sx={{ px: 3, mx: 'auto', mt: '12px' }}>
        <FilterSideBar
          open={openFilters}
          title='All Filters'
          onClose={() => setOpenFilters(false)}
          sections={(filterData?.data as any) || []}
          filterDetails={filterDetails}
          subText='Filtering excludes information from projects not rated by Calyx.'
          hasReset
        />
        <Stack direction={'row'} alignItems={'flex-end'} gap={10}>
          <Stack
            spacing={1}
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={1}
          >
            <Typography fontSize={'xl2'} color='primary' fontWeight={700} lineHeight={'32px'}>
              Retirement insights
            </Typography>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <Typography
                fontWeight={'lg'}
                level='body-xs'
                sx={(theme) => {
                  return { color: theme.palette.text.secondary };
                }}
              >
                Information Updated:{' '}
                {!!latestUpdate
                  ? dateFormat(latestUpdate).mmddyyyy
                  : !isPending
                    ? 'N/A'
                    : 'Loading...'}
              </Typography>
              <Tooltip
                title='Beta Access: This feature will be available to select subscription plans post-beta'
                variant='solid'
                placement='bottom'
                sx={{ maxWidth: '340px' }}
                arrow
              >
                <Chip
                  variant='outlined'
                  color='primary'
                  size='sm'
                  endDecorator={<IoInformationCircleOutline size={20} />}
                >
                  Beta
                </Chip>
              </Tooltip>
            </Stack>
          </Stack>
        </Stack>
        <Card>
          <Stack gap={2}>
            <Stack
              direction={{ xs: 'column', lg: 'row' }}
              gap={{ xs: 1, lg: 0 }}
              justifyContent={'space-between'}
            >
              <Typography
                fontSize={'xl'}
                fontWeight={700}
                color='primary'
                endDecorator={
                  <Tooltip
                    title='The retirement data shown here includes all projects with credits issued by the ACR, Climate Action Reserve, Gold Standard, and Verra.'
                    variant='solid'
                    placement='right'
                    sx={{ maxWidth: '340px' }}
                    arrow
                  >
                    <IconButton variant='plain'>
                      <IoInformationCircleOutline size={20} />
                    </IconButton>
                  </Tooltip>
                }
              >
                Credits Retired in Major Registries (tCO2e)
              </Typography>
              <Stack direction={'row'} spacing={2} justifyContent={{ xs: 'space-between' }}>
                <Input
                  endDecorator={<IoSearch />}
                  size='md'
                  color='neutral'
                  placeholder='Search for a company'
                  sx={{ width: '360px', height: '40px' }}
                  ref={filterDetails.inputRef}
                  onChange={handleTableSearch}
                />
                <Stack direction={'row'} gap={2}>
                  {filterDetails.allAppliedFilterCount > 0 && (
                    <Dropdown
                      open={isDropdownOpen}
                      onOpenChange={(_, isOpen: boolean) => {
                        handleIsDropdownOpen(isOpen);
                      }}
                    >
                      <MenuButton
                        disabled={!filterDetails.appliedFilters.length}
                        endDecorator={isDropdownOpen ? <FaChevronUp /> : <FaChevronDown />}
                      >
                        {`${filterDetails.allAppliedFilterCount} filter${filterDetails.allAppliedFilterCount > 1 ? 's' : ''} applied`}
                      </MenuButton>
                      <Menu
                        placement='bottom-end'
                        sx={{ width: '400px', p: 1 }}
                        autoFocus={isDropdownOpen}
                        ref={(node) => {
                          if (isDropdownOpen) node?.focus();
                        }}
                        onBlur={(e) => {
                          if (!e.relatedTarget) {
                            handleIsDropdownOpen(false);
                          }
                        }}
                      >
                        <Stack
                          direction={'row'}
                          gap={1}
                          sx={{
                            p: 1,
                            pb: 2,
                            flexWrap: 'wrap',
                            maxHeight: '180px',
                            overflowY: 'auto',
                          }}
                        >
                          {filterDetails.appliedFilters.map(
                            ({ keyName, valueName, key, childKeys }) => (
                              <Chip
                                title={`${keyName}: ${valueName} ${childKeys.length ? `(${childKeys.length})` : ''}`}
                                endDecorator={
                                  <ChipDelete
                                    onDelete={() => filterDetails.removeAppliedFilter(key)}
                                  />
                                }
                                variant='outlined'
                                color='primary'
                                key={key}
                                sx={{ maxWidth: '100%' }}
                              >
                                {keyName}: {valueName}{' '}
                                {childKeys.length ? `(${childKeys.length})` : ''}
                              </Chip>
                            )
                          )}
                        </Stack>
                        <ListDivider
                          sx={{ background: (theme) => theme.palette['border']['border'] }}
                        />
                        <Button
                          variant='outlined'
                          sx={{ ml: 'auto', mt: 1 }}
                          onClick={() => {
                            handleIsDropdownOpen(false);
                            filterDetails.clearFilter();
                          }}
                        >
                          Clear all filters
                        </Button>
                      </Menu>
                    </Dropdown>
                  )}
                  <Button onClick={handleOpenFilterDrawer} endDecorator={<RiFilter3Fill />}>
                    Filter
                  </Button>
                </Stack>
              </Stack>
            </Stack>
            <RetireeInsightsTable
              isLoading={isPending}
              data={data}
              hasNextPage={hasNextPage}
              paginationState={filterDetails.paginationState}
              toggleSort={filterDetails.toggleSort}
              getSortValue={filterDetails.getSortValue}
              sort={filterDetails.filters['sort'] || ({} as any)}
              columnDetails={filterData?.columns || { columns: columns, order: cellRenderOrder }}
              footerCells={totalData}
              rowVirtualizer={rowVirtualizer as unknown as Virtualizer<Element, Element>}
              loaderRef={loaderRef}
              isFilterApplied={isFilterApplied}
              error={
                error ? (
                  <MessagePage
                    title='Something went wrong'
                    description='We couldn’t load your results. Refresh the page to try again.'
                    hasHeading={false}
                    iconProps={{
                      size: 56,
                    }}
                    headingProps={{
                      level: 'h4',
                    }}
                    descriptionProps={{
                      level: 'title-md',
                    }}
                    Icon={MdError}
                    action={() => {
                      window.location.reload();
                    }}
                    actionText='Refresh'
                  />
                ) : null
              }
            />
          </Stack>
        </Card>
      </Stack>
    </Box>
  );
};

export { RetireeInsights };

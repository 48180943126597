import React, { useMemo } from 'react';

import { Breadcrumbs as MuiBreadcrumbs, styled, Typography } from '@mui/joy';
import { Link, useLocation, useMatches } from 'react-router-dom';

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.vars.palette['hyperlink']['text'],
  textUnderlineOffset: '0.15em',
}));
const Breadcrumbs: React.FC<{ title: string }> = ({ title }) => {
  const { search } = useLocation();
  const matches = useMatches();
  const filteredMatches = useMemo(
    () => matches.filter((match) => !!match.handle?.['breadcrumb']),
    [matches]
  );
  const lastMatch = useMemo(() => matches[matches.length - 1], [matches]);

  return (
    <MuiBreadcrumbs separator='/'>
      <StyledLink to={{ pathname: '/credit-ratings' }}>Credit ratings</StyledLink>
      {lastMatch.pathname === '/executive_summary' ? (
        <Typography level='body-md'>{title}</Typography>
      ) : (
        <StyledLink to={{ pathname: '/executive_summary', search }}>{title}</StyledLink>
      )}
      {filteredMatches.map((match, index) => {
        const { breadcrumb } = match.handle || ({} as any);
        if (!breadcrumb) return null;

        const isLast = index === filteredMatches.length - 1;

        return isLast ? (
          <Typography key={match.pathname} level='body-md'>
            {breadcrumb}
          </Typography>
        ) : (
          <StyledLink key={match.pathname} to={{ pathname: match.pathname, search }}>
            {breadcrumb}
          </StyledLink>
        );
      })}
    </MuiBreadcrumbs>
  );
};

export { Breadcrumbs };

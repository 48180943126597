import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Box, Button, Card, Stack, Typography } from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import { FaArrowDown } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { track } from '../../../services/analytics';
import {
  EVENT_ESR_RATING_PAGE,
  EVNT_esr_section_clicked,
  EVNTAPP_view_deep_dive_click,
  PGV_esr_summary,
  PGVAPP_deep_dive_content_viewed,
  PGVAPP_upgrade_modal_viewed,
} from '../../../services/analytics/events';
import { makeAuthenticatedGetRequest } from '../../../services/axios';
import { esrDetailSummary, esrProjectComparison } from '../../../services/axios/endpoints';
import { projectState } from '../../../stores/project';
import { updateMap } from '../../../utils/functions/map';
import { scrollToRef } from '../../../utils/functions/other';
import { useExecuteCallbackOnIntersection } from '../../../utils/hooks/useExecuteCallbackOnIntersection';
import { useFetchUserPermissions } from '../../../utils/hooks/useFetchUserPermission';

import DeepDiveAnalysis from './components/DeepDiveAnalysis';
import ScreeningTimeline from './components/detailedRiskFindings/ScreeningTimeline';
import SummaryRiskLevel from './components/detailedRiskFindings/SummaryRiskLevel';
import RiskSummaryLoader from './components/loaders/RishSummaryLoader';
import NoScreening from './components/noScreening/NoScreening';
import { StyledHTML } from './components/StyledHTML';

export const EnvironmentalAndSocialRisks: React.FC<{ activeTab?: number }> = ({ activeTab }) => {
  const {
    projectData,
    essRatingData: esrSummaryData,
    isLoading,
    projectCreditingData,
  } = useRecoilValue(projectState);
  const setProjectState = useSetRecoilState(projectState);
  const deepDiveRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { id } = queryString.parse(location.search);
  const [selectedTabIndex, setSelectedTabIndex] = useState(activeTab || 0);
  const [projectComparisonType, setProjectComparisonType] = useState<'category' | 'safeguard'>(
    'category'
  );
  const { hasPermissionForReadMore: hasGeneralAccessForReadMore } = useFetchUserPermissions();
  const maxRisk = useMemo(
    () => (esrSummaryData?.risk_data || []).find((d) => d.safeguard_areas.length > 0)?.name,
    [esrSummaryData?.risk_data]
  );

  const paths = useMemo(
    () => ({
      0: 'detailed_risk_findings',
      1: 'project_comparison',
      2: 'further_research',
      3: 'sources_and_methodology',
    }),
    []
  );

  useEffect(() => {
    let newSelectedTabIndex = 0;
    [...new Array(4)].forEach((_, index) => {
      if (location.pathname.includes(paths[index])) {
        newSelectedTabIndex = index;
      }
    });
    setSelectedTabIndex(newSelectedTabIndex);
  }, [location.pathname, paths]);

  useEffect(() => {
    if (location.pathname.startsWith('/ess_rating')) {
      navigate(`/esr_rating${location.search}`);
    }
  }, []);

  useEffect(() => {
    if (projectData?.projectId)
      setProjectState((currtVal) => ({
        ...currtVal,
        esrBadge: updateMap(currtVal.esrBadge, projectData.projectId, true),
      }));
  }, [projectData?.projectId, setProjectState]);

  useEffect(() => {
    if (!!esrSummaryData && !!projectData) {
      track(PGV_esr_summary, EVENT_ESR_RATING_PAGE, {
        project_id: projectData?.projectId,
        project_name: projectData?.title,
        project_category: projectData?.projectCategory,
        project_type: projectData?.projectType,
        issuance_size: projectData?.issuanceSizeCategory,
        has_screening: esrSummaryData?.screening_available ? 'yes' : 'no',
        esr_max_risk: maxRisk,
      });
    }
  }, [
    esrSummaryData,
    esrSummaryData?.risk_data,
    esrSummaryData?.screening_available,
    isLoading,
    maxRisk,
    projectData,
    projectData?.issuanceSizeCategory,
    projectData?.projectCategory,
    projectData?.projectId,
    projectData?.projectType,
    projectData?.title,
  ]);

  const handleTab = (_, newValue) => {
    track(EVNT_esr_section_clicked, EVENT_ESR_RATING_PAGE, {
      project_section: paths[newValue],
      porject_id: projectData?.projectId,
      project_name: projectData?.title,
      project_category: projectData?.projectCategory,
      project_type: projectData?.projectType,
      issuance_size: projectData?.issuanceSizeCategory,
      esr_max_risk: maxRisk,
    });
    navigate({ pathname: `/esr_rating/${paths[newValue]}`, search: location.search });
  };

  const {
    data: summaryDetailData,
    isLoading: isSummaryDetailLoading,
    isError: isSummaryDetailError,
  } = useQuery({
    queryKey: ['esr_detail_summary', id],
    queryFn: async () => {
      return await makeAuthenticatedGetRequest(esrDetailSummary, {
        params: { crediting_period_id: projectData.creditingPeriodId },
      });
    },
    select: (data) => {
      return data?.data?.data;
    },
    enabled: !!esrSummaryData?.screening_available && hasGeneralAccessForReadMore,
  });

  const hasPermissionForReadMore = useMemo(
    () => summaryDetailData?.read_more_access ?? false,
    [summaryDetailData]
  );

  const {
    data: projectComparisonData,
    isError: isProjectComparisonError,
    isLoading: isProjectComparisonLoading,
  } = useQuery({
    queryKey: ['esr_project_comparison', id, projectComparisonType],
    queryFn: async () => {
      return await makeAuthenticatedGetRequest(esrProjectComparison, {
        params: { crediting_period_id: projectData.creditingPeriodId, type: projectComparisonType },
      });
    },
    select: (data) => {
      return data?.data?.data;
    },
    enabled: !!esrSummaryData?.screening_available && hasGeneralAccessForReadMore,
  });

  const scrollReadMoreIntoView = (param: React.RefObject<HTMLDivElement> | string) => {
    const container = document.getElementById('layout');
    if (typeof param === 'string') {
      setSelectedTabIndex(0);
      setTimeout(() => {
        const element = document.getElementById(param);
        scrollToRef(element, container, -1100);
      }, 0);
    } else {
      scrollToRef(param?.current, container, 220);
    }
  };

  useExecuteCallbackOnIntersection(
    deepDiveRef,
    () => {
      track(
        hasPermissionForReadMore ? PGVAPP_deep_dive_content_viewed : PGVAPP_upgrade_modal_viewed,
        EVENT_ESR_RATING_PAGE,
        {
          project_id: projectData?.projectId,
          project_tab: 'ESR',
          project_name: projectData?.title,
          project_category: projectData?.projectCategory,
          project_type: projectData?.projectType,
          issuance_size: projectCreditingData?.issuanceSizeCategory,
          esr_max_risk: projectData?.maxESRRisk,
          ghg_rating: projectData?.ghgRatingDetail?.grade,
        }
      );
    },
    [projectData, esrSummaryData],
    '-200px'
  );

  if (isSummaryDetailLoading || isLoading) {
    return (
      <Box maxWidth={1032} margin={'auto'}>
        <Typography level='h4' color='primary' sx={{ mb: 1 }}>
          Environmental and social risks (ESRs)
        </Typography>
        <Card sx={{ gap: 0, p: 3, px: 5 }}>
          <RiskSummaryLoader />
        </Card>
      </Box>
    );
  }

  return (
    <Box maxWidth={1032} margin={'auto'}>
      <Typography level='h4' color='primary' sx={{ mb: 1 }}>
        Environmental and social risks (ESRs)
      </Typography>
      {!esrSummaryData?.screening_available ? (
        <Card sx={{ gap: 0, p: 3, px: 5 }} ref={containerRef}>
          <NoScreening data={esrSummaryData} />
        </Card>
      ) : (
        <>
          <Card sx={{ gap: 0, p: 3, px: 5 }} ref={containerRef}>
            <Card
              sx={{
                border: (theme) => `1px solid ${theme.palette['border']['border']}`,
                background: (theme) => theme.palette.background.body,
                padding: (theme) => `${theme.spacing(2)} ${theme.spacing(3)}`,
              }}
            >
              <Stack gap={2}>
                <StyledHTML
                  fontWeight={'md'}
                  fontSize={'md'}
                  color='primary'
                  dangerouslySetInnerHTML={{ __html: esrSummaryData?.summary_content }}
                />
                <Button
                  sx={{
                    background: (theme) => theme.palette['linearGradient']['special'],
                    width: 'fit-content',
                  }}
                  size='sm'
                  endDecorator={<FaArrowDown />}
                  onClick={() => {
                    track(EVNTAPP_view_deep_dive_click, EVENT_ESR_RATING_PAGE, {
                      project_tab: 'ESR',
                      project_id: projectData?.projectId,
                      project_name: projectData?.title,
                      project_category: projectData?.projectCategory,
                      project_type: projectData?.projectType,
                      issuance_size: projectData?.issuanceSizeCategory,
                      esr_max_risk: maxRisk,
                    });
                    scrollReadMoreIntoView(deepDiveRef);
                  }}
                >
                  Go to deep-dive analysis
                </Button>
              </Stack>
            </Card>
            <SummaryRiskLevel
              data={esrSummaryData?.risk_data || []}
              scrollReadMoreIntoView={scrollReadMoreIntoView}
            />
            <ScreeningTimeline data={esrSummaryData?.screening_timeline || []} />
          </Card>
          <Card sx={{ padding: '0px', overflow: 'hidden', mt: 4 }} ref={deepDiveRef}>
            <DeepDiveAnalysis
              data={summaryDetailData}
              projectComparisonData={projectComparisonData}
              selectedTabIndex={selectedTabIndex}
              setProjectComparisonType={setProjectComparisonType}
              projectComparisonType={projectComparisonType}
              scrollReadMoreIntoView={scrollReadMoreIntoView}
              handleTab={handleTab}
              isProjectComparisonLoading={isProjectComparisonLoading}
              error={selectedTabIndex === 1 ? isProjectComparisonError : isSummaryDetailError}
            />
          </Card>
        </>
      )}
    </Box>
  );
};

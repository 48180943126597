export const annualCarbonCreditsOptions = [
  'None',
  '1-500',
  '500-1,000',
  '1,000-5,000',
  '5,000-10,000',
  '10,000-20,000',
  '20,000+',
  'Unsure',
];

export const annualCarbonCreditsOptionsB2B = [
  '< 20,000',
  '20,000 - 75,000',
  '75,000 - 250,000',
  '250,000 - 1,000,000',
  '>1,000,000',
];

export const defaultError = {
  firstName: '',
  lastName: '',
  email: '',
  organisation: '',
  role: '',
  annualCarbonCredits: '',
  password: '',
  confirmPassword: '',
  terms1: '',
  terms2: '',
};

export const steps = [
  {
    id: 1,
    name: 'Personal',
  },
  {
    id: 2,
    name: 'Organisation',
  },
  {
    id: 3,
    name: 'Account',
  },
];

import { useCallback, useRef } from 'react';

function useUniqueDebouncedCallback(callback, delay) {
  const timeoutRefs = useRef<Map<string, NodeJS.Timeout>>(new Map());
  return useCallback(
    (...args) => {
      // Generate a unique key based on all arguments
      const argsKey = JSON.stringify(args);

      // Clear any existing timeout for the same argsKey
      if (timeoutRefs.current.has(argsKey)) {
        clearTimeout(timeoutRefs.current.get(argsKey));
      }

      // Set a new timeout
      timeoutRefs.current.set(
        argsKey,
        setTimeout(() => {
          callback(...args);
          timeoutRefs.current.delete(argsKey); // Cleanup after execution
        }, delay)
      );
    },
    [callback, delay, timeoutRefs.current]
  );
}

export default useUniqueDebouncedCallback;

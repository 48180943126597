import { useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';

const usePreviousRoute = () => {
  const { pathname: asPath } = useLocation();

  const ref = useRef<string | null>(null);

  useEffect(() => {
    ref.current = asPath;
  }, [asPath]);

  return ref.current;
};

export default usePreviousRoute;

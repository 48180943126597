import { atom } from 'recoil';

import { FilterDetails } from '../../utils/hooks/useFilters/types';

const RETIREE_INSIGHTS_REDIRECT_URL = 'RETIREE_INSIGHTS_REDIRECT_URL';
interface RetireeInsightState {
  retireeInsightsRedirectUrl: string;
  filterState: FilterDetails | null;
  prevPath: string;
}

export const miscState = atom<RetireeInsightState>({
  key: 'miscState',
  default: {
    filterState: null,
    prevPath: '',
    retireeInsightsRedirectUrl:
      sessionStorage.getItem(RETIREE_INSIGHTS_REDIRECT_URL) ??
      '/retirement_insights?limit=20&page=1',
  },
  effects: [
    ({ onSet }) => {
      onSet((retireeInsightState) => {
        sessionStorage.setItem(
          RETIREE_INSIGHTS_REDIRECT_URL,
          retireeInsightState.retireeInsightsRedirectUrl
        );
      });
    },
  ],
});

import { useRef, useEffect, useState } from 'react';

function deepEqual(obj1: any, obj2: any): boolean {
  if (obj1 === obj2) return true;
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null)
    return false;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) return false;
  }

  return true;
}

export function useDeepCompareMemo<T>(value: T): T {
  const ref = useRef<T>(value);
  const [result, setResult] = useState<T>(value);
  useEffect(() => {
    if (!deepEqual(ref.current, value)) {
      ref.current = value;
      setResult(value);
    }
  }, [value]);

  return result;
}

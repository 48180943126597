import { useEffect, useMemo } from 'react';

import { Box, Button, Card, Skeleton, Stack, Typography } from '@mui/joy';
import { FaArrowRight } from 'react-icons/fa';
import { TbLockOpen } from 'react-icons/tb';
import { Link } from 'react-router-dom';

import { track } from '../../services/analytics';
import { EVENT_INSIGHTS_HUB_PAGE, PGV_research_hub_main } from '../../services/analytics/events';
import useFetchAllArticlesByCategory from '../../services/strapi/useFetchAllArticlesByCategory';
import useFetchAllResearchUpdates from '../../services/strapi/useFetchAllResearchUpdates';
import { Article } from '../../types/strapi';
import { dateFormat } from '../../utils/functions/date';
import useIsMobile from '../../utils/hooks/useIsMobile';

import ArticleRow from './components/ArticleRow';
import FeaturedArticle from './components/FeaturedArticle';
import { insightsHubCategoriesConfig } from './constants';

export const InsightsHub = () => {
  const isMobile = useIsMobile();
  const {
    data,
    isLoading: areAllArticlesLoading,
    isRefetching,
    isPending,
  } = useFetchAllArticlesByCategory({
    category: null,
  });

  const featuredArticleOne = useMemo(() => {
    return data
      ?.filter(({ is_featured }) => is_featured === 'featured_one')
      ?.sort(
        (a, b) => new Date(b?.published_date).getTime() - new Date(a?.published_date).getTime()
      )?.[0];
  }, [data]);

  const featuredArticleTwo = useMemo(() => {
    return data
      ?.filter(({ is_featured }) => is_featured === 'featured_two')
      ?.sort(
        (a, b) => new Date(b?.published_date).getTime() - new Date(a?.published_date).getTime()
      )?.[0];
  }, [data]);

  const { data: researchUpdates, isLoading: isResearchUpdatesLoading } =
    useFetchAllResearchUpdates();

  useEffect(() => {
    if (data) {
      track(PGV_research_hub_main, EVENT_INSIGHTS_HUB_PAGE);
    }
  }, [data]);

  const isLoading = useMemo(
    () => areAllArticlesLoading || isPending || isRefetching,
    [areAllArticlesLoading, isPending, isRefetching]
  );

  return (
    <Box display={'grid'} gridTemplateColumns={'1fr'} width={'100%'} gap={3}>
      <Box display={'grid'} gridTemplateColumns={{ xs: '1fr', lg: '1fr 1fr' }} gap={3}>
        <FeaturedArticle
          data={featuredArticleOne as Article}
          isLoading={isLoading || !featuredArticleOne}
        />
        <Card sx={{ height: '100%', overflow: 'auto' }}>
          <Stack direction={'column'} gap={2}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography
                endDecorator={<TbLockOpen style={{ transform: 'rotateY(180deg)' }} />}
                level={isMobile ? 'title-lg' : 'h4'}
              >
                Research updates
              </Typography>
              <Link to={`/research-hub/research-updates`}>
                <Button variant='outlined' size='sm' endDecorator={<FaArrowRight />}>
                  View all
                </Button>
              </Link>
            </Stack>
            {isResearchUpdatesLoading
              ? Array(3)
                  .fill('')
                  .map((_, i) => {
                    return (
                      <Stack key={i} direction={'column'} gap={1}>
                        <Skeleton
                          variant='rectangular'
                          width={'40%'}
                          height={'24px'}
                          animation='wave'
                        />
                        <Stack direction={'column'} gap={0.5}>
                          {Array(3)
                            .fill('')
                            .map((_, i) => {
                              return (
                                <Skeleton
                                  variant='rectangular'
                                  width={'100%'}
                                  height={'14px'}
                                  animation='wave'
                                  key={i}
                                />
                              );
                            })}
                          <Skeleton
                            variant='rectangular'
                            width={'60%'}
                            height={'14px'}
                            animation='wave'
                          />
                        </Stack>
                      </Stack>
                    );
                  })
              : (researchUpdates as any[])?.slice(0, 3)?.map(({ date, preview, id }) => {
                  return (
                    <Link
                      to={isResearchUpdatesLoading ? '' : `/research-hub/research_update/${date}`}
                      style={{ textDecoration: 'none', color: 'unset' }}
                    >
                      <Stack key={id} direction={'column'} gap={0.5}>
                        <Typography level='title-lg'>{dateFormat(date).monthddyyyy}</Typography>
                        <Typography
                          level='title-sm'
                          sx={(theme) => {
                            return {
                              color: theme.palette.text.secondary,
                              fontWeight: theme.fontWeight.sm,
                            };
                          }}
                        >
                          {preview}
                        </Typography>
                      </Stack>
                    </Link>
                  );
                })}
          </Stack>
        </Card>
      </Box>

      {insightsHubCategoriesConfig
        ?.filter(({ hideInRow }) => !hideInRow)
        ?.map(({ filterCombination, key }) => {
          return (
            <ArticleRow
              allArticles={data as Article[]}
              category={key}
              filterCombination={filterCombination}
              isLoading={isLoading}
              urlKey={key}
              key={key}
            />
          );
        })}
      <FeaturedArticle
        data={featuredArticleTwo as Article}
        showVertical={false}
        isLoading={isLoading || !featuredArticleTwo}
        showSummary
      />
    </Box>
  );
};

export const getGHGImpactRatingDetail = (grade) => {
  switch (grade) {
    case 'AAA':
      return 'Lowest risk';
    case 'AA':
      return 'Very low risk';
    case 'A':
      return 'Low risk';
    case 'BBB':
      return 'Moderately low risk';
    case 'BB':
      return 'Moderate risk';
    case 'B':
      return 'Moderately high risk';
    case 'C':
      return 'High risk';
    case 'D':
      return 'Highest risk';
    default:
      return '';
  }
};

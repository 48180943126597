import { useMemo } from 'react';

import { CircularProgress, Typography, useColorScheme } from '@mui/joy';

import { getGHGratingColor, getSDGRatingColor } from '../utils/constants/colors';

interface PropTypes {
  type: 'ghg' | 'sdg';
  percentage: number;
  rating: string;
  size: 'sm' | 'md' | 'lg';
}

export const RatingCircle = ({ percentage, rating, type, size }: PropTypes) => {
  const { mode } = useColorScheme();
  const {
    width: circularProgressSize,
    fontSize: ratingFontSize,
    thickness: circleThickness,
    naSymbolFontSize,
    noCertFontSize,
  } = useMemo(() => {
    let width = 40;
    let fontSize = 'md';
    let naSymbolFontSize = 'xs';
    let noCertFontSize = '10px';
    let thickness = 4;
    switch (size) {
      case 'lg':
        width = 96;
        fontSize = 'xl3';
        thickness = 6;
        noCertFontSize = '20px';
        naSymbolFontSize = 'xl';
        break;
      case 'md':
        width = 64;
        fontSize = 'xl';
        naSymbolFontSize = 'sm';
        noCertFontSize = '14px';
        thickness = 4;
        break;
      case 'sm':
        width = 40;
        fontSize = 'xs';
        naSymbolFontSize = 'xs';
        noCertFontSize = '10px';
        thickness = 4;
        break;
      default:
        break;
    }
    return {
      width,
      fontSize,
      thickness,
      naSymbolFontSize,
      noCertFontSize,
    };
  }, [size]);
  return (
    <CircularProgress
      value={percentage}
      determinate
      sx={{
        '--CircularProgress-size': `${circularProgressSize}px`,
        '--CircularProgress-progressColor':
          type === 'ghg'
            ? getGHGratingColor(rating, mode === 'dark')
            : getSDGRatingColor(rating, mode === 'dark'),
        '--CircularProgress-trackColor': mode === 'dark' ? '#4D4D4D' : '#F0F4F8',
      }}
      thickness={circleThickness}
    >
      {rating?.toLowerCase() === 'n/a' ? (
        <Typography
          fontSize={naSymbolFontSize}
          fontWeight={'lg'}
          whiteSpace={'normal'}
          textAlign={'center'}
          sx={(theme) => {
            return {
              color: mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
            };
          }}
        >
          {rating}
        </Typography>
      ) : rating?.toLowerCase() === 'not certified' ? (
        <Typography
          fontSize={noCertFontSize}
          fontWeight={'lg'}
          whiteSpace={'normal'}
          textAlign={'center'}
          lineHeight={'100%'}
          width={circularProgressSize - 12}
          textTransform={'capitalize'}
          sx={(theme) => {
            return {
              color: mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
            };
          }}
        >
          {rating}
        </Typography>
      ) : (
        <Typography
          fontSize={ratingFontSize}
          fontWeight={'lg'}
          sx={(theme) => {
            return {
              color: mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
            };
          }}
        >
          {rating}
        </Typography>
      )}
    </CircularProgress>
  );
};

import { useMemo } from 'react';

import { Box, Card, Divider, Stack, Typography } from '@mui/joy';
import { useRecoilValue } from 'recoil';

import { projectState } from '../../../../stores/project';
import { SuggestedItem, SuggestedItemLoader } from '../../../home/components/LatestBlogs';

const emptyBlogs = Array(3).fill('');
const BLOGS_LIMIT = 2;

const RelatedContent = () => {
  const { relatedContentArticles, isLoading } = useRecoilValue(projectState);
  const limitedBlogs = useMemo(
    () =>
      relatedContentArticles?.length > BLOGS_LIMIT
        ? relatedContentArticles.slice(0, BLOGS_LIMIT)
        : relatedContentArticles,
    [relatedContentArticles]
  );
  if ((relatedContentArticles || []).length === 0 && !isLoading) return null;

  return (
    <Card sx={{ mb: 2, gap: 0, p: (theme) => theme.spacing(3) }}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography fontSize={20} fontWeight={'xl'} color='primary'>
          Related Content
        </Typography>
      </Stack>
      <Divider sx={{ width: 1, mx: 'auto', mt: (theme) => theme.spacing(2) }} />
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: (theme) => theme.spacing(1) }}
      >
        {isLoading
          ? emptyBlogs.map((item, index) => {
              return <SuggestedItemLoader key={index + item} />;
            })
          : (limitedBlogs || []).map((blog) => (
              <SuggestedItem
                key={blog.id}
                id={blog.id}
                title={blog.title}
                date={blog.published_date}
                imageURL={blog?.cover?.url}
                category={blog?.post_category?.url_slug}
                slug={blog?.url}
                link={blog?.content_detail_alternate_url}
              />
            ))}
      </Box>
    </Card>
  );
};

export default RelatedContent;

import React, {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Sheet, Skeleton, Stack, Tooltip, Typography, useTheme } from '@mui/joy';
import { SxProps } from '@mui/joy/styles/types';
import { Virtualizer } from '@tanstack/react-virtual';
import { uniqueId } from 'lodash';
import { BiSolidDownArrow, BiSolidUpArrow } from 'react-icons/bi';
import { MdInfoOutline } from 'react-icons/md';

import useDebounce from '../../utils/hooks/useDebounce';
import { PaginationState } from '../../utils/hooks/usePagination/types';

import Cell from './Cell';
import Column from './Column';
import Footer from './Footer';
import Row from './Row';
import { StyledFooter, StyledTBody, StyledTHead, StyledTable } from './styles';
import { Cell as CellType, Data } from './types';

type TableProps = {
  bodyStyle?: CSSProperties;
  style?: SxProps;
  columns: CellType[][];
  data: Data[];
  cellRenderOrder: string[];
  isLoading?: boolean;
  paginationState: PaginationState;
  isStickyHeader?: boolean;
  isStickyFooter?: boolean;
  hasInfiniteData?: boolean;
  hasNextPage?: boolean;
  footerCells?: CellType[];
  error?: ReactNode;
  loaderRef?: (node: HTMLDivElement | null) => void;
  rowVirtualizer?: Virtualizer<Element, Element>;
  noDataMessage?: ReactNode;
  defaultSort?: { [key: string]: 'DESC' | 'ASC' };
  sort?: { [key: string]: 'DESC' | 'ASC' };
  scrollArea?: 'none' | 'x' | 'y' | 'both';
  rowKey: string;
  onRowClick?: (row: Data) => void;
  customCell?: (data: Data, key: string) => React.ReactNode;
  customContent?: (data: Data, key: string) => React.ReactNode;
  toggleSort?: (key: string, order?: string) => void;
  getSortValue?: (key?: string) => any;
  isRowDiabled?: (row: Data) => boolean;
  onRowMouseLeave?: () => void;
};

const dummyArray = [...new Array(3).fill(null)];
const Table: React.FC<TableProps> = ({
  style,
  bodyStyle,
  columns,
  data = [],
  isLoading,
  cellRenderOrder,
  paginationState,
  isStickyHeader,
  isStickyFooter,
  hasInfiniteData,
  footerCells,
  rowVirtualizer,
  error,
  loaderRef,
  noDataMessage,
  defaultSort,
  hasNextPage,
  scrollArea = 'y',
  onRowClick,
  customCell,
  toggleSort,
  sort,
  isRowDiabled,
  customContent,
  onRowMouseLeave,
  rowKey,
}) => {
  const noResultsFound = useMemo(() => data?.length === 0 && !isLoading, [data.length, isLoading]);
  const columnsValueMap = useRef(new Map());
  const hasPinnedColumn = useRef(scrollArea === 'x' || scrollArea === 'both');
  const [tableInitalizing, setTableInitializing] = useState(true);
  const [sortClone, setSortClone] = useState(sort);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const debouncedToggleSort = useDebounce(toggleSort ?? (() => {}), 200);
  const theme = useTheme();

  // set columns value map
  useEffect(() => {
    const map = new Map();
    columns.flat().forEach((column) => {
      map.set(column.key, column);
    });
    columnsValueMap.current = map;
    setTableInitializing(false);
  }, []);

  const renderCell = useCallback(
    (data: Data) => {
      const keys = cellRenderOrder;
      const fallBack = (key: string) => {
        const contentStyle = columnsValueMap.current.get(key)?.contentStyle;
        return (
          <Typography
            level='body-xs'
            fontWeight={600}
            title={data[key]}
            sx={{
              color: (theme) => theme.palette.text.primary,
              ...(contentStyle || {}),
            }}
          >
            {customContent ? customContent(data, key) : data[key]}
          </Typography>
        );
      };
      return keys.map((key, index) => {
        const cellStyle = columnsValueMap.current.get(key)?.cellStyle;
        const isPinned = columnsValueMap.current.get(key)?.isPinned;
        return (
          <Cell
            key={data[rowKey] + index}
            isPinned={isPinned}
            style={{
              borderLeft: index <= 1 ? 'inhereit' : 'none',
              ...(cellStyle || {}),
            }}
          >
            {customCell ? customCell(data, key) || fallBack(key) : fallBack(key)}
          </Cell>
        );
      });
    },
    [customCell, columnsValueMap.current, theme]
  );

  const renderEmptyCell = useCallback(
    (count: number) => {
      const dummy = [...new Array(count)].fill('');
      return dummy.map((_) => (
        <Row key={uniqueId('dummyrow')}>
          {cellRenderOrder.map((key, index) => (
            <Cell
              key={uniqueId('dummycell_')}
              isPinned={columnsValueMap.current.get(key)?.isPinned}
              style={{
                borderLeft: index <= 1 || index === dummy.length - 1 ? 'inhereit' : 'none',
                ...(columnsValueMap.current.get(key)?.cellStyle || {}),
              }}
            >
              <Skeleton sx={{ position: 'relative' }} />
            </Cell>
          ))}
        </Row>
      ));
    },
    [theme]
  );

  const handleSort = (key: string, order?: string) => {
    setSortClone((prev) => {
      const prevSort: Record<string, string> = prev || {};
      const newSort = {};

      if (prevSort[key] === 'ASC') {
        newSort[key] = 'DESC';
      } else if (prevSort[key] === 'DESC') {
        newSort[key] = 'ASC';
      } else {
        newSort[key] = order;
      }
      return newSort;
    });
    toggleSort && debouncedToggleSort(key, order);
  };

  const renderColumnCell = useCallback(
    (columns: CellType[][], isFooter?: boolean) => {
      return columns.map((column) => (
        <Row key={uniqueId('column')} style={{ borderRadius: 0, display: isFooter ? 'flex' : '' }}>
          {column.map((cell) =>
            cell.isLoading ? (
              <Column
                rowSpan={cell.rowSpan}
                colSpan={cell.colSpan}
                isPinned={cell.isPinned}
                key={cell.key}
                style={{
                  ...(cell?.cellStyle || {}),
                }}
              >
                <Skeleton sx={{ position: 'relative' }} />
              </Column>
            ) : (
              <Column
                rowSpan={cell.rowSpan}
                colSpan={cell.colSpan}
                key={cell.key}
                isSortable={cell.isSortable}
                isPinned={cell.isPinned}
                style={{
                  cursor: cell.isSortable ? 'pointer' : 'default',
                  userSelect: 'none',
                  ...(cell?.cellStyle || {}),
                }}
                aria-disabled={isLoading}
                onClick={() => {
                  handleSort(cell.key, defaultSort?.[cell.key] || defaultSort?.['default']);
                }}
              >
                <Stack direction={'row'} alignItems={'center'} sx={cell.contentStyle}>
                  {cell.isSortable && (
                    <>
                      {sortClone && sortClone?.[cell.key] === 'ASC' && <BiSolidUpArrow size={10} />}
                      {sortClone && sortClone?.[cell.key] === 'DESC' && (
                        <BiSolidDownArrow size={10} />
                      )}
                    </>
                  )}
                  <Typography
                    level='body-xs'
                    color='primary'
                    sx={{
                      ...cell?.contentStyle,
                      fontWeight: 700,
                      ...(sortClone && sortClone?.[cell.key] ? { ml: 1 } : {}),
                    }}
                  >
                    {cell.name}
                  </Typography>
                  {cell.tooltip && (
                    <Tooltip title={cell.tooltip} sx={{ maxWidth: 300 }}>
                      <Stack alignItems={'center'} justifyContent={'center'} ml={'4px'}>
                        <MdInfoOutline size={16} />
                      </Stack>
                    </Tooltip>
                  )}
                </Stack>
              </Column>
            )
          )}
        </Row>
      ));
    },
    [handleSort, sort, theme]
  );

  useEffect(() => {
    return () => {
      paginationState.setScrollParentRef(null);
    };
  }, []);

  return (
    <StyledTable
      borderAxis='both'
      hoverRow
      stickyHeader={isStickyHeader}
      stickyFooter={isStickyFooter}
      sx={{ display: 'table-row', ...style }}
    >
      <Sheet
        ref={(node) => {
          if (node) paginationState.setScrollParentRef(node);
        }}
        sx={hasPinnedColumn.current ? { overflow: noResultsFound ? 'hidden' : 'auto' } : {}}
      >
        <StyledTHead sx={hasPinnedColumn.current ? { position: 'sticky', top: 0, zIndex: 12 } : {}}>
          {renderColumnCell(columns)}
        </StyledTHead>
        <StyledTBody
          style={bodyStyle}
          sx={{
            height: `${rowVirtualizer?.getTotalSize()}px`,
            position: 'relative',
            overflowY: hasPinnedColumn.current ? '' : 'none',
            borderBottom: (theme) =>
              error || noResultsFound
                ? `1px solid ${theme.palette['border']['border']}`
                : 'inherit',
            borderBottomRightRadius: (theme) => (error || noResultsFound ? theme.radius.sm : ''),
            borderBottomLeftRadius: (theme) => (error || noResultsFound ? theme.radius.sm : ''),
          }}
        >
          {tableInitalizing ? null : (
            <>
              {error ? (
                error
              ) : (
                <>
                  {rowVirtualizer ? (
                    rowVirtualizer.getVirtualItems().map((virtualRow) => {
                      const isLoaderRow = virtualRow.index > data.length - 1;
                      const isLast = virtualRow.index === data.length;
                      const row = data[virtualRow.index];
                      const isDisabled = isRowDiabled && isRowDiabled(data[virtualRow.index]);
                      if (isLoaderRow && hasNextPage) {
                        if (data.length >= paginationState.limit)
                          return (
                            <Row
                              data-index={virtualRow.index}
                              ref={(node) => {
                                rowVirtualizer.measureElement(node);
                                if (loaderRef && isLast) loaderRef(node);
                              }}
                              key={uniqueId('virtual_row')}
                              style={{
                                position: 'absolute',
                                transform: `translateY(${virtualRow.start}px)`,
                              }}
                            >
                              {cellRenderOrder.map((key, index) => (
                                <Cell
                                  key={uniqueId('cell')}
                                  isPinned={columnsValueMap.current.get(key)?.isPinned}
                                  style={{
                                    borderLeft: index <= 1 || index === 0 ? 'inhereit' : 'none',
                                    paddingRight: index === cellRenderOrder.length - 1 ? '5px' : '',
                                    ...(columnsValueMap.current.get(key)?.cellStyle || {}),
                                  }}
                                >
                                  <Skeleton sx={{ position: 'relative' }} />
                                </Cell>
                              ))}
                            </Row>
                          );
                        else {
                          return null;
                        }
                      }
                      return (
                        <Row
                          onClick={() => {
                            onRowClick && onRowClick(row);
                          }}
                          key={virtualRow.key}
                          data-index={virtualRow.index}
                          ref={(node) => rowVirtualizer.measureElement(node)}
                          className={isDisabled ? 'disabled' : ''}
                          style={{
                            position: 'absolute',
                            transform: `translateY(${virtualRow.start}px)`,
                          }}
                          onMouseLeave={isDisabled ? onRowMouseLeave && onRowMouseLeave : undefined}
                        >
                          {renderCell(row)}
                        </Row>
                      );
                    })
                  ) : isLoading && data.length === 0 ? (
                    renderEmptyCell(paginationState.limit)
                  ) : (
                    <>
                      {data.map((row) => {
                        const isDisabled = isRowDiabled && isRowDiabled(row);
                        return (
                          <Row
                            className={isDisabled ? 'disabled' : ''}
                            onClick={() => onRowClick && onRowClick(row)}
                            onMouseLeave={
                              isDisabled ? onRowMouseLeave && onRowMouseLeave : undefined
                            }
                            key={uniqueId('row_')}
                          >
                            {renderCell(row)}
                          </Row>
                        );
                      })}
                      {hasInfiniteData &&
                        data.length >= paginationState.limit &&
                        hasNextPage &&
                        dummyArray.map((_, index) => (
                          <Row
                            ref={(node) => {
                              if (loaderRef && index === 0) loaderRef(node);
                            }}
                            key={uniqueId('loader_row')}
                          >
                            {cellRenderOrder.map((key, index) => (
                              <Cell
                                key={uniqueId('cell')}
                                isPinned={columnsValueMap.current.get(key)?.isPinned}
                                style={{
                                  borderLeft: index <= 1 || index === 0 ? 'inhereit' : 'none',
                                  paddingRight: index === cellRenderOrder.length - 1 ? '5px' : '',
                                  ...(columnsValueMap.current.get(key)?.cellStyle || {}),
                                }}
                              >
                                <Skeleton sx={{ position: 'relative' }} />
                              </Cell>
                            ))}
                          </Row>
                        ))}
                    </>
                  )}
                  {isLoading && data.length === 0 && renderEmptyCell(paginationState.limit)}
                  {noResultsFound && data.length === 0 && noDataMessage}
                </>
              )}
            </>
          )}
        </StyledTBody>
      </Sheet>
      {hasInfiniteData ? (
        footerCells &&
        !error &&
        !noResultsFound && <StyledFooter>{renderColumnCell([footerCells], true)}</StyledFooter>
      ) : noResultsFound ? null : (
        <Footer paginationState={paginationState} span={7} isLoading={isLoading} />
      )}
    </StyledTable>
  );
};

export default Table;

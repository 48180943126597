import { IconButton, Table, styled } from '@mui/joy';

export const StyledTable = styled(Table)(({ theme }) => ({
  '--TableCell-height': '36px',
  '--TableCell-cornerRadius': theme.spacing(1),
  '--TableCell-borderColor': theme.palette['border']['border'],
  '--TableCell-headBackground': theme.palette.background.body,
  '--TableCell-footBackground': theme.palette.background.body,
  '--Table-headerUnderlineThickness': '3px',
  '--TableCell-paddingY': theme.spacing(1),
  '--TableCell-paddingX': theme.spacing(1),
  tr: {
    ':hover': {
      '--TableRow-hoverBackground': theme.palette['hyperlink']['cell'],
      td: {
        cursor: 'pointer',
        background: `${theme.palette['hyperlink']['cell']} !important`,
      },
    },
    '&.disabled': {
      '--TableRow-hoverBackground': 'transparent',
      opacity: 0.6,
      td: {
        cursor: 'default',
      },
    },
  },
  th: {
    fontSize: '12px',
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  'thead tr:not(:first-child) th': {
    '--TableCell-cornerRadius': theme.spacing(0),
  },
  'thead tr:nth-of-type(1) th': {
    borderTopWidth: '0px !important',
  },
  'thead:first-child tr:first-child th:first-child': {
    borderLeftWidth: '0px !important',
  },
  'tr>*:last-of-type:not(:first-of-type)': {
    borderRightWidth: '0px !important',
  },
  'thead tr:not(:first-child) th:last-child': {
    borderRight: 'none',
  },
  td: {
    fontSize: '12px',
    fontWeight: 500,
    textAlign: 'end',
  },
  'tfoot  td': {
    ':last-child': {
      borderRight: `1px solid ${theme.palette['border']['border']}`,
    },
    width: '51px',
  },
  overflow: 'auto',
  borderRadius: theme.radius.lg,
  border: `1px solid ${theme.palette['border']['border']}`,
  'tfoot th, tfoot td': {
    borderBottomWidth: '0px !important',
  },
  'tfoot tr>*:first-of-type': {
    borderLeftWidth: '0px !important',
  },
  'tfoot tr': {
    paddingRight: '15px',
  },
}));

export const StyledFooter = styled('tfoot')(({ theme }) => ({
  display: 'table',
  width: '100%',
  background: theme.palette.background.body,
}));

export const StyledTd = styled('td')<{ isPinned?: boolean }>(({ isPinned, theme }) => ({
  verticalAlign: 'middle',
  background: `${isPinned ? theme.palette.background.body : ''} !important`,
}));

export const StyledTh = styled('th')<{ isSortable?: boolean; isPinned?: boolean }>(
  ({ isSortable, isPinned, theme }) => ({
    p: 2,
    background: isPinned ? theme.palette.background.body : '',
    alignContent: 'center',
    ':hover': {
      background: isSortable ? theme.palette.primary.outlinedHoverBg : '',
    },
  })
);

export const StyledTr = styled('tr')({});

export const StyledTHead = styled('thead')(({ theme }) => ({
  display: 'table',
  width: '100%',
  tableLayout: 'fixed',
  background: theme.palette.background.body,
}));

export const StyledTBody = styled('tbody')(({ theme }) => ({
  display: 'block',
  width: '100%',
  minHeight: '180px',
  background: theme.palette.background.surface,
  tr: {
    display: 'table',
    width: '100%',
    tableLayout: 'fixed',
  },
  'tr td:first-child': {
    borderLeft: 'none !important',
  },
  'tr td:last-child': {
    borderRight: 'none !important',
  },
  '::-webkit-scrollbar': {
    width: '7px',
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: theme.palette['border']['border'],
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.neutral['softActiveColor'] + '80',
    borderRadius: '4px',
  },
}));

export const StyledIconButton = styled(IconButton)<{ isSelected?: boolean }>(
  ({ isSelected, theme }) => ({
    borderRadius: '50%',
    background: isSelected ? theme.palette.background.tooltip : '',
    ':hover': {
      background: isSelected
        ? theme.palette.background.tooltip
        : theme.palette.primary.outlinedHoverBg,
    },
  })
);

import BuildingIcon from 'jsx:../../assets/icons/building.svg';
import CheckListIcon from 'jsx:../../assets/icons/check-list.svg';
import ExecutiveSummaryIcon from 'jsx:../../assets/icons/executive-summary.svg';
import GenderIcon from 'jsx:../../assets/icons/gender.svg';
import GHGIcon from 'jsx:../../assets/icons/ghg-globe.svg';
import ComparisonIcon from 'jsx:../../assets/icons/graph-arrow-up.svg';
import HeartBrokenIcon from 'jsx:../../assets/icons/heart-broken.svg';
import HeartHandShakeIcon from 'jsx:../../assets/icons/heart-handshake.svg';
import HomeIcon from 'jsx:../../assets/icons/home.svg';
import HumanRightsIcon from 'jsx:../../assets/icons/human-rights.svg';
import LaborCartIcon from 'jsx:../../assets/icons/labor-cart.svg';
import HammerIcon from 'jsx:../../assets/icons/law-hammer.svg';
import LotusIcon from 'jsx:../../assets/icons/lotus.svg';
import SDGIcon from 'jsx:../../assets/icons/sdg-target.svg';
import SocialRisksIcon from 'jsx:../../assets/icons/shield-tick.svg';

import {
  EVNTAPP_analytics_menu_click,
  EVNTAPP_esr_rating_menu_click,
  EVNTAPP_executive_summary_menu_click,
  EVNTAPP_ghg_rating_menu_click,
  EVNTAPP_sdg_rating_menu_click,
} from '../../services/analytics/events';
import { theme } from '../../theme';
import { sortByKey } from '../../utils/functions/array';
import { dateFormat } from '../../utils/functions/date';
import { getGHGImpactRatingDetail } from '../../utils/functions/ghg';
import { median } from '../../utils/functions/number';
import { getProjectAttributes } from '../../utils/functions/other';
import { getSDGImpactRatingDetail } from '../../utils/functions/sdg';

export const getIssuanceSizeCategory = (issuanceQuantity: number) => {
  const issuanceSize = Number(issuanceQuantity);
  if (issuanceSize < 50000) {
    return '0-50K';
  } else if (issuanceSize > 50000 && issuanceSize < 100000) {
    return '50-100K';
  } else if (issuanceSize > 100000 && issuanceSize < 500000) {
    return '100-500K';
  } else if (issuanceSize > 500000 && issuanceSize < 1000000) {
    return '500K-1M';
  } else if (issuanceSize > 1000000 && issuanceSize < 5000000) {
    return '1M-5M';
  } else if (issuanceSize > 5000000) {
    return '5M+';
  } else {
    return 'None';
  }
};

export const screeningIconMap = {
  transparency: {
    icon: HammerIcon,
  },
  biodiversity: {
    icon: LotusIcon,
  },
  health: {
    icon: HeartBrokenIcon,
  },
  cultural_heritage: {
    icon: HomeIcon,
  },
  gender_equity: {
    icon: GenderIcon,
  },
  human_rights: {
    icon: HumanRightsIcon,
  },
  labour_rights: {
    icon: LaborCartIcon,
  },
  land_rights: {
    icon: BuildingIcon,
  },
  resource_efficiency: {
    icon: CheckListIcon,
  },
  benefit_sharing: {
    icon: HeartHandShakeIcon,
  },
};

export const projectSections = [
  {
    id: 'executive_summary',
    title: 'Executive summary',
    Icon: ExecutiveSummaryIcon,
    event: EVNTAPP_executive_summary_menu_click,
    badge: '',
  },
  {
    id: 'ghg_rating',
    title: 'GHG rating',
    Icon: GHGIcon,
    event: EVNTAPP_ghg_rating_menu_click,
    badge: '',
  },
  {
    id: 'sdg_rating',
    title: 'SDG rating',
    Icon: SDGIcon,
    event: EVNTAPP_sdg_rating_menu_click,
    badge: '',
  },
  {
    id: 'esr_rating',
    title: 'ESR screening',
    Icon: SocialRisksIcon,
    event: EVNTAPP_esr_rating_menu_click,
    badge: 'Report Available',
  },
  {
    id: 'project_comparison',
    title: 'Analytics',
    Icon: ComparisonIcon,
    event: EVNTAPP_analytics_menu_click,
    badge: '',
  },
  // {
  //   id: 'related_content',
  //   title: 'Related Content',
  //   Icon: RelatedContentIcon,
  //   event: EVNTAPP_related_content_menu_click,
  //   badge: '',
  // },
];

export const projectComparisonYears = [
  {
    id: 'activity',
    label: 'Activity year',
  },
  {
    id: 'vintage',
    label: 'Vintage',
  },
];

export const sdgImapctNegative =
  'Calyx Global notes the absence of key SDG documentation on the corresponding carbon crediting registry. Therefore, the SDG Impact Rating cannot be conducted.';
export const sdgImpactPositive =
  "Calyx Global notes that any SDG contributions referred to in the carbon credit program documents are self-claimed and have not been verified by a third party. Therefore, this project is not eligible to be rated under Calyx Global's SDG Impact rating.";

export const getProjectDetail = (data: any) => {
  let projectState = '',
    projectCountry = '',
    projectRegion = '',
    projectFlag = '',
    projectStates = '';
  let projectLat = 0,
    projectLong = 0;
  const locationData = data?.locations?.[0]?.location;
  const creditingPeriods =
    (data?.all_crediting_periods || []).length === 0
      ? []
      : sortByKey(
          (data?.all_crediting_periods || []).map((period) => ({
            ...period,
            start_date: dateFormat(period.start_date).utcYear,
            end_date: dateFormat(period.end_date).utcYear,
          })),
          'start_date'
        );
  if (locationData?.type === 'state') {
    projectCountry = locationData.parent?.display_name || '';
    projectState = locationData?.display_name || '';
    projectLat = locationData?.parent?.latitude || 0;
    projectLong = locationData?.parent?.longitude || 0;
    projectRegion = locationData?.parent?.parent?.display_name || '';
    projectFlag = locationData?.parent?.project_location_flag?.[0]?.mini || '';
  } else if (locationData?.type === 'country') {
    projectCountry = locationData.display_name || '';
    projectState = '';
    projectLat = locationData?.latitude || 0;
    projectLong = locationData?.longitude || 0;
    projectRegion = locationData?.parent?.display_name || '';
    projectFlag = locationData?.project_location_flag?.[0]?.mini || '';
  }
  projectStates = (data?.locations || []).map(({ location }) => {
    if (location?.type === 'state') {
      return location?.display_name || '';
    }
    return '';
  });

  function getMaxRiskLevel(data: any) {
    if (!data) return;
    const riskLevels: any[] = [];

    data.forEach((item: any) => {
      if (item?.maxRiskLevel) {
        riskLevels.push(item?.maxRiskLevel);
      }

      if (item.risks && item.risks.length > 0) {
        item.risks.forEach((risk) => {
          if (risk.risk_level) {
            riskLevels.push(risk?.risk_level);
          }
        });
      }
    });

    if (riskLevels.length === 0) return null;

    const frequency = riskLevels.reduce((acc, level) => {
      acc[level] = (acc[level] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(frequency).sort((a: any, b: any) => b[1] - a[1])[0][0];
  }

  const sdgRatingDetail = data?.project_crediting_periods[0]?.ratings?.sdg_rating_detail;

  return {
    title: data?.name,
    description: data?.about,
    logo: data?.project_types[0]?.project_type?.logo,
    code: `${data?.ghg_standard?.code || ''}${data?.project_id || ''}`,
    ghgStandardCode: data?.ghg_standard?.code || '',
    ghgStandardFullform: data?.ghg_standard?.full_form || '',
    sdgImpactRating: data?.project_crediting_periods[0]?.ratings?.sdg_impact_rating,
    projectType: data?.project_types[0]?.project_type?.display_name || '',
    projectCategory: data?.project_types[0]?.project_type?.project_group?.display_name || '',
    methodology: data?.ghg_methodologies[0]?.ghg_methodology?.display_name || '',
    certificate: data?.project_crediting_periods[0]?.ratings?.sdg_certificate?.code || '',
    duration: `${dateFormat(data?.start_year).utcYear}-${dateFormat(data?.end_year).utcYear}`,
    proponents: data?.proponents[0]?.proponent?.display_name || '',
    projectAttributes: getProjectAttributes(
      data?.project_crediting_periods[0]?.project_attributes || []
    ).attributeDetails,
    ghgRatingDetail: data?.project_crediting_periods[0]?.ratings?.ghg_rating_detail,
    sdgRatingDetail,
    sdgImpact: getSDGImpactRatingDetail(
      data?.project_crediting_periods[0]?.ratings?.sdg_rating_detail?.rating
    ),
    ghgImpact: getGHGImpactRatingDetail(
      data?.project_crediting_periods[0]?.ratings?.ghg_rating_detail?.grade
    ),
    projectRatings: data?.project_crediting_periods[0]?.ratings,
    dateOfRating: data?.project_crediting_periods[0]?.ratings?.ghg_risk_rating_date
      ? dateFormat(data?.project_crediting_periods[0]?.ratings?.ghg_risk_rating_date).mmyyyy
      : '',
    projectState,
    projectCountry,
    location: {
      latitude: projectLat,
      longitude: projectLong,
      country: projectCountry,
      state: projectState,
      region: projectRegion,
      states: projectStates,
      flag: projectFlag,
    },
    isFollowing: !!data?.project_crediting_periods[0]?.project_follow_user?.length,
    confirmedSdgs: data?.project_crediting_periods[0]?.ratings?.confirmed_sdgs,
    creditingPeriods,
    projectId: data?.project_id,
    creditingPeriodId: data?.project_crediting_periods[0]?.id,
    esrRisks: data?.project_crediting_periods[0]?.esr?.esr_safeguard_areas,
    enableGHGDeepDive: data?.project_crediting_periods[0]?.ratings?.enable_ghg_deep_dive,
    enableSDGDeepDive:
      sdgRatingDetail?.grade !== 'N/A' && sdgRatingDetail?.grade !== 'Not certified',
    maxESRRisk: getMaxRiskLevel(data?.project_crediting_periods?.[0]?.esr?.esr_safeguard_areas),
  };
};

export const tranformAnnualCreditData = (data: any) => {
  const activityYear = data.activityYear;
  const vintage = data.vintage;

  const activityIssued = Object.keys(activityYear.issued).map((year) => [
    year,
    activityYear.issued[year],
  ]);
  const activityRetired = Object.keys(activityYear.retired).map((year) => [
    year,
    activityYear.retired[year],
  ]);
  const vintageIssued = Object.keys(vintage.issued).map((year) => [year, vintage.issued[year]]);
  const vintageRetired = Object.keys(vintage.retired).map((year) => [year, vintage.retired[year]]);

  return {
    ...data,
    activityYear: {
      issued: activityIssued,
      retired: activityRetired,
    },
    vintage: {
      issued: vintageIssued,
      retired: vintageRetired,
    },
  };
};

export const transformRetirementData = (data: any) => {
  const keys = Object.keys(data?.data || {});
  return {
    totalRetired: data?.totalRetired || 0,
    graph: keys.map((key) => [key, data?.data?.[key] || '']),
  };
};

export const getProjectSdgData = (data: any) => {
  const {
    sections,
    diffContributions,
    sdgContributions,
    implicitContributions,
    explicitContributions,
  } = tranformBlurbsToSections(data?.rating_blurb_sdg_sections || [], data?.sdgRatingDetails);

  return {
    description: data?.project_story_line || '',
    certificate: data?.sdgRatingDetails?.sdg_certificate || '',
    date: data?.sdgRatingDetails?.date_of_rating
      ? dateFormat(data?.sdgRatingDetails?.date_of_rating).mmyyyy
      : '',
    sections,
    diffContributions,
    sdgContributions,
    implicitContributions,
    explicitContributions,
    hasDetailedView: data?.detailed_data_available,
    readMoreAccess: data?.read_more_access,
  };
};

export const tranformBlurbsToSections = (blurps: any[], details: any) => {
  const map = {},
    sdgMap = {},
    confirmedMap = {},
    insufficientMap = {},
    ratedMap = {};

  const confirmedSdgs = details?.confirmed_sdgs || [];
  const insufficientEvidenceSdgs = details?.insufficient_evidence_sdgs || [];
  const ratedUnderDifferentSds = details?.rated_under_different_sdgs || [];
  blurps.forEach((data) => {
    const id = data.section_name.replace(/[^0-9]/g, '');
    if (!map[data.section_name]) {
      map[data.section_name] = [data];
    } else {
      map[data.section_name] = [...map[data.section_name], data];
    }
    sdgMap[id] = [...map[data.section_name]];
  });
  confirmedSdgs.forEach((data) => {
    if (sdgMap[data.sdg]) confirmedMap[data.sdg] = { data: sdgMap[data.sdg], ...data };
  });
  insufficientEvidenceSdgs.forEach((data) => {
    insufficientMap[data.sdg] = { data: sdgMap[data.sdg], ...data };
  });
  ratedUnderDifferentSds.forEach((data) => {
    ratedMap[data.sdg] = { data: sdgMap[data.sdg], ...data };
  });
  const confirmedTargets = Object.keys(confirmedMap).reduce((acc: any[], key) => {
    return [...acc, ...(confirmedMap[key]?.data || [])];
  }, []);

  const diffContributions = confirmedTargets.length;

  const explicitContributions = confirmedTargets.filter((data) => {
    return data.claim.toLowerCase() === 'explicit';
  }).length;

  const implicitContributions = confirmedTargets.filter((data) => {
    return data.claim.toLowerCase() === 'implicit';
  }).length;

  return {
    diffContributions,
    sdgContributions: Object.keys(confirmedMap).length,
    implicitContributions,
    explicitContributions,
    sections: {
      confirmedSdgs: Object.keys(confirmedMap).map((key) => ({
        ...confirmedMap[key],
      })),
      insufficientEvidenceSdgs: Object.keys(insufficientMap).map((key) => ({
        ...insufficientMap[key],
      })),
      ratedUnderDifferentSds: Object.keys(ratedMap).map((key) => ({
        ...ratedMap[key],
      })),
    },
  };
};

export const getGhgSdgGraphData = (result: any) => {
  const datas: any[] = [];
  const ghgMedian: number[] = [];
  const sdgMedian: number[] = [];

  const ThisGHG = result?.projectRatingData?.ghg_risk_rating
    ? 9 - result?.projectRatingData?.ghg_risk_rating
    : 0;
  const ThisSDG = result?.projectRatingData?.sdg_impact_rating || 0;
  const thisProjectCords = [ThisGHG - 0.5, parseFloat(ThisSDG) + 0.5];

  if ((result?.allProjectRatingData || []).length > 0) {
    (result?.allProjectRatingData || []).forEach((hr_row, i) => {
      const sGHG = hr_row['ghg_risk_rating'] ? 9 - hr_row['ghg_risk_rating'] : 0;
      const sSDG = hr_row['sdg_impact_rating'] || 0;
      if (sGHG <= 10) {
        ghgMedian.push(sGHG - 1);
        sdgMedian.push(sSDG);
        ghgMedian.sort();
        sdgMedian.sort();
        const gMedain = median(ghgMedian);
        const sMedain = median(sdgMedian);
        const cordinates = [sGHG - 0.5, parseFloat(sSDG) + 0.5];
        const cntRating = hr_row['totalProjects'] || 0;

        const bubData: any = [];
        for (let z = 0; z < sGHG - 1; z++) {
          bubData.push(null);
        }
        bubData.push(sSDG);
        let color = '#00A0B2';
        let name = 'Other projects';
        let drawline: any = {};
        let markline: any = {};
        let drawArea: any = {};
        let markArea: any = {};
        if (sSDG <= 0) {
          markline = drawline = {
            data: [
              {
                xAxis: 0,
              },
              {
                yAxis: 0,
              },
            ],
            symbol: 'none',
            silent: 'true',
            label: {
              show: false,
            },
            lineStyle: {
              color: '#666666',
              type: 'dashed',
              width: '1',
            },
          };
          markArea = drawArea = {
            itemStyle: {
              color: theme.palette.mode === 'light' ? 'rgba(99,107,116,0.01)' : '#919599A8',
            },
            data: [
              [
                {
                  tooltip: {
                    show: false,
                  },
                  xAxis: 0,
                  yAxis: 0,
                },
                {
                  xAxis: 10,
                  yAxis: 1,
                },
              ],
            ],
          };
        }
        if ((result?.allProjectRatingData || [])?.length === i + 1) {
          markline = drawline = {
            data: [
              {
                xAxis: gMedain,
              },
              {
                yAxis: sMedain,
              },
            ],
            symbol: 'none',
            silent: 'true',
            label: {
              show: false,
            },
          };
          markArea = drawArea = {
            itemStyle: {
              color: '#4661EC',
              opacity: 0.1,
            },
            label: {
              show: false,
            },
            data: [
              [
                {
                  name: 'Top quartile',
                  xAxis: 10,
                  yAxis: 6,
                },
                {
                  xAxis: gMedain,
                  yAxis: sMedain,
                },
              ],
            ],
          };
        }

        if (
          cordinates[0] === thisProjectCords[0] &&
          cordinates[1] === thisProjectCords[1] &&
          (sGHG !== 0 || sSDG !== 0)
        ) {
          color = '#FFCB14';
          name = 'This project';
          markline = {
            data: [
              {
                xAxis: sGHG - 0.5,
              },
              {
                yAxis: sSDG + 0.5,
              },
            ],
            symbol: 'none',
            silent: 'true',
            label: {
              show: false,
            },
          };
        }
        const maxSize = 40;
        const d = {
          symbolSize:
            cntRating < 1
              ? 5
              : cntRating < 100
                ? Math.sqrt(cntRating) * 3
                : Math.min(maxSize, cntRating * 0.5),
          symbolOffset: [0, 0],
          color: color,
          name: name,
          data: [
            {
              value: cordinates,
              name: cntRating + ' projects',
              ...hr_row,
            },
          ],
          type: 'scatter',
          markLine: { ...markline, 0: { ...drawline } },
          markArea: { ...markArea, 0: { ...drawArea } },
        };
        datas.push(d);
      }
    });
  }
  return datas;
};

export const moveToLatLng = (canvasRef, pointerRef, longitude, latitude) => {
  if (!canvasRef || !pointerRef)
    return {
      left: 0,
      top: 0,
    };

  const mapWidth = canvasRef.width;
  const mapHeight = canvasRef.height;

  // Convert longitude and latitude into pixel coordinates
  const x = (longitude + 180) * (mapWidth / 360);
  const y = (90 - latitude) * (mapHeight / 180);

  // Move the pointer to the calculated position
  const pointer = pointerRef;
  const mapPointerWidth = pointer.width;
  const mapPointerHeight = pointer.height;

  const adjustedX = x - mapPointerWidth / 2;
  const adjustedY = y - mapPointerHeight;

  return {
    left: adjustedX,
    top: adjustedY,
  };
};

export const riskAreaTranformer = (data: any[]) => {
  return data.reduce(
    (acc, value) => [
      ...acc,
      {
        id: value?.area_covered?.name,
        name: value?.area_covered?.display_name,
        icon: screeningIconMap[value?.area_covered?.name]?.icon || '',
      },
    ],
    []
  );
};

export const transformRiskData = (data: any[]) => {
  const transformedData: any[] = [];
  const categoryMap: { [key: string]: any[] } = {};
  data.forEach((item) => {
    const { area_category, areas } = item;

    if (categoryMap[area_category]) {
      categoryMap[area_category].push(...areas);
    } else {
      categoryMap[area_category] = [...areas];
    }
  });
  for (const [category, areas] of Object.entries(categoryMap)) {
    transformedData.push({ category, areas });
  }
  return transformedData;
};

export const riskRelatedTo = {
  project_activities: 'Project Activities',
  project_site: 'Project Site',
};

import { useEffect, useRef, useState } from 'react';

export const useScrollRestoration = (key: string) => {
  const savedScrollPosition = Number(sessionStorage.getItem(`scroll-${key}`)) || 0;
  const scrollYRef = useRef(savedScrollPosition);
  const [scrollY, setScrollY] = useState(savedScrollPosition);

  useEffect(() => {
    if (scrollYRef.current !== 0) {
      setScrollY(scrollYRef.current);
    }
  }, []);

  const setScrollYOffset = (offset: number) => {
    scrollYRef.current = offset;
    setScrollY(offset);
    sessionStorage.setItem(`scroll-${key}`, String(offset));
  };

  return { scrollY, setScrollYOffset };
};

export type Filter = {
  name: string;
  display_name: string;
  type?: string;
  helper_text?: string;
  options?: { name: string; display_name: string }[];
  filters: Filter[];
};

export type Section = {
  name: string;
  type?: string;
  display_name: string;
  filters: Filter[];
};

export enum FilterType {
  MULTI_SELECT = 'multi_select',
  TEXT = 'text',
  CHIP_INPUT = 'chip_input',
}

export type FilterValue =
  | string
  | number
  | boolean
  | Array<string | number>
  | Record<string, string>
  | { [k: string]: 'DESC' | 'ASC' }
  | FilterObject;

export type FilterObject = {
  [key: string]: FilterValue;
};

export type FilterListRef = {
  clearAll: () => void;
  handleOnChange: (key: string, value: boolean) => void;
};

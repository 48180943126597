import { Card, Skeleton, Stack, Tooltip, Typography } from '@mui/joy';
import { SxProps, TypographySystem } from '@mui/joy/styles/types';
import { MdInfoOutline } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { RatingCircle } from '../../../../components/RatingCircle';
import { track } from '../../../../services/analytics';
import {
  EVENT_EXECUTIVE_SUMMARY_PAGE,
  EVNT_exec_GHG_rating_tile_clicked,
  EVNT_exec_SDG_rating_tile_clicked,
} from '../../../../services/analytics/events';
import { projectState } from '../../../../stores/project';

const RatingCard = ({
  title,
  percentage,
  rating,
  path,
  type,
  impact,
  disableRedirection = false,
  ...sxProps
}: {
  title: string;
  percentage: number;
  rating: string;
  path: string;
  type: 'sdg' | 'ghg';
  impact?: string;
  disableRedirection?: boolean;
} & SxProps) => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const { isLoading, projectData } = useRecoilValue(projectState);

  if (isLoading)
    return (
      <Card sx={{ position: 'relative', minHeight: '80px', p: 2, mb: 3 }}>
        <Skeleton width={'90%'} height={'60%'} />
      </Card>
    );

  const handleWidgetClick = () => {
    let event = '';
    if (type === 'ghg') {
      event = EVNT_exec_GHG_rating_tile_clicked;
    }
    if (type === 'sdg') {
      event = EVNT_exec_SDG_rating_tile_clicked;
    }
    track(event, EVENT_EXECUTIVE_SUMMARY_PAGE, {
      project_id: projectData?.projectId,
      page_url: `${pathname}${search}`,
    });
    navigate({ pathname: path, search });
  };

  return (
    <Card
      sx={{
        mb: 2,
        gap: 0,
        p: (theme) => theme.spacing(3),
        cursor: disableRedirection ? 'default' : 'pointer',
        ...sxProps,
      }}
      onClick={disableRedirection ? undefined : handleWidgetClick}
    >
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Stack justifyContent={'center'}>
          <Typography level={'sub1' as keyof TypographySystem} color='primary'>
            {title}
          </Typography>
          {impact && (
            <Typography
              fontSize={'md'}
              fontWeight={'md'}
              sx={{ color: (theme) => theme.palette.text.secondary }}
              noWrap
            >
              {impact}
            </Typography>
          )}
        </Stack>
        {rating === 'Not certified' ? (
          <Typography
            level='body-sm'
            height={'60px'}
            endDecorator={
              <Tooltip
                title={`This project does not have SDG contributions that have been certified by a recognized carbon crediting program.`}
                sx={{ maxWidth: 300 }}
                placement='right'
              >
                <Stack alignItems={'center'} justifyContent={'center'} ml={'4px'}>
                  <MdInfoOutline size={16} />
                </Stack>
              </Tooltip>
            }
          >
            Not certified
          </Typography>
        ) : (
          <RatingCircle size='md' percentage={percentage} rating={rating} type={type} />
        )}
      </Stack>
    </Card>
  );
};
export default RatingCard;

import { useEffect, useRef } from 'react';

import { Grid, Stack } from '@mui/joy';
import { useRecoilValue } from 'recoil';

import { track } from '../../../services/analytics';
import { EVENT_EXECUTIVE_SUMMARY_PAGE, PGV_exec_summary } from '../../../services/analytics/events';
import { projectState } from '../../../stores/project';

import Crediting from './components/Crediting';
import EnvAndSocialRisk from './components/EnvAndSocialRisk';
import Location from './components/Location';
import { ProjectAttributes } from './components/projectAttributes/ProjectAttributes';
import ProjectComparison from './components/ProjectComparison';
import { ProjectInfo } from './components/ProjectInfo';
import ProjectSize from './components/ProjectSize';
import RatingCard from './components/RatingCard';
import RelatedContent from './components/RelatedContent';

export const ExecutiveSummary = () => {
  const { projectData, projectCreditingData } = useRecoilValue(projectState);
  const isPageTracked = useRef<boolean>(false);

  useEffect(() => {
    if (projectData && !!projectCreditingData?.issuanceSizeCategory && !isPageTracked.current) {
      isPageTracked.current = true;
      track(PGV_exec_summary, EVENT_EXECUTIVE_SUMMARY_PAGE, {
        project_id: projectData?.projectId,
        project_name: projectData?.title,
        project_category: projectData?.projectCategory,
        project_type: projectData?.projectType,
        issuance_size: projectCreditingData?.issuanceSizeCategory,
        ghg_rating: projectData?.ghgRatingDetail?.grade,
        sdgRating: projectData?.sdgRatingDetail?.grade,
      });
    }
  }, [projectData, projectCreditingData]);

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2 }} columns={{ xs: 3, lg: 12 }}>
        <Grid xs={4}>
          <RatingCard
            rating={projectData?.ghgRatingDetail?.grade || ''}
            percentage={projectData?.ghgRatingDetail?.percentage || 0}
            impact={projectData?.ghgImpact || ''}
            title='GHG Rating'
            path='/ghg_rating'
            type='ghg'
            display={{ xs: 'none', lg: 'flex' }}
          />
        </Grid>
        <Grid xs={4}>
          <RatingCard
            rating={projectData?.sdgRatingDetail?.grade || ''}
            percentage={projectData?.sdgRatingDetail?.percentage || 0}
            impact={projectData?.sdgImpact || ''}
            title='SDG Rating'
            path='/sdg_rating'
            type='sdg'
            display={{ xs: 'none', lg: 'flex' }}
            disableRedirection={projectData?.sdgRatingDetail?.grade === 'Not certified'}
          />
        </Grid>
        <Grid xs={4}>
          <EnvAndSocialRisk sxProps={{ display: { xs: 'none', lg: 'flex' } }} />
        </Grid>
      </Grid>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2 }} columns={{ xs: 4, lg: 12 }}>
        <Grid xs={8}>
          <Stack gap={2}>
            <ProjectInfo />
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 2 }}
              columns={{ xs: 6, lg: 12 }}
              sx={{ display: { xs: 'flex', lg: 'none' } }}
            >
              <Grid xs={3}>
                <RatingCard
                  rating={projectData?.ghgRatingDetail?.grade || ''}
                  percentage={projectData?.ghgRatingDetail?.percentage || 0}
                  impact={projectData?.ghgImpact || ''}
                  title='GHG Rating'
                  path='/ghg_rating'
                  type='ghg'
                  mb={0}
                />
              </Grid>
              <Grid xs={3}>
                <RatingCard
                  rating={projectData?.sdgRatingDetail?.grade || ''}
                  percentage={projectData?.sdgRatingDetail?.percentage || 0}
                  impact={projectData?.sdgImpact || ''}
                  disableRedirection={projectData?.sdgRatingDetail?.grade === 'Not certified'}
                  title='SDG Rating'
                  path='/sdg_rating'
                  type='sdg'
                  mb={0}
                />
              </Grid>
              <Grid xs={3}>
                <EnvAndSocialRisk sxProps={{ mb: 0 }} />
              </Grid>
            </Grid>
            <ProjectAttributes />
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 2 }}
              columns={{ xs: 6, lg: 12 }}
            >
              <Grid xs={6}>
                <Crediting />
              </Grid>
              <Grid xs={6}>
                <ProjectSize />
              </Grid>
            </Grid>
          </Stack>
        </Grid>
        <Grid xs={4}>
          <ProjectComparison />
          <RelatedContent />
          <Location />
        </Grid>
      </Grid>
    </>
  );
};

import React from 'react';

import { StyledTd } from './styles';

type CellProps = React.HTMLAttributes<HTMLTableCellElement> & { isPinned?: boolean };

const Cell: React.FC<React.PropsWithChildren<CellProps>> = ({ children, isPinned, ...props }) => {
  return (
    <StyledTd isPinned={isPinned} {...props}>
      {children}
    </StyledTd>
  );
};

export default Cell;

import { useMemo } from 'react';

import { Box, Card, Chip, Grid, Skeleton, Stack, Tooltip, Typography } from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import { MdInfoOutline } from 'react-icons/md';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { RatingCircle } from '../../../components/RatingCircle';
import { makeAuthenticatedGetRequest } from '../../../services/axios';
import { projectSdgData } from '../../../services/axios/endpoints';
import { projectState } from '../../../stores/project';
import { getProjectSdgData } from '../constants';

import SdgDetail from './components/SDGDetail';

const emptyItems = Array(3).fill('');
export const SDGRating = () => {
  const { projectData } = useRecoilValue(projectState);
  const [params] = useSearchParams();
  const ghg_standard_code = params.get('standard');
  const crediting_period_id = params.get('cp_no');
  const project_id = params.get('id');
  const { data, isFetching } = useQuery({
    queryKey: [`sdg-rating-detail-data`, crediting_period_id],
    queryFn: async () =>
      makeAuthenticatedGetRequest(projectSdgData, {
        params: { project_id, crediting_period_id, ghg_standard_code },
      }),
    select: (data) => getProjectSdgData(data?.data?.data),
  });

  const hasPermissionForReadMore = useMemo(() => data?.readMoreAccess ?? false, [data]);

  if (isFetching)
    return (
      <Stack sx={{ mx: 'auto', mb: 3, maxWidth: 'lg' }}>
        <Card sx={{ padding: 3 }}>
          <Box display={'grid'} gridTemplateColumns={'1fr 7fr'} alignItems={'center'} gap={3}>
            <Skeleton variant='circular' width={120} height={120} animation='wave' />
            <Stack spacing={1.5}>
              {emptyItems.map(() => {
                return (
                  <Skeleton variant='rectangular' width={'100%'} height={'1em'} animation='wave' />
                );
              })}
            </Stack>
          </Box>
        </Card>
        <Card sx={{ mt: 3 }}>
          <Grid container sx={{ p: 3 }}>
            <Grid xs={6} sx={{ pr: 3 }}>
              {emptyItems.map(() => {
                return (
                  <Skeleton variant='rectangular' width={'100%'} height={'1em'} animation='wave' />
                );
              })}
            </Grid>
            <Grid xs={6}>
              {emptyItems.map(() => {
                return (
                  <Skeleton variant='rectangular' width={'100%'} height={'5em'} animation='wave' />
                );
              })}
            </Grid>
          </Grid>
        </Card>
      </Stack>
    );

  return (
    <Stack sx={{ mx: 'auto', mb: 3, maxWidth: 'lg' }} gap={3}>
      <Card sx={{ p: 3 }}>
        <Typography level='h2'>SDG Rating</Typography>
        <Grid container sx={{ pt: 2 }}>
          <Grid alignContent={'center'} xs={2} sm={3}>
            <Stack direction={'column'} justifyContent={'center'} alignItems={'start'} gap={2}>
              <Stack direction={'row'} alignItems={'center'} gap={2}>
                {projectData?.sdgRatingDetail?.grade !== 'Not certified' ? (
                  <RatingCircle
                    type='sdg'
                    percentage={projectData?.sdgRatingDetail?.percentage || 0}
                    rating={projectData?.sdgRatingDetail?.grade || ''}
                    size='lg'
                  />
                ) : (
                  <Typography
                    level='body-sm'
                    px={1}
                    endDecorator={
                      <Tooltip
                        title={`This project does not have SDG contributions that have been certified by a recognized carbon crediting program.`}
                        sx={{ maxWidth: 300 }}
                        placement='right'
                      >
                        <Stack alignItems={'center'} justifyContent={'center'} ml={'4px'}>
                          <MdInfoOutline size={16} />
                        </Stack>
                      </Tooltip>
                    }
                  >
                    Not certified
                  </Typography>
                )}
                {projectData?.sdgImpact && (
                  <Typography fontSize={'md'} fontWeight={'md'}>
                    {projectData?.sdgImpact || ''}
                  </Typography>
                )}
              </Stack>
              <Chip variant='outlined' color='success'>
                {data?.certificate} Certified
              </Chip>
              {!!data?.date ? (
                <Typography level='body-sm' color='primary'>
                  Date of rating: {data?.date}
                </Typography>
              ) : null}
            </Stack>
          </Grid>
          <Grid xs={10} sm={9} sx={{ px: 2 }} alignContent={'center'}>
            <Typography level='body-md'>{data?.description}</Typography>
          </Grid>
        </Grid>
      </Card>
      {projectData?.enableSDGDeepDive && data?.hasDetailedView ? (
        <SdgDetail data={data} hasPermissionForReadMore={hasPermissionForReadMore} />
      ) : null}
    </Stack>
  );
};

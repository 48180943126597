import { AppliedFilterItem } from './types';

// the url params which do not exist in filter json but are used for filtering
export const WHITE_FILTER_KEY_LIST = ['following'];
// the keys that we dont want to send to the backend
export const NON_PAYLOAD_FILTER_KEY_LIST = ['view'];
// ignore keys from clearing in filter when clear all is clicked
export const ignoredKeys = ['search_text', 'view', 'sort'];

export const findByKey = (set, targetKey, value): AppliedFilterItem | null => {
  let removedItem = null;

  set.forEach((item) => {
    if (item && item[targetKey] === value) {
      removedItem = item;
    }
  });

  return removedItem;
};

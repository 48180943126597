import React from 'react';

import { Grid } from '@mui/joy';

import { DistributionDoughnut } from './charts/DistributionDoughnut';
import { DistributionGraphV2 } from './charts/DistributionGraphV2';
import { DistributionScatterV2 } from './charts/DistributionScatterV2';

const Charts: React.FC<{ data: any; isLoading: boolean }> = ({ data, isLoading }) => {
  return (
    <Grid container columnSpacing={3} rowSpacing={3} pb={3}>
      <Grid xs={12} lg={6}>
        <DistributionGraphV2 data={data?.ghgRatingGraphData} type='ghg' isLoading={isLoading} />
      </Grid>
      <Grid xs={12} lg={6}>
        <DistributionGraphV2 data={data?.sdgRatingGraphData} type='sdg' isLoading={isLoading} />
      </Grid>
      <Grid xs={12} lg={6}>
        <DistributionScatterV2 rowData={data?.ghgSdgGridData} isLoading={isLoading} />
      </Grid>
      <Grid xs={12} lg={6}>
        <DistributionDoughnut rowData={data?.ghgRatingDoughnutData} isLoading={isLoading} />
      </Grid>
    </Grid>
  );
};

export { Charts };

import React from 'react';

import { Button, ButtonGroup, Grid, Select, Stack, Option } from '@mui/joy';
import { MutationFunction, useMutation } from '@tanstack/react-query';
import { FaChevronDown } from 'react-icons/fa';
import { IoAddCircleOutline, IoRemoveCircleOutline } from 'react-icons/io5';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { track } from '../../../services/analytics';
import {
  EVENT_EXECUTIVE_SUMMARY_PAGE,
  EVNT_exec_follow_project_clicked,
} from '../../../services/analytics/events';
import { makeAuthenticatedGetRequest } from '../../../services/axios';
import { projectFollow, projectUnfollow } from '../../../services/axios/endpoints';
import queryClient from '../../../services/react-query';
import { projectState } from '../../../stores/project';
import { snackbarState } from '../../../stores/snackerbar';
import { FOLLOWING } from '../../home/constants';
import { projectSections } from '../constants';

const ActionBar: React.FC = () => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const { projectData, essRatingData, projectComparisonData, relatedContentArticles } =
    useRecoilValue(projectState);
  const setProjectState = useSetRecoilState(projectState);
  const setSnackbar = useSetRecoilState(snackbarState);
  const ghg_standard_code = params.get('standard');
  const crediting_period_id = params.get('cp_no');
  const project_id = params.get('id');
  const followUnFollowCall: MutationFunction<unknown> = async () => {
    const isFollowing = !!projectData?.isFollowing;
    try {
      const { data } = await makeAuthenticatedGetRequest<unknown>(
        isFollowing ? projectUnfollow : projectFollow,
        {
          params: {
            ghg_standard_code,
            crediting_period_id,
            project_id,
          },
        }
      );
      return data;
    } catch (error: any) {
      throw new Error(error?.response?.data?.message || error.message);
    }
  };

  const { mutate: followMutate, isPending } = useMutation<unknown, Error>({
    mutationFn: followUnFollowCall,
    onSuccess: () => {
      const isFollowing = !!projectData?.isFollowing;
      setSnackbar({
        message: `${isFollowing ? 'Unfollowed' : 'Followed'} successfully !`,
        color: 'success',
      });
      setProjectState((value) => ({
        ...value,
        projectData: { ...projectData, isFollowing: !projectData?.isFollowing },
      }));
      queryClient.invalidateQueries({ queryKey: [`dashboard-ratings-${FOLLOWING}`] });
    },
    onError: (error) => {
      setSnackbar({ message: error.message || 'Something went wrong', color: 'danger' });
    },
  });
  const handleFollow = () => {
    if (isPending) return;
    track(EVNT_exec_follow_project_clicked, EVENT_EXECUTIVE_SUMMARY_PAGE, {
      project_id: projectData?.projectId,
      page_url: `${pathname}${search}`,
    });
    followMutate();
  };

  const handleCreditingPeriodChange = (_, value) => {
    if (crediting_period_id === value || value === null) return;
    params.set('cp_no', value);
    setParams(params);
  };
  const isDisabled = (id: string) => {
    switch (id) {
      case 'sdg_rating': {
        const isSDGEnabled = projectData?.enableSDGDeepDive;
        return !isSDGEnabled;
      }
      case 'ess_rating': {
        const content = essRatingData?.content;
        return !content;
      }
      case 'project_comparison': {
        const data = projectComparisonData?.allProjectRatingData;
        return !data || data?.length === 0;
      }
      case 'related_content': {
        return (relatedContentArticles || []).length === 0;
      }
    }
    return false;
  };

  const isActive = (id: string) => {
    return !!pathname.startsWith(`/${id}`);
  };

  return (
    <>
      <Stack direction={'row'} justifyContent={'space-between'} pb={3}>
        <ButtonGroup>
          {projectSections.map((item) => {
            const disabled = isDisabled(item.id);
            const active = isActive(item.id);
            return (
              <Button
                variant={!active ? 'outlined' : 'solid'}
                color={!active ? 'neutral' : 'primary'}
                disabled={disabled}
                onClick={() => {
                  track(item.event, EVENT_EXECUTIVE_SUMMARY_PAGE);
                  if (!disabled) {
                    navigate({
                      pathname: `/${item.id}`,
                      search,
                    });
                  }
                }}
              >
                {item.title}
              </Button>
            );
          })}
        </ButtonGroup>
        <Grid container alignItems={'center'} spacing={3}>
          {(projectData?.creditingPeriods || []).length > 1 && (
            <Grid xs={6}>
              <Select
                defaultValue={crediting_period_id}
                indicator={<FaChevronDown />}
                onChange={handleCreditingPeriodChange}
                variant='outlined'
                sx={{ width: 'fit-content' }}
              >
                {(projectData?.creditingPeriods || []).map((period) => (
                  <Option value={`${period.crediting_period_id}`}>
                    {`${period.start_date} - ${period.end_date}`}
                  </Option>
                ))}
              </Select>
            </Grid>
          )}
          <Grid xs={6}>
            <Stack
              direction={'row'}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{
                gap: 1,
                cursor: isPending ? 'not-allowed' : 'pointer',
                opacity: isPending ? 0.5 : 1,
                minWidth: (theme) => theme.spacing(14),
              }}
              onClick={handleFollow}
            >
              {projectData?.isFollowing ? (
                <>
                  <Button
                    startDecorator={<IoRemoveCircleOutline size={24} />}
                    size='md'
                    variant='outlined'
                  >
                    Unfollow
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    startDecorator={<IoAddCircleOutline size={24} />}
                    size='md'
                    variant='outlined'
                  >
                    Follow
                  </Button>
                </>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export { ActionBar };

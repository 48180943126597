import { brand } from './brand';

export const light = {
  divider: '#636b744D',
  focusVisible: brand.primary.light['600'],
  background: {
    backdrop: '#12141640',
    body: '#f8f9fa',
    level1: brand.common.white,
    level2: brand.neutral['200'],
    level3: brand.primary.light['200'],
    popup: brand.common.white,
    surface: brand.common.white,
    tooltip: brand.function.dark.neutral['90'],
  },
  text: {
    icon: brand.function.light.neutral['90'],
    primary: brand.neutral['800'],
    secondary: brand.function.light.neutral['70'],
    tertiary: brand.neutral['600'],
  },
  danger: {
    darkChannel: '#7d1212',
    lightChannel: '#f7c5c5',
    outlinedActiveBg: brand.danger['200'],
    outlinedBorder: brand.danger['300'],
    outlinedColor: brand.danger['500'],
    outlinedDisabledBorder: brand.neutral['200'],
    outlinedDisabledColor: brand.neutral['400'],
    outlinedHoverBg: brand.danger['100'],
    plainActiveBg: brand.danger['200'],
    plainColor: brand.danger['500'],
    plainDisabledColor: brand.neutral['400'],
    plainHoverBg: brand.danger['100'],
    softActiveBg: brand.danger['300'],
    softActiveColor: brand.danger['800'],
    softBg: brand.danger['100'],
    softColor: brand.danger['700'],
    softDisabledBg: brand.neutral['050'],
    softDisabledColor: brand.neutral['400'],
    softHoverBg: brand.danger['200'],
    solidActiveBg: brand.danger['700'],
    solidBg: brand.danger['500'],
    solidColor: brand.common.white,
    solidDisabledBg: brand.neutral['100'],
    solidDisabledColor: brand.neutral['400'],
    solidHoverBg: brand.danger['600'],
  },
  neutral: {
    darkChannel: '#32383e',
    lightChannel: '#dde7ee',
    mainChannel: '#636b74',
    outlinedActiveBg: brand.neutral['100'],
    outlinedBorder: brand.neutral['300'],
    outlinedColor: brand.neutral['700'],
    outlinedDisabledBorder: brand.neutral['200'],
    outlinedDisabledColor: brand.neutral['400'],
    outlinedHoverBg: brand.neutral['050'],
    plainActiveBg: brand.neutral['200'],
    plainColor: brand.neutral['700'],
    plainDisabledColor: brand.neutral['400'],
    plainHoverBg: brand.neutral['100'],
    plainHoverColor: brand.neutral['900'],
    softActiveBg: brand.neutral['300'],
    softActiveColor: brand.neutral['800'],
    softBg: brand.neutral['100'],
    softColor: brand.neutral['700'],
    softDisabledBg: brand.neutral['050'],
    softDisabledColor: brand.neutral['400'],
    softHoverBg: brand.neutral['200'],
    solidActiveBg: brand.neutral['700'],
    solidBg: brand.neutral['500'],
    solidColor: brand.common.white,
    solidDisabledBg: brand.neutral['100'],
    solidDisabledColor: brand.neutral['400'],
    solidHoverBg: brand.neutral['600'],
  },
  primary: {
    darkChannel: '#0a470a',
    mainChannel: '#1f7a1f',
    lightChannel: '#c7f7c7',
    outlinedActiveBg: brand.primary.light['1000'],
    outlinedBorder: brand.function.dark.neutral['90'],
    outlinedColor: brand.neutral['050'],
    outlinedDisabledBorder: brand.neutral['200'],
    outlinedDisabledColor: brand.neutral['400'],
    outlinedHoverBg: brand.primary.light['200'],
    outlinedHoverBorder: brand.function.light.neutral['30'],
    plainActiveBg: brand.primary.light['900'],
    plainColor: brand.common.black,
    plainDisabledColor: brand.neutral['400'],
    plainHoverBg: brand.primary.light['100'],
    softActiveBg: brand.primary.light['200'],
    softActiveColor: brand.primary.light['700'],
    softBg: brand.primary.light['100'],
    softColor: brand.primary.light['700'],
    softDisabledBg: brand.neutral['050'],
    softDisabledColor: brand.neutral['400'],
    softHoverBg: brand.primary.light['200'],
    solidActiveBg: brand.primary.light['700'],
    solidBg: brand.primary.light['900'],
    solidColor: brand.common.white,
    solidDisabledBg: brand.neutral['100'],
    solidDisabledColor: brand.neutral['400'],
    solidHoverBg: brand.primary.light['600'],
  },
  success: {
    darkChannel: '#0a470a',
    lightChannel: '#c7f7c7',
    mainChannel: '#1f7a1f',
    outlinedActiveBg: brand.primary.light['200'],
    outlinedBorder: brand.neutral['050'],
    outlinedColor: brand.primary.light['800'],
    outlinedDisabledBorder: brand.neutral['200'],
    outlinedDisabledColor: brand.neutral['400'],
    outlinedHoverBg: brand.primary.light['100'],
    plainActiveBg: brand.primary.light['200'],
    plainColor: brand.primary.light['500'],
    plainDisabledColor: brand.neutral['400'],
    plainHoverBg: brand.primary.light['100'],
    softActiveBg: brand.primary.light['300'],
    softActiveColor: brand.primary.light['800'],
    softBg: brand.primary.light['100'],
    softColor: brand.primary.light['700'],
    softDisabledBg: brand.neutral['050'],
    softDisabledColor: brand.neutral['400'],
    softHoverBg: brand.primary.light['200'],
    solidActiveBg: brand.primary.light['600'],
    solidBg: brand.primary.light['400'],
    solidColor: brand.common.white,
    solidDisabledBg: brand.neutral['100'],
    solidDisabledColor: brand.neutral['400'],
    solidHoverBg: brand.primary.light['500'],
  },
  warning: {
    darkChannel: '#492b08',
    lightChannel: '#fce1c2',
    mainChannel: '#9a5b13',
    outlinedActiveBg: brand.warning['200'],
    outlinedBorder: brand.warning['300'],
    outlinedColor: brand.warning['500'],
    outlinedDisabledBorder: brand.neutral['200'],
    outlinedDisabledColor: brand.neutral['400'],
    outlinedHoverBg: brand.warning['100'],
    plainActiveBg: brand.warning['200'],
    plainColor: brand.warning['200'],
    plainDisabledColor: brand.neutral['400'],
    plainHoverBg: brand.warning['100'],
    softActiveBg: brand.warning['300'],
    softActiveColor: brand.warning['800'],
    softBg: brand.warning['100'],
    softColor: brand.warning['700'],
    softDisabledBg: brand.neutral['050'],
    softDisabledColor: brand.neutral['400'],
    softHoverBg: brand.warning['200'],
    solidActiveBg: brand.warning['700'],
    solidBg: brand.warning['500'],
    solidColor: brand.common.white,
    solidDisabledBg: brand.neutral['100'],
    solidDisabledColor: brand.neutral['400'],
    solidHoverBg: brand.warning['600'],
  },
  GHG: {
    AAA: brand.function.light.green['100'],
    AA: brand.function.light.green['80'],
    A: brand.function.light.lime['100'],
    BBB: brand.function.light.yellow['100'],
    BB: brand.function.light.yellow['80'],
    B: brand.function.light.yellow['70'],
    C: brand.function.light.orange['90'],
    D: brand.function.light.red['100'],
  },
  GHGV2: {
    AAA: brand.ocean[800],
    AA: brand.ocean[700],
    A: brand.ocean[600],
    BBB: brand.ocean[500],
    BB: '#257FD9',
    B: brand.ocean[400],
    C: brand.ocean[300],
    D: '#AED0F2',
  },
  ESR: {
    critical: brand.function.light.red['100'],
    high: brand.function.light.orange['90'],
    moderate: brand.function.light.yellow['90'],
    low: brand.function.light['tennis-green'],
  },
  SDG: {
    '1': brand.function.light.green['60'],
    '2': brand.function.light.green['70'],
    '3': brand.function.light.green['80'],
    '4': brand.function.light.green['90'],
    '5': brand.function.light.green['100'],
  },
  SDGV2: {
    '1': brand.ocean[300],
    '2': brand.ocean[400],
    '3': brand.ocean[500],
    '4': brand.ocean[700],
    '5': brand.ocean[800],
    'Not certified': '#9541BC',
  },
  border: {
    border: brand.function.dark.neutral['90'],
  },
  illustrations: {
    illustration_border_green: brand.base.light.forest['80'],
    illustration_border_grey: brand.function.dark.neutral['80'],
  },
  SDGDisplay: {
    '1': brand.function.light.lime['60'],
    '2': brand.function.light.lime['70'],
    '3': brand.function.light.lime['80'],
    '4': brand.function.light.lime['90'],
    '5': brand.function.light.lime['100'],
    '6': brand.function.light.green['60'],
    '7': brand.function.light.green['70'],
    '8': brand.function.light.green['80'],
    '9': brand.function.light.green['90'],
    '10': brand.function.light.green['100'],
    '11': brand.sky.dark['300'],
  },
  graph: {
    issued: brand.sky.light['300'],
    retired: brand.function.light.yellow['90'],
    forestLand: brand.function.light.green['80'],
    householdCommunity: brand.function.light.yellow['80'],
    manufacturingIndustry: brand.function.light.ocean,
    renewableEnergy: brand.sky.light['100'],
    waste: brand.function.light.orange['80'],
  },
  expert: {
    light: brand.expert.light['050'],
    dark: brand.expert.light['100'],
  },
  blur: {
    '25%': brand.blur.light,
  },
  linearGradient: {
    special: brand.linearGradient.light,
  },
  hyperlink: {
    cell: brand.hyperlink.cell.light,
    text: brand.hyperlink.text.light,
    underline: brand.hyperlink.underline.light,
  },
  icon: {
    warning: brand.function.dark.yellow['100'],
    error: brand.function.dark.red['90'],
    success: brand.function.dark.green['100'],
  },
};

import { useEffect, useRef, useState } from 'react';

import { Box, Input, Skeleton, Stack, styled, Typography } from '@mui/joy';
import { IoSearch } from 'react-icons/io5';
import { Link } from 'react-router-dom';

import { track } from '../services/analytics';
import { EVENT_INSIGHTS_HUB_PAGE, EVNT_research_hub_searched } from '../services/analytics/events';
import { makeIndexedInsightsHubSearchRequest } from '../services/typesense/insightsHub';
import { dateFormat } from '../utils/functions/date';

export const HTMLRenderer = styled(Box)(() => ({}));

const SearchResultItem = ({
  highlighTitle,
  title,
  body,
  bodyHighlight,
  isArticle = false,
  url,
  alternateUrl,
  category,
  researchUpdateDate,
  publishDate,
}) => {
  const redirectionLink = isArticle
    ? `/research-hub/${category}/${url}`
    : `/research-hub/research_update/${researchUpdateDate}`;
  return (
    <Link
      to={alternateUrl ?? redirectionLink}
      target={!!alternateUrl ? '_blank' : '_self'}
      style={{ textDecoration: 'none', color: 'unset' }}
    >
      <Stack
        padding={2}
        width={'100%'}
        borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
        sx={{ cursor: 'pointer' }}
      >
        <Stack maxHeight={'114px'} overflow={'hidden'} gap={1}>
          <Typography level='title-md'>
            <HTMLRenderer dangerouslySetInnerHTML={{ __html: highlighTitle ?? title }} />
          </Typography>
          <Typography level='body-sm' color='neutral'>
            <HTMLRenderer dangerouslySetInnerHTML={{ __html: bodyHighlight ?? body }} />
          </Typography>
          {isArticle ? (
            <Typography level='body-xs' color='primary' fontWeight={'bold'}>
              {category} - {dateFormat(publishDate).monthddyyyy}
            </Typography>
          ) : null}
        </Stack>
      </Stack>
    </Link>
  );
};

export default function InsightsHubSearch() {
  const searchContainerRef = useRef<HTMLDivElement>(null);
  const searchDebounceTimerRef = useRef<any>(null);
  const [openResultsDrawer, setOpenResultsDrawer] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [results, setResults] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.replace(/[^a-zA-Z0-9 ]/g, ''));
    setOpenResultsDrawer(true);
  };

  const handleMakeSearchQuery = async () => {
    const resultsList = await makeIndexedInsightsHubSearchRequest({ q: searchQuery });
    setResults(resultsList);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    clearTimeout(searchDebounceTimerRef.current);
    searchDebounceTimerRef.current = setTimeout(() => {
      handleMakeSearchQuery();
    }, 300);
    searchDebounceTimerRef.current = setTimeout(() => {
      track(EVNT_research_hub_searched, EVENT_INSIGHTS_HUB_PAGE, { search_term: searchQuery });
    }, 800);
  }, [searchQuery]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchContainerRef.current && !searchContainerRef.current?.contains(event.target)) {
        setOpenResultsDrawer(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.addEventListener('touchstart', handleClickOutside);
    };
  }, []);

  return (
    <Box position={'relative'} ref={searchContainerRef}>
      <Input
        endDecorator={<IoSearch />}
        size='lg'
        color='neutral'
        placeholder='Search our research & insights'
        sx={{ width: { xs: '100%', lg: '100%' }, zIndex: 99 }}
        onChange={handleSearch}
        value={searchQuery}
      />
      {openResultsDrawer && searchQuery?.length > 0 ? (
        <Stack
          position={'absolute'}
          top={'38px'}
          width={'100%'}
          height={'400px'}
          zIndex={9}
          sx={(theme) => {
            return {
              background: theme.palette.background.surface,
              overflowY: 'auto',
              overflowX: 'hidden',
            };
          }}
          borderRadius={'md'}
          boxShadow={'lg'}
        >
          {isLoading ? (
            Array(10)
              .fill('')
              .map((_, i) => {
                return (
                  <Stack
                    key={i}
                    px={2}
                    py={3}
                    width={'100%'}
                    borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
                    sx={{ cursor: 'pointer' }}
                    gap={2}
                  >
                    <Skeleton
                      width={'100%'}
                      animation='wave'
                      height={'24px'}
                      variant='rectangular'
                    />
                    <Stack gap={1}>
                      {Array(2)
                        .fill('')
                        .map((_, i) => {
                          return (
                            <Skeleton
                              key={i}
                              width={'100%'}
                              animation='wave'
                              height={'16px'}
                              variant='rectangular'
                            />
                          );
                        })}
                      <Skeleton
                        width={'60%'}
                        animation='wave'
                        height={'16px'}
                        variant='rectangular'
                      />
                    </Stack>
                  </Stack>
                );
              })
          ) : results?.length > 0 ? (
            results?.map((result) => {
              const isArticle = result?.document?.type === 'article';
              const bodyHighlight =
                result?.highlight?.content?.snippet ||
                result?.highlight?.summary?.snippet ||
                result?.highlight?.preview?.snippet;
              return (
                <SearchResultItem
                  key={result?.document?.id}
                  highlighTitle={
                    isArticle
                      ? result?.highlight?.['title']?.snippet
                      : `Research Update - ${result?.document?.date}`
                  }
                  title={result?.document?.title}
                  body={result?.document?.[isArticle ? 'content' : 'summary']}
                  bodyHighlight={bodyHighlight}
                  url={result?.document?.url}
                  alternateUrl={result?.document?.content_detail_alternate_url}
                  category={result?.document?.category}
                  researchUpdateDate={result?.document?.date}
                  isArticle={isArticle}
                  publishDate={result?.document?.published_date}
                />
              );
            })
          ) : (
            <Box
              width={'100%'}
              height={'100%'}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'center'}
              gap={1}
              padding={4}
            >
              <IoSearch size={56} />
              <Typography level='title-md' color='neutral' textAlign={'center'}>
                No results found matching your query "{searchQuery}"
              </Typography>
            </Box>
          )}
        </Stack>
      ) : null}
    </Box>
  );
}

import { Card, Chip, Divider, Grid, Skeleton, Stack, Typography } from '@mui/joy';
import { useRecoilValue } from 'recoil';

import { StyledHTML } from '../../../../components/styled/StyledHTML';
import { projectState } from '../../../../stores/project';
import { NormaliseCSSWrapper } from '../../ghg_rating/components/HTMLRenderer';

export const ProjectInfo = () => {
  const { projectData, isLoading } = useRecoilValue(projectState);

  if (isLoading)
    return (
      <Card sx={{ position: 'relative', minHeight: '400px', p: 2 }}>
        <Skeleton width={'95%'} height={'90%'} />
      </Card>
    );

  return (
    <Card sx={{ p: (theme) => theme.spacing(3) }}>
      <Typography fontSize={'xl'} fontWeight={'xl'} color='primary'>
        Project details
      </Typography>
      <NormaliseCSSWrapper>
        <StyledHTML
          dangerouslySetInnerHTML={{
            __html: projectData?.description || '',
          }}
        />
      </NormaliseCSSWrapper>
      <Divider sx={{ width: '100%', mx: 'auto', my: (theme) => theme.spacing(2) }} />
      <Grid container columnSpacing={2}>
        <Grid xs={4} sx={{ mb: 2 }}>
          <Stack gap={1}>
            <Typography level='body-sm' sx={{ color: (theme) => theme.palette.text.secondary }}>
              Project type
            </Typography>
            <Typography level='body-md' color='primary' sx={{ pr: 3 }}>
              {projectData?.projectType}
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={4} sx={{ mb: 2 }}>
          <Stack gap={1}>
            <Typography level='body-sm' sx={{ color: (theme) => theme.palette.text.secondary }}>
              Carbon crediting program
            </Typography>
            <Typography level='body-md' color='primary' sx={{ pr: 3 }}>
              {projectData?.ghgStandardFullform || projectData?.ghgStandardCode || '-'}
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={4} sx={{ mb: 2 }}>
          <Stack gap={1}>
            <Typography level='body-sm' sx={{ color: (theme) => theme.palette.text.secondary }}>
              Methodology
            </Typography>
            <Typography level='body-md' color='primary' sx={{ pr: 3 }}>
              {projectData?.methodology}
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={4} sx={{ mb: 2 }}>
          <Stack gap={1}>
            <Typography level='body-sm' sx={{ color: (theme) => theme.palette.text.secondary }}>
              Certificates
            </Typography>
            <Typography level='body-md' color='primary' sx={{ pr: 3 }}>
              <Chip variant='outlined'>{projectData?.certificate || '-'}</Chip>
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={4}>
          <Stack gap={1}>
            <Typography level='body-sm' sx={{ color: (theme) => theme.palette.text.secondary }}>
              Project duration
            </Typography>
            <Typography level='body-md' color='primary' sx={{ pr: 3 }}>
              {projectData?.duration}
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={4}>
          <Stack gap={1}>
            <Typography level='body-sm' sx={{ color: (theme) => theme.palette.text.secondary }}>
              Proponent and Partners
            </Typography>
            <Typography level='body-md' color='primary' sx={{ pr: 3 }}>
              {projectData?.proponents}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};

import { useMemo } from 'react';

import { Box, Button, Card, Stack, Typography } from '@mui/joy';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import ArticleThumb from '../../../components/ArticleThumb';
import { Article } from '../../../types/strapi';
import {
  insightsHubCategoriesConfig,
  PINNED_BLOG_POST_DISPLAY_WHITELIST,
  pinnedPostObj,
} from '../constants';

interface Props {
  allArticles: Array<Article>;
  category: string;
  filterCombination?: Array<string>;
  isLoading?: boolean;
  urlKey: string;
}

export default function ArticleRow({
  allArticles,
  category,
  filterCombination,
  isLoading = false,
  urlKey,
}: Props) {
  const displayPinnedPost = useMemo(
    () => PINNED_BLOG_POST_DISPLAY_WHITELIST.includes(urlKey),
    [urlKey]
  );
  const articlesToRender = useMemo(() => {
    return allArticles
      ?.filter((article) => {
        return (
          article?.post_category?.url_slug === category ||
          (filterCombination && filterCombination.includes(article?.post_category?.url_slug)) ||
          category === 'all'
        );
      })
      ?.slice(0, displayPinnedPost ? 2 : 3);
  }, [allArticles, category, filterCombination]);

  const isResearch = useMemo(() => category === 'Research', [category]);

  const categoryLabel = useMemo(
    () => insightsHubCategoriesConfig?.find(({ key }) => key === category)?.label,
    [category, insightsHubCategoriesConfig]
  );

  return articlesToRender?.length > 0 || isLoading ? (
    <Box sx={{ paddingY: { lg: isResearch ? 2 : 0 } }}>
      <Card>
        <Stack direction='row' justifyContent={'space-between'} alignItems={'center'}>
          <Typography level='h4'>{categoryLabel}</Typography>
          <Link to={`/research-hub/${urlKey}`}>
            <Button variant='outlined' size='sm' endDecorator={<FaArrowRight />}>
              View all
            </Button>
          </Link>
        </Stack>
        <Box display={'grid'} gap={3} gridTemplateColumns={{ xs: '1fr', lg: 'repeat(3,1fr)' }}>
          {isLoading ? (
            Array(3)
              .fill('')
              .map((_, i) => {
                return <ArticleThumb article={{} as Article} key={i} isLoading />;
              })
          ) : (
            <>
              {displayPinnedPost ? <ArticleThumb article={pinnedPostObj} isPinnedPost /> : null}
              {articlesToRender?.map((article) => {
                return <ArticleThumb article={article} key={article?.id} />;
              })}
            </>
          )}
        </Box>
      </Card>
    </Box>
  ) : null;
}

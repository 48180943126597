import { getAssetURL } from '../../services/axios/endpoints';
import { Article } from '../../types/strapi';

export const insightsHubCategoriesConfig = [
  {
    label: 'Most recent',
    key: 'all',
    alternateLabel: 'Most Recent',
    orderIndex: 0,
  },
  {
    label: 'Commentary',
    key: 'commentary',
    orderIndex: 1,
  },
  {
    label: 'Research',
    key: 'research',
    orderIndex: 2,
  },
  {
    label: 'Ratings approach',
    key: 'ratings-approach',
    orderIndex: 6,
  },
  {
    label: 'News & events',
    filterCombination: ['news', 'events'],
    key: 'news_and_events',
    orderIndex: 3,
  },
  {
    label: 'Customer stories',
    key: 'customer-stories',
    orderIndex: 5,
  },
  {
    label: 'Research updates',
    key: 'research-updates',
    hideInRow: true,
    orderIndex: 4,
  },
];

export const PINNED_BLOG_POST_DISPLAY_WHITELIST = ['all'];

export const pinnedPostObj = {
  author: {
    name: 'Calyx Global',
    title: 'This article includes insights and input from multiple experts in Calyx Global.',
  },
  post_category: { display_name: 'Calyx ratings', url_slug: 'calyx_ratings' },
  content: '',
  cover: {
    url: getAssetURL('calyx_global_rating_distribution_16Dec.jpg'),
    name: 'Pinned Blogs Post Image',
    alternativeText: 'How risky is the carbon credit market?',
  },
  published_date: new Date().toDateString(),
  title: 'Current Calyx Risk Ratings Distribution',
  url: '/research-hub/pinned/current-calyx-risk-ratings-distribution',
} as Article;

import React, { useEffect, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  Box,
  Button,
  Divider,
  Drawer,
  ModalClose,
  Stack,
  Typography,
} from '@mui/joy';
import { useSetRecoilState } from 'recoil';

import { miscState } from '../../stores/misc';
import { FilterDetails } from '../../utils/hooks/useFilters/types';

import { FilterList } from './FilterList';
import { FilterSelctedCount } from './FilterSelctedCount';
import { StyledAccordionSummary } from './styles';
import { FilterType, Section } from './types';

export type FilterSideBarProps = {
  title?: string;
  sections: Section[];
  hasReset?: boolean;
  open: boolean;
  filterDetails: FilterDetails;
  subText?: string;
  onClose?: () => void;
};

export const FilterSideBar: React.FC<FilterSideBarProps> = ({
  title,
  sections,
  open,
  hasReset,
  filterDetails,
  subText,
  onClose,
}) => {
  const setMisc = useSetRecoilState(miscState);
  const [expandedSections, setExpandedSections] = useState({});

  const {
    updateFilter,
    isSelected,
    clearFilter,
    getFilterValue,
    updateTextFilter,
    setFilters,
    getFilterCount,
    getTotalFilterCount,
    filterListRefs,
    resetFilterList,
  } = filterDetails;

  useEffect(() => {
    setMisc((prev) => ({ ...prev, filterState: filterDetails }));
  }, [filterDetails]);

  const toggleAccordion = (sectionName) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [sectionName]: !prevState[sectionName],
    }));
  };

  return (
    <Drawer
      anchor={'right'}
      open={open}
      onClose={() => {
        setExpandedSections({});
        onClose && onClose();
      }}
      slotProps={{ backdrop: { sx: { backdropFilter: 'blur(0px)' } } }}
    >
      <Box display={'flex'} flexDirection={'column'} width={'100%'} height={'100vh'}>
        <Box width={'100%'}>
          <ModalClose />
          <Typography level='h4' sx={{ px: 2, pt: 2, pb: 1 }}>
            {title}
          </Typography>
          {subText && (
            <Typography fontSize={'sm'} fontWeight={500} color='primary' sx={{ px: 2, pr: 4 }}>
              {subText}
            </Typography>
          )}
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          width={'100%'}
          flex={1}
          height={'100%'}
          overflow={'auto'}
        >
          <AccordionGroup sx={{ py: 2 }}>
            {sections.map((section) => (
              <Accordion
                key={section.name}
                expanded={expandedSections[section.name] || false}
                onChange={() => toggleAccordion(section.name)}
              >
                <StyledAccordionSummary>
                  <Stack direction={'row'} alignItems={'center'} gap={2}>
                    <Typography color='primary'>
                      {section.display_name}{' '}
                      {section.type === FilterType.CHIP_INPUT
                        ? ''
                        : `(${filterDetails.getTotalFilterCount(section.name)})`}
                    </Typography>
                    <FilterSelctedCount
                      getFilterCount={getFilterCount}
                      keyName={section.name}
                      postFix='Selected'
                    />
                  </Stack>
                </StyledAccordionSummary>
                <AccordionDetails
                  sx={{ pl: 2, background: (theme) => theme.palette.primary.outlinedColor }}
                >
                  <FilterList
                    ref={(el) => {
                      if (el) filterListRefs.current.set(section.name, { current: el });
                    }}
                    sections={section.filters}
                    parentFilter={section}
                    resetFilterList={open ? false : resetFilterList}
                    updateFilter={updateFilter}
                    clearFilter={clearFilter}
                    isSelected={isSelected}
                    getFilterValue={getFilterValue}
                    updateTextFilter={updateTextFilter}
                    getTotalFilterCount={getTotalFilterCount}
                    onChange={setFilters}
                  />
                  <Divider sx={{ mt: 2 }} />
                  <Stack direction={'row'} justifyContent={'end'} sx={{ py: 2 }}>
                    <Button
                      variant='outlined'
                      onClick={() => {
                        setTimeout(() => {
                          clearFilter(section.name);
                        }, 0);
                      }}
                    >
                      Clear
                    </Button>
                  </Stack>
                </AccordionDetails>
              </Accordion>
            ))}
          </AccordionGroup>
        </Box>
        {hasReset && (
          <Box>
            <Divider />
            <Stack direction={'row'} justifyContent={'end'} gap={2} sx={{ p: 2 }}>
              <Button
                variant='outlined'
                onClick={() => {
                  setTimeout(() => {
                    clearFilter();
                  }, 0);
                }}
                sx={{ width: '160px', height: '40px' }}
              >
                Clear all filters
              </Button>
              <Button variant='solid' onClick={onClose} sx={{ width: '160px', height: '40px' }}>
                Apply
              </Button>
            </Stack>
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

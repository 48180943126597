import { useMemo } from 'react';

import { Box, Divider, Stack, Typography, useColorScheme } from '@mui/joy';
import ReactEcharts from 'echarts-for-react';
import { useRecoilValue } from 'recoil';

import { projectState } from '../../../../stores/project';
import useIsLargeScreen from '../../../../utils/hooks/useIsLargeScreen';
import { getChartTypeCustomizations, getSeriesData, getTableData, getXAxis } from '../constants';

import { HTMLRenderer } from './HTMLRenderer';

interface PropTypes {
  chartConfigProp: any;
  chartData: any;
  isHidden: boolean;
  chartType: string;
  description: string;
  title: string;
  uniqueIdentifier: number;
}

export const Chart = ({
  chartConfigProp,
  chartData,
  isHidden = false,
  chartType,
  description,
  title,
  uniqueIdentifier,
}: PropTypes) => {
  const { mode } = useColorScheme();

  const { projectData } = useRecoilValue(projectState);

  const chartConfig = chartConfigProp?.chart_config;
  const tableConfig = chartConfig?.table_config;
  const tableData = chartData?.data || [];

  const rowData = useMemo(
    () => Object.assign(tableConfig?.rowData || [], tableData),
    [tableConfig, tableData]
  );

  const isLg = useIsLargeScreen();

  const option = useMemo(() => {
    const filterNonZeroSeries = (seriesData, dataset) => {
      return seriesData.filter((series) => {
        const key = series?.dimensions?.[1];
        if (!key || !dataset.source.some((row) => Object.prototype.hasOwnProperty.call(row, key))) {
          return false;
        }
        return dataset.source.some((row) => row[key] !== 0);
      });
    };

    const dataset = getTableData(rowData, chartType);
    const seriesData = getSeriesData(
      chartConfig,
      rowData,
      chartType,
      projectData?.projectCountry,
      projectData?.projectState
    );

    const filteredSeries = filterNonZeroSeries(seriesData, dataset);
    const visibleLegendNames = filteredSeries.map((series) => series.name).filter(Boolean);

    return {
      ...chartConfig,
      backgroundColor: 'transparent',
      xAxis: getXAxis(chartConfig, rowData, chartType),
      dataset,
      series: filteredSeries,
      legend: {
        ...chartConfig?.legend,
        data: visibleLegendNames,
      },
      ...getChartTypeCustomizations(chartType, isLg, chartConfig),
    };
  }, [chartConfig, rowData, projectData, chartType, isLg]);

  if (isHidden) return null;
  return (
    <Stack>
      <Typography level='h4' mt={2}>
        {title}
      </Typography>
      <Box display={'grid'} gridTemplateColumns={isLg ? '4fr 0fr 2fr' : '1fr'} gap={isLg ? 6 : 2}>
        <ReactEcharts
          option={option}
          style={{ height: '360px' }}
          theme={mode}
          key={uniqueIdentifier}
        />
        <Divider orientation='vertical' />
        <Typography level='body-md' color='neutral'>
          <HTMLRenderer colorType='secondary' dangerouslySetInnerHTML={{ __html: description }} />
        </Typography>
      </Box>
    </Stack>
  );
};

import { useEffect, useMemo, useRef, useState } from 'react';

import {
  Box,
  Button,
  Card,
  Chip,
  ChipDelete,
  Dropdown,
  ListDivider,
  Menu,
  MenuButton,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Tooltip,
  Typography,
} from '@mui/joy';
import { MutationFunction, useMutation, useQuery } from '@tanstack/react-query';
import { FaChevronDown, FaChevronUp, FaLock } from 'react-icons/fa';
import { IoSearchSharp } from 'react-icons/io5';
import { RiFilter3Fill } from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router-dom';

import { FilterPopUp } from '../../../../components/filter/FilterPopUp';
import MessagePage from '../../../../components/MessagePage';
import Table from '../../../../components/table';
import { track } from '../../../../services/analytics';
import {
  EVENT_RETIREMENT_INSIGHTS_COMPANY_DRILL_DOWN_PAGE,
  EVNT_retirement_company_widget_filters_applied,
} from '../../../../services/analytics/events';
import {
  makeAuthenticatedGetRequest,
  makeAuthenticatedPostRequest,
} from '../../../../services/axios';
import { filterMetadata, projectRetireeTable } from '../../../../services/axios/endpoints';
import { formatNumberWithCommas } from '../../../../utils/functions/number';
import { useFetchUserPermissions } from '../../../../utils/hooks/useFetchUserPermission';
import { FilterDetails } from '../../../../utils/hooks/useFilters/types';
import { PaginationState } from '../../../../utils/hooks/usePagination/types';

import { columns, cellRenderOrder, emptyContent } from './constants';

export default function RetiredProjectsTable({
  filterDetails,
  paginationState,
}: {
  filterDetails: FilterDetails;
  paginationState: PaginationState;
}) {
  const { hasPermissionForRetirementInsights } = useFetchUserPermissions();
  const [data, setData] = useState<any>([]);
  const { retiree } = useParams();
  const navigate = useNavigate();

  const [openFilters, setOpenFilters] = useState(false);
  const [isDropdownOpen, handleIsDropdownOpen] = useState(false);
  const [disabledProjects, setDisabledProjects] = useState(new Set());
  const abortControllerRef = useRef<AbortController | null>(null);
  const isFilterApplied = useMemo(
    () => filterDetails.appliedFilters.length > 0,
    [filterDetails.appliedFilters]
  );
  const projectRetireeCall: MutationFunction<unknown> = async (filter: any) => {
    if (!hasPermissionForRetirementInsights) return;
    try {
      abortControllerRef.current = new AbortController();
      const { data } = await makeAuthenticatedPostRequest<unknown>(projectRetireeTable, filter, {
        params: { retiree_name: retiree },
        signal: abortControllerRef.current.signal,
      });
      return data;
    } catch (error: any) {
      throw new Error(error?.response?.data?.message || error.message);
    }
  };

  const { data: filterData } = useQuery({
    queryKey: [`ritiree-filter-meta`],
    queryFn: async () =>
      await makeAuthenticatedGetRequest(filterMetadata, {
        params: { type: 'drilldown' },
      }),
    select: (data) => data?.data?.data?.metaData,
    enabled: hasPermissionForRetirementInsights,
  });

  const { mutate: getRetiree, isPending } = useMutation<unknown, Error>({
    mutationKey: ['get-project-retiree', filterDetails.filters],
    mutationFn: projectRetireeCall,
    onSuccess: (res: any) => {
      const { setTotalCount, setTotalPages, setPage } = paginationState;
      setTotalCount(res?.data?.total_count || 0);
      setTotalPages(res?.data?.total_pages || 0);
      setPage(res?.data?.current_page || 1);
      setData(res?.data?.retireeTableData || []);
    },
  });

  useEffect(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    getRetiree(filterDetails.filters as any);
  }, [filterDetails.filters]);

  useEffect(() => {
    if (filterData) {
      filterDetails.updateFilterMap(filterData);
    }
  }, [filterData]);

  const handleRowClick = (row) => {
    if (!hasAccess(row)) return;
    const isDisabledRow = isRowDisabled(row);
    if (isDisabledRow) {
      setDisabledProjects(() => {
        const set = new Set();
        set.add(row.project_id);
        return set;
      });
    } else {
      navigate(
        `/executive_summary?standard=${row?.ghg_standard}&id=${row?.project_id}&cp_no=${row?.crediting_period_id}`
      );
    }
  };

  const isRowDisabled = (row) => {
    const isCalyxRated = !!row?.is_calyx_rated;
    return !isCalyxRated;
  };

  const hasAccess = (row) => {
    return row.has_access;
  };

  useEffect(() => {
    const filter_project_type_values = filterDetails?.filters?.project_type;
    const filter_country_values = filterDetails?.filters?.country;
    const filter_ghg_rating_values = filterDetails?.filters?.ghg_rating;
    const filter_sdg_rating_values = filterDetails?.filters?.sdg_rating;
    const filter_attributes_values = filterDetails?.filters?.project_attribute;
    const filter_confirmed_sdg_values = filterDetails?.filters?.confirmed_sdg;
    const filter_sdg_cert_values = filterDetails?.filters?.sdg_certificate;
    const filter_duration_values = filterDetails?.filters?.duration_vintage;
    if (isFilterApplied) {
      track(
        EVNT_retirement_company_widget_filters_applied,
        EVENT_RETIREMENT_INSIGHTS_COMPANY_DRILL_DOWN_PAGE,
        {
          filter_attributes_values,
          filter_confirmed_sdg_values,
          filter_country_values,
          filter_ghg_rating_values,
          filter_project_type_values,
          filter_sdg_cert_values,
          filter_sdg_rating_values,
          filter_duration_values,
        }
      );
    }
  }, [filterDetails]);

  return (
    <Card>
      <Modal
        open={openFilters}
        onClose={() => setOpenFilters(false)}
        slotProps={{ backdrop: { sx: { backdropFilter: 'blur(0px)' } } }}
      >
        <ModalDialog>
          <ModalClose />
          <Typography fontWeight={'xl'} fontSize={'xl2'} color='primary'>
            Retired Projects Filters
          </Typography>
          <Typography fontSize={'sm'} fontWeight={500} color='primary'>
            Filtering excludes information from projects not rated by Calyx.
          </Typography>
          <FilterPopUp
            sections={(filterData as any) || []}
            filterDetails={filterDetails}
            onClose={() => setOpenFilters(false)}
            hasReset
          />
        </ModalDialog>
      </Modal>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} mb={2}>
        <Stack gap={3} direction={'row'} alignItems={'center'}>
          <Typography fontWeight={'xl'} fontSize={'xl'} color='primary'>
            Retired Projects
          </Typography>
        </Stack>
        <Stack direction={'row'} gap={2}>
          {filterDetails.allAppliedFilterCount > 0 && (
            <Dropdown
              open={isDropdownOpen}
              onOpenChange={(_, isOpen: boolean) => {
                handleIsDropdownOpen(isOpen);
              }}
            >
              <MenuButton
                disabled={!filterDetails.appliedFilters.length}
                endDecorator={isDropdownOpen ? <FaChevronUp /> : <FaChevronDown />}
              >
                {`${filterDetails.appliedFilters.length} filter${filterDetails.appliedFilters.length !== 1 ? 's' : ''} applied`}
              </MenuButton>
              <Menu
                placement='bottom-end'
                sx={{ width: '300px', p: 1 }}
                onMouseLeave={() => handleIsDropdownOpen(false)}
              >
                <Stack
                  direction={'row'}
                  gap={1}
                  sx={{ p: 1, pb: 2, flexWrap: 'wrap', maxHeight: '160px', overflowY: 'auto' }}
                >
                  {filterDetails.appliedFilters.map(({ keyName, valueName, key }) => (
                    <Chip
                      endDecorator={
                        <ChipDelete onDelete={() => filterDetails.removeAppliedFilter(key)} />
                      }
                      sx={{ maxWidth: '100%' }}
                    >
                      {keyName}: {valueName}
                    </Chip>
                  ))}
                </Stack>
                <ListDivider sx={{ background: (theme) => theme.palette['border']['border'] }} />
                <Button
                  variant='outlined'
                  sx={{ ml: 'auto', mt: 1 }}
                  onClick={() => {
                    handleIsDropdownOpen(false);
                    filterDetails.clearFilter();
                  }}
                >
                  Clear all filters
                </Button>
              </Menu>
            </Dropdown>
          )}
          <Button onClick={() => setOpenFilters(true)} endDecorator={<RiFilter3Fill />}>
            Filter
          </Button>
        </Stack>
      </Stack>
      <Table
        scrollArea='both'
        rowKey='project_id'
        bodyStyle={{ minHeight: '150px', maxHeight: '507px' }}
        onRowClick={handleRowClick}
        isStickyHeader
        isStickyFooter
        columns={columns}
        cellRenderOrder={cellRenderOrder}
        data={data}
        isLoading={isPending}
        isRowDiabled={(row) => isRowDisabled(row) || !hasAccess(row)}
        paginationState={paginationState}
        toggleSort={filterDetails.toggleSort}
        getSortValue={filterDetails.getSortValue}
        sort={filterDetails.filters['sort'] || ({} as any)}
        onRowMouseLeave={() =>
          setDisabledProjects(() => {
            const set = new Set();
            set.delete(data.project_id);
            return set;
          })
        }
        customContent={(row, key) =>
          (row[key] || []).length === 0
            ? emptyContent[key]
            : Array.isArray(row[key])
              ? row[key].join(', ')
              : row[key]
        }
        noDataMessage={
          <MessagePage
            title='No Results Found'
            description={
              isFilterApplied
                ? 'Please adjust your filter selection.'
                : `Retirement insights are searchable by company name only. <br/>
                More granularity is available in the filter menu. <br/>
                Retirements by project can be found using the Credit ratings page.`
            }
            hasHeading={false}
            iconProps={{
              size: 56,
            }}
            headingProps={{
              level: 'h4',
            }}
            descriptionProps={{
              level: 'title-md',
              width: '480px',
              textAlign: isFilterApplied ? 'center' : 'start',
            }}
            Icon={IoSearchSharp}
            action={
              isFilterApplied
                ? undefined
                : () => {
                    navigate('/credit-ratings');
                  }
            }
            actionText={isFilterApplied ? '' : 'Go to Search'}
          />
        }
        customCell={(data, key) => {
          if (key === 'project_id') {
            return (
              <Typography
                level='body-xs'
                fontWeight={600}
                sx={{ textAlign: 'start', color: (theme) => theme.palette.text.primary }}
              >
                {data['ghg_standard']}
                {data[key]}
              </Typography>
            );
          }

          if (key === 'project_name' && isRowDisabled(data)) {
            return (
              <Tooltip
                title='There are no details to show for non-rated projects'
                variant='solid'
                placement='right'
                open={disabledProjects.has(data.project_id)}
                arrow
              >
                <Typography
                  level='body-xs'
                  fontWeight={600}
                  sx={{ textAlign: 'start', color: (theme) => theme.palette.text.primary }}
                >
                  {data[key]}
                </Typography>
              </Tooltip>
            );
          }
          if (key === 'project_name') {
            return (
              <Typography
                level='body-xs'
                fontWeight={600}
                sx={{ textAlign: 'start', color: (theme) => theme.palette.text.primary }}
              >
                {data[key]}
              </Typography>
            );
          }
          if (!hasAccess(data))
            return (
              <Box sx={{ backdropFilter: 'blur(10px)' }}>
                <Typography
                  level='body-xs'
                  fontWeight={600}
                  sx={{
                    textAlign: key === 'total_volume' ? 'end' : 'start',
                    color: (theme) => theme.palette.text.primary,
                  }}
                >
                  <FaLock />
                </Typography>
              </Box>
            );
          if (key === 'total_volume') {
            return (
              <Typography
                level='body-xs'
                fontWeight={600}
                sx={{ textAlign: 'end', color: (theme) => theme.palette.text.primary }}
              >
                {formatNumberWithCommas(Number(data[key]))}
              </Typography>
            );
          }
          return null;
        }}
      />
    </Card>
  );
}

import { useState } from 'react';

import { Box, Grid, Input, Stack, Typography } from '@mui/joy';
import { IoSearch } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { track } from '../../services/analytics';
import {
  EVENT_DASHBOARD_PAGE,
  EVNTAPP_search_click,
  EVNTAPP_search_enter,
} from '../../services/analytics/events';
import { userState } from '../../stores/user';

import { LatestBlogs } from './components/LatestBlogs';
import { RatingsDistribution } from './components/RatingDistribution';
import { ViewRatings } from './components/ViewRatings';

export const Home = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState<string>('');
  const userInfo = useRecoilValue(userState);

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value.trim());
  };

  const handleSearch = () => {
    navigate(`/credit-ratings?search_text=${searchText}`);
  };

  return (
    <Box maxWidth={'1300px'} marginX={'auto'}>
      <Typography margin={'auto'} level='h3' width={'max-content'} marginY={6}>
        Welcome back, {userInfo?.name}
      </Typography>
      <Input
        endDecorator={
          <IoSearch
            onClick={() => {
              track(EVNTAPP_search_click, EVENT_DASHBOARD_PAGE);
              handleSearch();
            }}
          />
        }
        size='lg'
        color='neutral'
        placeholder='Search Calyx Global ratings'
        sx={{ width: '70%', margin: 'auto' }}
        onChange={handleSearchInputChange}
        onKeyUp={(e) => {
          if (e.code === 'Enter') {
            track(EVNTAPP_search_enter, EVENT_DASHBOARD_PAGE);
            handleSearch();
          }
        }}
      />
      <Grid container spacing={3} marginTop={5} paddingX={5} paddingBottom={12}>
        <Grid xs={12} lg={8}>
          <Stack direction={'column'} spacing={4}>
            <ViewRatings />
            <RatingsDistribution />
          </Stack>
        </Grid>
        <Grid xs={12} lg={4}>
          <LatestBlogs />
        </Grid>
      </Grid>
    </Box>
  );
};

import { memo, useMemo } from 'react';

import { Box, Skeleton, Stack, useColorScheme } from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import ReactEcharts from 'echarts-for-react';
import { useNavigate } from 'react-router-dom';

import { makeAuthenticatedGetRequest } from '../../../services/axios';
import { dashboardGraph, searchFilters } from '../../../services/axios/endpoints';
import { addBarBorderRadius } from '../../../utils/functions/graph';
import { getProjectGroupDetails, getProjectTypeColor } from '../constants';

interface PropTypes {
  type: 'ghg' | 'sdg';
}

const DistributionGraph = memo(({ type }: PropTypes) => {
  const { mode } = useColorScheme();
  const navigate = useNavigate();
  const { data, isLoading } = useQuery({
    queryKey: [`${type}-graph`],
    queryFn: async () => makeAuthenticatedGetRequest(dashboardGraph, { params: { type } }),
    select: (data) => data?.data?.graph,
  });

  const { data: filterData } = useQuery({
    queryKey: [`projects-filter-meta`],
    queryFn: async () => await makeAuthenticatedGetRequest(searchFilters, {}),
    select: (data) => data?.data?.data,
  });

  const { groups } = useMemo(() => getProjectGroupDetails(data), [data]);

  const option = useMemo(() => {
    return addBarBorderRadius({
      backgroundColor: 'transparent',
      legend: { top: 'bottom', icon: 'circle' },
      tooltip: {
        trigger: 'axis',
        show: true,
        axisPointer: {
          type: 'shadow',
        },
      },
      grid: {
        bottom: window.innerWidth < 500 ? '120px' : '80px',
      },
      xAxis: {
        data: data?.map(({ grade }: any) => grade),
      },
      yAxis: {
        name: 'Number of Ratings',
        nameLocation: 'middle',
        nameGap: 50,
      },
      series: data?.[0]?.data
        ?.sort((a: any, b: any) => {
          return groups.indexOf(a?.key) - groups.indexOf(b?.key);
        })
        ?.map(({ name, key }: any) => {
          return {
            data: data?.map(({ data: itemData }: any) => {
              return itemData?.find(({ name: itemName }: any) => name === itemName)?.total_project;
            }),
            type: 'bar',
            stack: 'x',
            name,
            barWidth: 18,
            color: getProjectTypeColor(key, mode === 'dark'),
          };
        }),
    });
  }, [data, mode]);

  const onChartClick = (params) => {
    const projectTypes = filterData
      ?.find((filter) => filter?.name === 'project_type')
      ?.filters?.find((filter) => filter?.display_name === params?.seriesName)
      ?.filters?.map((filter) => filter?.name)
      ?.map((item) => `"${item}"`)
      ?.join(',');
    navigate(
      `/credit-ratings?${type === 'ghg' ? 'ghg_rating' : 'sdg_impact_rating'}=${encodeURIComponent(`["${params?.name}"]`)}&project_type=${encodeURIComponent(`[${projectTypes}]`)}`
    );
  };

  const onEvents = {
    click: onChartClick,
  };

  return (
    <Box height={'360px'}>
      {isLoading ? (
        <Stack
          padding={[2, 6]}
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'flex-end'}
        >
          {[4, 6, 10, 4, 3, 6, 5, 8].map((height) => {
            return <Skeleton height={`${28 * height}px`} width={'36px'} variant='rectangular' />;
          })}
        </Stack>
      ) : (
        <ReactEcharts
          option={option}
          style={{ height: '360px' }}
          theme={mode}
          onEvents={onEvents}
        />
      )}
    </Box>
  );
});

export { DistributionGraph };

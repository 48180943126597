import { Card, Grid, Skeleton, Typography } from '@mui/joy';
import { Link } from 'react-router-dom';

import Image from '../../../components/Image';
import { Article } from '../../../types/strapi';
import { dateFormat } from '../../../utils/functions/date';

interface Props {
  data: Article;
  showVertical?: boolean;
  isLoading?: boolean;
  showSummary?: boolean;
}
export default function FeaturedArticle({
  data,
  showVertical = true,
  isLoading = false,
  showSummary = false,
}: Props) {
  return (
    <Link
      to={
        isLoading
          ? ''
          : data?.content_detail_alternate_url ??
            `/research-hub/${data?.post_category?.url_slug}/${data?.url}`
      }
      target={data?.content_detail_alternate_url ? '_blank' : '_self'}
      style={{ textDecoration: 'none', color: 'unset' }}
    >
      <Card sx={{ padding: 0, height: '100%' }}>
        <Grid
          container
          width={'100%'}
          height={'100%'}
          borderRadius={'md'}
          overflow={'hidden'}
          gap={0}
        >
          <Grid xs={12} lg={showVertical ? 12 : 5} minHeight={'350px'}>
            {isLoading ? (
              <Skeleton width={'100%'} height={'100%'} variant='rectangular' animation='wave' />
            ) : (
              <Image
                src={data?.cover?.url}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            )}
          </Grid>
          <Grid
            py={{ xs: 3, lg: 1.5 }}
            px={3}
            xs={12}
            lg={showVertical ? 12 : 7}
            sx={(theme) => {
              return {
                background: theme.palette.background.surface,
              };
            }}
          >
            {isLoading ? (
              <Skeleton variant='rectangular' width={'100%'} height={'32px'} animation='wave' />
            ) : (
              <Typography level='h1'>{data?.title}</Typography>
            )}
            {showSummary ? (
              isLoading ? (
                <Skeleton
                  variant='rectangular'
                  width={'100%'}
                  height={'32px'}
                  animation='wave'
                  sx={{ my: 1 }}
                />
              ) : (
                <Typography color='primary' fontSize={'22px'} my={1}>
                  <div dangerouslySetInnerHTML={{ __html: data?.featured_summary }} />
                </Typography>
              )
            ) : null}
            {isLoading ? (
              <Skeleton
                variant='rectangular'
                width={'40%'}
                height={'24px'}
                animation='wave'
                sx={{ marginTop: 2 }}
              />
            ) : (
              <Typography
                level='title-sm'
                sx={(theme) => {
                  return { color: theme.palette.text.secondary };
                }}
                mt={1}
              >
                {dateFormat(data?.published_date).monthddyyyy}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Card>
    </Link>
  );
}

import { useEffect, useMemo, useRef } from 'react';

import { Box, Card, Chip, Skeleton, Stack, Typography } from '@mui/joy';
import { _ } from 'ag-grid-enterprise';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import ArticleContent from '../../components/ArticleContent';
import Image from '../../components/Image';
import MessagePage from '../../components/MessagePage';
import { track } from '../../services/analytics';
import {
  EVENT_INSIGHTS_HUB_PAGE,
  PGV_research_hub_content_detail,
} from '../../services/analytics/events';
import { getAssetURL } from '../../services/axios/endpoints';
import useFetchAllResearchUpdates from '../../services/strapi/useFetchAllResearchUpdates';
import useFetchResearchUpdate from '../../services/strapi/useFetchResearchUpdate';
import { dateFormat } from '../../utils/functions/date';

import NextUpResearchUpdates from './components/NextUpResearchUpdates';

export const InsightsHubResearchUpdate = () => {
  const { slug } = useParams();
  const { hideLayoutNavigation } = useOutletContext<{
    hideLayoutNavigation: () => void;
  }>();
  const navigate = useNavigate();
  const topRef = useRef<HTMLDivElement>(null);

  const { data: allUpdates = [], isLoading: isAllUpdatesLoading } = useFetchAllResearchUpdates();

  const { data, isLoading } = useFetchResearchUpdate({
    date: `${slug}`,
  });

  const nextUpUpdates = useMemo(() => {
    return (allUpdates as any[])?.filter(({ date }) => date !== slug)?.slice(0, 3) ?? [];
  }, [data, allUpdates]);

  useEffect(() => {
    topRef.current?.scrollIntoView({ behavior: 'instant', block: 'start', inline: 'start' });
  }, [slug]);

  useEffect(() => {
    if (data) {
      track(PGV_research_hub_content_detail, EVENT_INSIGHTS_HUB_PAGE, {
        title: dateFormat(new Date(data?.date).toDateString()).monthddyyyy,
        category: 'Research update',
      });
    }
  }, [data]);

  const isResearchUpdateDataNotFound = useMemo(() => {
    const isNotFound = !isLoading && !data?.date;
    if (isNotFound) hideLayoutNavigation();
    return isNotFound;
  }, [hideLayoutNavigation, isLoading, data]);

  const handleGoToResearchHub = () => {
    navigate('/research-hub');
  };

  return isResearchUpdateDataNotFound ? (
    <Box height={'600px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
      <MessagePage
        title='Page not found'
        description='The page you are looking for doesn’t exist or has been moved.'
        hasAction
        actionText='Go to research hub'
        hasHeading={false}
        action={handleGoToResearchHub}
      />
    </Box>
  ) : (
    <Stack
      direction='column'
      spacing={3}
      width={'100%'}
      maxWidth={'1117px'}
      margin={'auto'}
      ref={topRef}
      sx={{ scrollMarginTop: '200px' }}
    >
      <Card>
        <Stack direction={'column'} spacing={3}>
          <Stack direction={'column'} spacing={1}>
            {isLoading ? (
              <Skeleton variant='rectangular' width={'100%'} height={'36px'} />
            ) : (
              <Typography fontSize={'xl3'} fontWeight={500} color='primary' lineHeight={'133%'}>
                {dateFormat(new Date(data?.date).toDateString()).monthddyyyy}
              </Typography>
            )}
            {isLoading ? (
              <Skeleton
                variant='rectangular'
                width={'150px'}
                height={'36px'}
                sx={{ borderRadius: '50px' }}
              />
            ) : (
              <Chip variant='outlined' color='primary' size='md'>
                Research update
              </Chip>
            )}
          </Stack>
          {isLoading ? (
            <Stack direction={'column'} width={'100%'} spacing={3}>
              {Array(5)
                .fill('')
                .map((_, i) => {
                  return (
                    <Stack direction={'column'} spacing={1}>
                      <Skeleton key={i} variant='rectangular' width={'70%'} height={'24px'} />
                      <Stack direction={'column'} gap={0.5}>
                        {Array(5)
                          .fill('')
                          .map((_, i) => {
                            return (
                              <Skeleton
                                key={i}
                                variant='rectangular'
                                width={'100%'}
                                height={'16px'}
                              />
                            );
                          })}
                      </Stack>
                    </Stack>
                  );
                })}
            </Stack>
          ) : (
            <ArticleContent>
              <div dangerouslySetInnerHTML={{ __html: data?.summary }} />
            </ArticleContent>
          )}
          <Card variant='soft'>
            <Stack direction={'row'} spacing={3}>
              <Box width={'50px'}>
                <Image
                  src={getAssetURL('calyx.png')}
                  style={{ width: '50px', aspectRatio: '1/1', borderRadius: '100%' }}
                />
              </Box>
              <Stack direction={'column'}>
                <Typography
                  sx={(theme) => {
                    return { color: theme.palette.text.tertiary };
                  }}
                  level='title-sm'
                >
                  About the author
                </Typography>
                <Typography level='h4'>{data?.author?.name}</Typography>
                <Typography
                  sx={(theme) => {
                    return { color: theme.palette.text.secondary };
                  }}
                  level='body-sm'
                >
                  {data?.author?.title}
                </Typography>
              </Stack>
            </Stack>
          </Card>
        </Stack>
      </Card>
      <NextUpResearchUpdates updates={nextUpUpdates} isLoading={isAllUpdatesLoading || isLoading} />
    </Stack>
  );
};

import { useEffect, useMemo, useState } from 'react';

import { Box, Card, CircularProgress, Typography, useColorScheme } from '@mui/joy';
import ReactEcharts from 'echarts-for-react';

import { addBarBorderRadius } from '../../../utils/functions/graph';
import { getProjectTypeColor } from '../../home/constants';
import { ghgRatingsArray, sdgRatingsArray } from '../constants';

interface PropTypes {
  type: 'ghg' | 'sdg';
  data: any[];
  isLoading: boolean;
}

const DistributionGraphV2 = ({ type, data = [], isLoading }: PropTypes) => {
  const { mode } = useColorScheme();
  const [showChart, setShowChart] = useState<boolean>(true);

  const option = useMemo(() => {
    const sortedData = data?.sort((a, b) => {
      const key = type === 'ghg' ? 'ghg_rating' : 'sdg_rating';
      const reArray = type === 'ghg' ? ghgRatingsArray : sdgRatingsArray;
      return reArray.indexOf(a[key]) - reArray.indexOf(b[key]);
    });

    const projectTypesSet = new Set();
    const projectTypeKeysSet = new Set();

    sortedData.forEach((item) =>
      item.project_categories.forEach((pt) => {
        projectTypesSet.add(pt.project_category);
        projectTypeKeysSet.add(pt.project_category_key);
      })
    );

    const projectTypes = Array.from(projectTypesSet ?? {}) as any[];
    const projectTypesKeys = Array.from(projectTypeKeysSet ?? {}) as any[];
    const series = projectTypes
      ?.sort((a, b) => a?.localeCompare(b))
      .map((projectType, i) => ({
        name: projectType,
        type: 'bar',
        stack: 'total',
        emphasis: {
          focus: 'series',
        },
        data: sortedData.map((item) => {
          const found = item.project_categories.find((pt) => pt.project_category === projectType);
          return found ? found.count : 0;
        }),
        color: getProjectTypeColor(
          projectTypesKeys?.sort((a, b) => a?.localeCompare(b))[i],
          mode === 'dark'
        ),
        barWidth: 18,
      }));
    return addBarBorderRadius({
      backgroundColor: 'transparent',
      legend: {
        bottom: 0,
        icon: 'circle',
        textStyle: {
          fontFamily: "'Inter Variable', sans-serif",
          fontSize: '11px',
        },
        itemGap: 15,
      },
      grid: {
        bottom: 80,
      },
      tooltip: {
        trigger: 'axis',
        show: true,
        axisPointer: {
          type: 'shadow',
        },
      },
      xAxis: {
        data: sortedData.map((item) => item[type === 'ghg' ? 'ghg_rating' : 'sdg_rating']),
        axisLabel: {
          textStyle: {
            fontFamily: "'Inter Variable', sans-serif",
            fontSize: '12px',
          },
        },
      },
      yAxis: {
        name: '# Ratings',
        nameLocation: 'middle',
        nameGap: 50,
        axisLabel: {
          textStyle: {
            fontFamily: "'Inter Variable', sans-serif",
            fontSize: '12px',
          },
        },
      },
      series,
    });
  }, [data, ghgRatingsArray, sdgRatingsArray, mode]);

  useEffect(() => {
    let timerId: NodeJS.Timeout | string | number | undefined = undefined;
    setShowChart(false);
    timerId = setTimeout(() => {
      setShowChart(true);
    }, 500);
    return () => clearTimeout(timerId);
  }, [option]);

  return (
    <Card>
      <Typography fontWeight={600} fontSize={'lg'}>
        {type?.toUpperCase()} Rating Distribution
      </Typography>
      {!isLoading && showChart ? (
        <ReactEcharts option={option} style={{ height: '400px' }} theme={mode} />
      ) : (
        <Box height={'400px'} width={'100%'} position={'relative'}>
          <CircularProgress
            color='primary'
            sx={{
              backgroundColor: 'transparent',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}
            size='lg'
          />
        </Box>
      )}
    </Card>
  );
};

export { DistributionGraphV2 };

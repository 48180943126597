import { useEffect } from 'react';

import { useAuthInfo, useRedirectFunctions } from '@propelauth/react';
import { useSetRecoilState } from 'recoil';

import { authState } from '../stores/auth';

const AuthContext = ({ children }) => {
  const { loading, isLoggedIn, accessToken } = useAuthInfo();
  const setAuthState = useSetRecoilState(authState);
  const { redirectToLoginPage } = useRedirectFunctions();

  useEffect(() => {
    setAuthState({ accessToken: accessToken || '' });
  }, [accessToken, setAuthState]);

  if (loading) {
    return null;
  }

  // TODO - Figure out a better way for this
  if (
    !isLoggedIn &&
    window.location.pathname !== '/signup' &&
    window.location.pathname !== '/account-signup' &&
    window.location.pathname !== '/account-request' &&
    window.location.pathname !== '/terms'
  ) {
    redirectToLoginPage({
      userSignupQueryParameters: { opt_hint: 'pw,sso' },
      postLoginRedirectUrl: window.location.href,
    });
    return null;
  }

  return <>{children}</>;
};

export default AuthContext;

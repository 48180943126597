import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import { Autocomplete, Box, IconButton, Stack, Typography, styled } from '@mui/joy';

import useUniqueDebouncedCallback from '../../utils/hooks/useUniqueDebouncedCallback';

import { AnimatedArrow, AnimatedBox, StyledCheckBox } from './styles';
import { Filter, FilterListRef, FilterObject, FilterType, Section } from './types';

export const ArrowIconHoverContainer = styled(Box)(({ theme }) => ({
  height: '20px',
  width: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: '0.1s ease',
  ':hover': {
    background: theme.palette.primary.outlinedHoverBg,
  },
}));

type FilterListProps = {
  sections: Section[];
  parentFilter: Filter;
  resetFilterList: boolean;
  updateFilter: (
    parentFilter: Filter,
    valueFilter: Filter,
    isChecked?: boolean | undefined
  ) => boolean | undefined;
  isSelected: (parent: Filter, value: Filter) => boolean;
  onChange: (filter: FilterObject) => void;
  clearFilter: (parentKey?: string | undefined) => void;
  getFilterValue: (parentKey: string) => string[] | string | null;
  updateTextFilter: (type: FilterType, key: string, value: string | string[]) => boolean;
  getTotalFilterCount: (parentKey: string) => number;
};

type FilterProps = {
  data: Filter[];
  parentFilter: Filter;
  checkedMap: Map<string, boolean>;
  isSelected: (parent: Filter, value: Filter) => boolean;
  isAnyChildFilterChecked: (parent: Filter, value?: Filter) => boolean;
  getTotalFilterCount: (parentKey: string) => number;
  updateFilter: (...args: any[]) => void;
  handleOnChange: (key: string, value: boolean) => void;
};

const Filter: React.FC<FilterProps> = ({
  data,
  parentFilter,
  checkedMap,
  updateFilter,
  isSelected,
  isAnyChildFilterChecked,
  getTotalFilterCount,
  handleOnChange,
}) => {
  const [showFilters, setShowFilters] = useState(new Set());

  const handleShowFilter = (key: string) => {
    setShowFilters((prevSet) => {
      const newSet = new Set(prevSet);
      if (newSet.has(key)) {
        newSet.delete(key);
      } else {
        newSet.add(key);
      }
      return newSet;
    });
  };

  return (
    <Stack sx={{ ml: 3, mt: 1 }}>
      {data.map((filter) => (
        <>
          <Stack
            direction={'row'}
            alignItems={'center'}
            key={filter.name}
            gap={1}
            sx={{
              background: (theme) =>
                showFilters.has(filter.display_name) && filter.filters.length > 0
                  ? theme.palette.primary.outlinedHoverBg
                  : '',
            }}
          >
            <IconButton
              variant='plain'
              onClick={() => handleShowFilter(filter.display_name)}
              sx={{ visibility: filter.filters.length > 0 ? 'visible' : 'hidden' }}
            >
              <AnimatedArrow
                isOpen={showFilters.has(filter.display_name) && filter.filters.length > 0}
              />
            </IconButton>
            <StyledCheckBox
              color='primary'
              label={`${filter.display_name} ${filter.filters.length > 0 ? `(${getTotalFilterCount(filter.name)})` : ''}`}
              indeterminate={
                filter.filters.length > 0
                  ? isAnyChildFilterChecked(parentFilter, filter) && !checkedMap.get(filter.name)
                  : false
              }
              defaultChecked={!!checkedMap.get(filter.name)}
              checked={!!checkedMap.get(filter.name)}
              onChange={(e) => {
                handleOnChange(filter.name, e.target.checked);
                updateFilter(parentFilter, filter, e.target.checked);
              }}
            />
          </Stack>
          <AnimatedBox isOpen={showFilters.has(filter.display_name) && filter.filters.length > 0}>
            <Filter
              isSelected={isSelected}
              checkedMap={checkedMap}
              data={filter.filters}
              parentFilter={parentFilter}
              updateFilter={updateFilter}
              handleOnChange={handleOnChange}
              isAnyChildFilterChecked={isAnyChildFilterChecked}
              getTotalFilterCount={getTotalFilterCount}
            />
          </AnimatedBox>
        </>
      ))}
    </Stack>
  );
};

export const FilterList = forwardRef<FilterListRef, FilterListProps>(
  (
    {
      sections,
      parentFilter,
      resetFilterList,
      updateFilter,
      isSelected,
      getFilterValue,
      updateTextFilter,
      getTotalFilterCount,
    },
    ref
  ) => {
    // const [input, handleInputChange] = useDebouncedInput(getFilterValue(parentFilter.name), (value) =>
    //   updateTextFilter(parentFilter.name, value)
    // );
    const defaultValue = useMemo(
      () => Array.from(getFilterValue(parentFilter.name) || []) as string[],
      [getFilterValue, parentFilter.name]
    );
    const type = useMemo(() => parentFilter.type, [parentFilter]);
    const [checkedMap, setCheckedMap] = useState(new Map<string, boolean>());
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [isAnySelected, setIsAnySelected] = useState(false);
    const [showFilters, setShowFilters] = useState(new Set());
    const debouncedUpdateFilter = useUniqueDebouncedCallback(updateFilter, 200);

    const handleShowFilter = (key: string) => {
      setShowFilters((prevSet) => {
        const newSet = new Set(prevSet);
        if (newSet.has(key)) {
          newSet.delete(key);
        } else {
          newSet.add(key);
        }
        return newSet;
      });
    };

    useEffect(() => {
      setCheckedMap((map) => {
        const newMap = new Map(map);
        let isAllSelected = true,
          isAnySelected = false;
        function tranverseFilters(filters) {
          filters.map((filter) => {
            if (filter.filters.length > 0) {
              tranverseFilters(filter.filters);
            }
            const selected = isSelected(parentFilter, filter);
            newMap.set(filter.name, selected);
            if (!selected) {
              isAllSelected = false;
            } else {
              isAnySelected = true;
            }
          });
        }
        newMap.set(parentFilter.name, isSelected(parentFilter, parentFilter));
        tranverseFilters(sections);
        setIsAllSelected(isAllSelected);
        setIsAnySelected(isAnySelected && !isAllSelected);
        return newMap;
      });
    }, [resetFilterList]);

    const handleOnChange = (key: string, value: boolean) => {
      setCheckedMap((prevMap) => {
        const newMap = new Map(prevMap);

        // Recursively update all children
        const updateChildren = (filters) => {
          for (const filter of filters) {
            newMap.set(filter.name, value);
            if (filter.filters.length > 0) {
              updateChildren(filter.filters);
            }
          }
        };

        const updateParents = (filters, parentName) => {
          if (!parentName) return;
          const allChecked = filters.every((filter) => newMap.get(filter.name) === true);
          newMap.set(parentName, allChecked);
        };

        const checkAnySelected = (filters) => {
          return filters.some(
            (filter) =>
              newMap.get(filter.name) === true ||
              (filter.filters.length > 0 && checkAnySelected(filter.filters))
          );
        };

        if (key === parentFilter.name) {
          newMap.set(parentFilter.name, value);
          updateChildren(sections);
        } else {
          newMap.set(key, value);

          const traverseAndUpdate = (filters, parentName) => {
            for (const filter of filters) {
              if (filter.name === key) {
                newMap.set(filter.name, value);
                updateChildren(filter.filters);
                updateParents(filters, parentName);
                return true;
              }
              if (filter.filters.length > 0) {
                if (traverseAndUpdate(filter.filters, filter.name)) {
                  updateParents(filters, parentName);
                  return true;
                }
              }
            }
            return false;
          };

          traverseAndUpdate(sections, parentFilter.name);
        }

        const isSelectAll = sections.every((s) => newMap.get(s.name) === true);
        newMap.set(parentFilter.name, isSelectAll);

        const isAnySelected = checkAnySelected(sections);

        setIsAllSelected(isSelectAll);
        setIsAnySelected(isAnySelected && !isSelectAll);
        return newMap;
      });
    };

    const isAnyChildFilterChecked = (parent: Filter, value?: Filter) => {
      const { type, filters: parentFilters } = parent;
      const valueFilters = value?.filters || [];

      const flattenFilters = (filters: Filter[]): string[] => {
        return filters.reduce((acc: string[], filter: Filter) => {
          acc.push(filter.name);

          if (filter.filters && filter.filters.length > 0) {
            acc.push(...flattenFilters(filter.filters));
          }

          return acc;
        }, []);
      };
      const keys = flattenFilters(value ? valueFilters : parentFilters);

      switch (type) {
        case FilterType.MULTI_SELECT: {
          const found = keys.find((key) => !!checkedMap.get(key));
          if (found) return true;
          break;
        }
      }

      return false;
    };

    const clearAll = () => {
      setCheckedMap(new Map());
      setIsAllSelected(false);
      setIsAnySelected(false);
    };

    useImperativeHandle(ref, () => ({
      handleOnChange,
      clearAll,
    }));

    return (
      <Stack sx={{ overflow: 'none', pt: 2 }}>
        {type === FilterType.CHIP_INPUT ? (
          <Stack direction={'row'} alignItems={'center'} sx={{ pt: 1, px: 1 }}>
            <Stack gap={1} sx={{ width: 1 }}>
              {parentFilter.helper_text && (
                <Typography level='body-md' color='primary'>
                  {parentFilter.helper_text}
                </Typography>
              )}
              <Autocomplete
                multiple
                placeholder={parentFilter.display_name}
                sx={{ width: 1 }}
                options={parentFilter.options || []}
                getOptionLabel={(option) => option.display_name}
                value={defaultValue.map((d) => ({ name: d, display_name: d }))}
                getOptionDisabled={(option) => !!defaultValue.find((val) => val === option.name)}
                onChange={(_e, value) => {
                  updateTextFilter(
                    FilterType.CHIP_INPUT,
                    parentFilter.name,
                    value.map((d) => d.name)
                  );
                }}
              />
            </Stack>
          </Stack>
        ) : (
          <>
            {sections.length > 1 && (
              <StyledCheckBox
                color='primary'
                indeterminate={isAnySelected}
                label={'(Select all)'}
                defaultChecked={!!checkedMap.get(parentFilter.name)}
                checked={isAllSelected}
                onChange={(e) => {
                  handleOnChange(
                    parentFilter.name,
                    !!e.target.indeterminate ? true : e.target.checked
                  );
                  debouncedUpdateFilter(
                    parentFilter,
                    parentFilter,
                    e.target.indeterminate ? true : e.target.checked
                  );
                }}
                sx={{ mb: 1 }}
              />
            )}
            {(sections || []).map((section) => (
              <React.Fragment key={section.name}>
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  sx={{
                    background: 'transparent',
                  }}
                >
                  {sections.length > 1 && (
                    <IconButton
                      variant='plain'
                      onClick={() => handleShowFilter(section.display_name)}
                      sx={{
                        visibility: section.filters.length > 0 ? 'visible' : 'hidden',
                      }}
                    >
                      <ArrowIconHoverContainer>
                        <AnimatedArrow
                          isOpen={
                            showFilters.has(section.display_name) && section.filters.length > 0
                          }
                        />
                      </ArrowIconHoverContainer>
                    </IconButton>
                  )}
                  <StyledCheckBox
                    color='primary'
                    defaultChecked={!!checkedMap.get(section.name)}
                    label={`${section.display_name} ${section.filters.length > 0 ? `(${getTotalFilterCount(section.name)})` : ''}`}
                    indeterminate={
                      section.filters.length > 0
                        ? isAnyChildFilterChecked(parentFilter, section) &&
                          !checkedMap.get(section.name)
                        : false
                    }
                    checked={!!checkedMap.get(section.name)}
                    onChange={(e) => {
                      handleOnChange(section.name, e.target.checked);
                      debouncedUpdateFilter(parentFilter, section, e.target.checked);
                    }}
                  />
                </Stack>
                <AnimatedBox
                  isOpen={showFilters.has(section.display_name) && section.filters.length > 0}
                >
                  <Filter
                    data={section.filters}
                    parentFilter={parentFilter}
                    checkedMap={checkedMap}
                    isSelected={isSelected}
                    updateFilter={debouncedUpdateFilter}
                    isAnyChildFilterChecked={isAnyChildFilterChecked}
                    getTotalFilterCount={getTotalFilterCount}
                    handleOnChange={handleOnChange}
                  />
                </AnimatedBox>
              </React.Fragment>
            ))}
          </>
        )}
      </Stack>
    );
  }
);

import { useState } from 'react';

import {
  Alert,
  Button,
  Card,
  Stack,
  Step,
  StepIndicator,
  Stepper,
  Typography,
  useColorScheme,
} from '@mui/joy';
import { useAuthInfo, useRedirectFunctions } from '@propelauth/react';
import CalyxLogo from 'jsx:../../assets/icons/logo.svg';
import CalyxLogoDark from 'jsx:../../assets/icons/logoDark.svg';
import { BiCheck } from 'react-icons/bi';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';

import { makePostRequest } from '../../services/axios';

import { AccountForm } from './components/AccountForm';
import { OrganisationForm } from './components/OrganisationForm';
import { PersonalForm } from './components/PersonalForm';
import { defaultError, steps } from './constants';

type ErrorType = {
  firstName: string;
  lastName: string;
  email: string;
  organisation: string;
  role: string;
  annualCarbonCredits: string;
  password: string;
  confirmPassword: string;
  terms1: string;
  terms2: string;
};

type SignUpInfo = {
  firstName: string;
  lastName: string;
  email: string;
  organisation: string;
  role: string;
  annualCarbonCredits: string;
  password: string;
  confirmPassword: string;
};

type TermsAccepted = {
  terms1: boolean;
  terms2: boolean;
};

type SignupPageType = {
  signupType?: 'secondnature' | 'b2b2b';
};

const SignUp: React.FC<SignupPageType> = ({ signupType = 'secondnature' }) => {
  const { loading, isLoggedIn } = useAuthInfo();
  const { mode } = useColorScheme();
  const [activeStep, setActiveStep] = useState(1);
  const [error, setError] = useState<ErrorType>(defaultError);
  const [errorText, setErrorText] = useState([]);
  const [loadingSignUp, setLoadingSignUp] = useState(false);
  const [isValidResponse, setIsValidResponse] = useState(true);
  const { redirectToLoginPage } = useRedirectFunctions();

  const [signUpInfo, setSignUInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    organisation: '',
    role: '',
    annualCarbonCredits: '',
    password: '',
    confirmPassword: '',
  });
  const [termsAccepted, setTermsAccepted] = useState({
    terms1: false,
    terms2: false,
  });
  const navigate = useNavigate();

  if (loading) {
    return <></>;
  }

  if (isLoggedIn) {
    navigate('/');
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target;
    if (name === 'terms1' || name === 'terms2') {
      setTermsAccepted((obj) => ({ ...obj, [name]: checked }));
    } else {
      setSignUInfo({ ...signUpInfo, [name]: value });
    }
  };

  const checkError = () => {
    let updatedError = defaultError;
    if (signUpInfo.firstName === '') {
      updatedError = { ...updatedError, firstName: 'Enter your first name' };
    }
    if (signUpInfo.lastName === '') {
      updatedError = { ...updatedError, lastName: 'Enter your last name' };
    }
    if (signUpInfo.email === '') {
      updatedError = { ...updatedError, email: 'Enter your email address' };
    }
    if (signUpInfo.organisation === '') {
      updatedError = { ...updatedError, organisation: 'Enter your organisation' };
    }
    if (signUpInfo.role === '') {
      updatedError = { ...updatedError, role: 'Enter your role' };
    }
    if (signUpInfo.annualCarbonCredits === '') {
      updatedError = {
        ...updatedError,
        annualCarbonCredits:
          'Enter the annual number of carbon credits purchased by your organisation',
      };
    }
    if (signUpInfo.password === '') {
      updatedError = { ...updatedError, password: 'Enter your password' };
    }
    if (signUpInfo.confirmPassword === '') {
      updatedError = { ...updatedError, confirmPassword: 'Confirm your password' };
    }
    if (!termsAccepted.terms1) {
      updatedError = { ...updatedError, terms1: 'Please accept terms and conditions' };
    }
    if (!termsAccepted.terms2) {
      updatedError = { ...updatedError, terms2: 'Please accept terms and conditions' };
    }
    // Check if password and confirmPassword match and are at least 8 characters long
    if (signUpInfo.password !== '' && signUpInfo.password.length >= 8) {
      if (signUpInfo.password === signUpInfo.confirmPassword) {
        updatedError = { ...updatedError, password: '', confirmPassword: '' };
      } else {
        updatedError = {
          ...updatedError,
          password: 'Passwords do not match',
          confirmPassword: 'Passwords do not match',
        };
      }
    } else {
      updatedError = {
        ...updatedError,
        password: 'Password must be at least 8 characters long',
        confirmPassword: '',
      };
    }
    setError(updatedError);
    return updatedError;
  };
  const handleSubmit = () => {
    const errorCheck = checkError();

    if (activeStep === 1 && !errorCheck.firstName && !errorCheck.lastName && !errorCheck.email) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setError(defaultError);
    }
    if (
      activeStep === 2 &&
      !errorCheck.organisation &&
      !errorCheck.role &&
      !errorCheck.annualCarbonCredits
    ) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setError(defaultError);
    }

    if (
      activeStep === 3 &&
      signUpInfo.password !== '' &&
      signUpInfo.password === signUpInfo.confirmPassword &&
      termsAccepted.terms1 &&
      (signupType === 'b2b2b' ? true : termsAccepted.terms2)
    ) {
      handleSignUp();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSignUp = () => {
    setLoadingSignUp(true);
    setErrorText([]);
    setIsValidResponse(true);
    const signupEndpoint = signupType === 'b2b2b' ? '/user/signup/v2' : '/user/signup';
    makePostRequest(signupEndpoint, signUpInfo)
      .then((res) => {
        if (res) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      })
      .catch((error) => {
        setIsValidResponse(!!error.response.data.valid);
        setErrorText(error.response.data.message || []);
      })
      .finally(() => {
        setLoadingSignUp(false);
      });
  };

  return (
    <>
      <Stack direction={'row'} alignItems={'center'} p={2}>
        {mode === 'light' ? (
          <CalyxLogo width={164} height={36} />
        ) : (
          <CalyxLogoDark width={164} height={36} />
        )}
      </Stack>
      <Stack
        direction={'row'}
        justifyContent={'center'}
        alignItems={'center'}
        height={'auto'}
        m={2}
      >
        <Card
          sx={{
            width: 1,
            maxWidth: '555px',
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          {activeStep === 4 ? (
            <>
              <Typography fontSize={24} fontWeight={'lg'}>
                Account Created
              </Typography>
              <Typography fontSize={18} fontWeight={'md'}>
                Thanks for signing up to the Calyx Global Platform. Please check your inbox, for a
                confirmation email.
              </Typography>
              <Typography fontSize={14} fontWeight={'md'}>
                Note: Please check your spam folder as well
              </Typography>
            </>
          ) : (
            <>
              <Typography fontSize={24} fontWeight={'lg'}>
                Create your account
              </Typography>
              <Stepper>
                {steps.map((step) => (
                  <Step
                    key={`${step.id}-${step.name}`}
                    sx={{
                      fontSize: (theme) => theme.vars.fontSize.sm,
                      justifyContent: { md: 'flex-end', xs: 'flex-start' },
                      flex: { md: step.id === 3 ? 'none' : 1, xs: 1 },
                      flexDirection: { md: 'row', xs: 'column' },
                    }}
                    indicator={
                      <StepIndicator
                        variant='solid'
                        color={activeStep >= step.id ? 'primary' : 'neutral'}
                      >
                        {activeStep <= step.id ? `${step.id}` : <BiCheck />}
                      </StepIndicator>
                    }
                  >
                    {step.name}
                  </Step>
                ))}
              </Stepper>
              {errorText.length > 0 && (
                <Alert
                  variant='soft'
                  color='danger'
                  endDecorator={
                    <Button
                      size='sm'
                      variant='plain'
                      color='danger'
                      onClick={() => setErrorText([])}
                    >
                      CLOSE
                    </Button>
                  }
                >
                  <Stack justifyContent={'flex-start'}>
                    {!isValidResponse ? (
                      <Typography fontSize={'sm'} fontWeight={'md'}>
                        You are not allowed to sign up from this link. Please{' '}
                        <Link to={'https://calyxglobal.com/contact'} target='_blank'>
                          contact us
                        </Link>
                        , and we will reach out to you.
                      </Typography>
                    ) : (
                      errorText.map((errorText) => (
                        <Typography fontSize={'sm'} fontWeight={'md'}>
                          {errorText}
                        </Typography>
                      ))
                    )}
                  </Stack>
                </Alert>
              )}
              <Card
                variant='soft'
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 3,
                }}
              >
                {activeStep === 1 && (
                  <PersonalForm handleChange={handleChange} data={signUpInfo} error={error} />
                )}
                {activeStep === 2 && (
                  <OrganisationForm
                    signupType={signupType}
                    handleChange={handleChange}
                    data={signUpInfo}
                    error={error}
                  />
                )}
                {activeStep === 3 && (
                  <AccountForm
                    signupType={signupType}
                    handleChange={handleChange}
                    data={signUpInfo}
                    termsAccepted={termsAccepted}
                    error={error}
                  />
                )}
                <Stack direction={'row'} gap={2} alignItems={'center'}>
                  {activeStep > 1 && (
                    <Button variant='outlined' size='md' onClick={handleBack} sx={{ flex: 1 }}>
                      Back
                    </Button>
                  )}
                  <Button
                    variant='solid'
                    color='primary'
                    size='md'
                    onClick={handleSubmit}
                    sx={{ flex: 1 }}
                    loading={loadingSignUp}
                  >
                    {activeStep === 3 ? 'Sign Up' : 'Next'}
                  </Button>
                </Stack>
              </Card>
              <Stack direction={'row'} gap={1} alignItems={'center'}>
                <Typography level='body-sm' sx={{ color: (theme) => theme.palette.text.secondary }}>
                  Already have an account ?{' '}
                </Typography>
                <Typography>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      redirectToLoginPage({
                        userSignupQueryParameters: { opt_hint: 'pw,sso' },
                        postLoginRedirectUrl: window.location.href,
                      });
                    }}
                  >
                    <Typography
                      level='body-sm'
                      sx={{ color: (theme) => theme.palette.text.primary }}
                    >
                      Login here
                    </Typography>
                    <MdKeyboardArrowRight size={16} style={{ marginLeft: '5px' }} />
                  </Stack>
                </Typography>
              </Stack>
            </>
          )}
        </Card>
      </Stack>
    </>
  );
};

export { SignUp };
export type { ErrorType, SignUpInfo, TermsAccepted };

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  ChipDelete,
  Dropdown,
  Grid,
  IconButton,
  Input,
  ListDivider,
  Menu,
  MenuButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/joy';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { FaChevronDown, FaChevronUp, FaSearch } from 'react-icons/fa';
import { IoFilterSharp, IoSearchSharp } from 'react-icons/io5';
import { MdCancel, MdError, MdInfoOutline } from 'react-icons/md';
import { useLoaderData, useNavigate, useSearchParams } from 'react-router-dom';

import { FilterSideBar } from '../../components/filter/FilterSideBar';
import Image from '../../components/Image';
import MessagePage from '../../components/MessagePage';
import { RatingCircle } from '../../components/RatingCircle';
import Table from '../../components/table';
import { track } from '../../services/analytics';
import {
  EVENT_CREDIT_RATINGS_SEARCH_PAGE,
  EVNT_ratings_filter_panel_opened,
  EVNT_ratings_filters_applied,
  EVNT_ratings_table_error,
  EVNT_ratings_table_searched,
  EVNT_ratings_view_toggled,
  PGV_search_credit_ratings,
} from '../../services/analytics/events';
import { makeAuthenticatedGetRequest, makeAuthenticatedPostRequest } from '../../services/axios';
import {
  assetBaseUrl,
  searchFilters,
  searchPageCharts,
  searchProjects,
} from '../../services/axios/endpoints';
import { getRiskAreaColorCode } from '../../utils/constants/colorCode';
import { useDeepCompareMemo } from '../../utils/hooks/useDeepCompareMemo';
import useFilters from '../../utils/hooks/useFilters';
import useInfiniteScroll from '../../utils/hooks/useInfiniteScroll';
import { useScrollRestoration } from '../../utils/hooks/useScrollRestoration';

import { Charts } from './Charts';
import {
  cellRenderOrder,
  columns,
  getGHGRatingPercentage,
  getSDGRatingPercentage,
  transformCachedData,
  transformData,
} from './constants';

const Search: React.FC = () => {
  const [data, setData] = useState<any>([]);
  const [searchParams] = useSearchParams();

  const filterDetails = useFilters(
    {
      limit: 20,
      sort: searchParams.has('sort')
        ? JSON.parse(searchParams.get('sort') || '')
        : {
            ghg_rating: 'ASC',
          },
      ...(searchParams.has('following')
        ? { following: JSON.parse(searchParams.get('following') || '') }
        : {}),
      page: 1,
    },
    false,
    false
  );
  const [openFilters, setOpenFilters] = useState(false);
  const [isDropdownOpen, handleIsDropdownOpen] = useState(false);
  const [showCharts, setShowCharts] = useState(filterDetails.searchParams.get('view') === 'chart');
  const theme = useTheme();
  const hasRestoredScroll = useRef(false);
  const navigate = useNavigate();
  const cachedDataRef = useRef<NodeJS.Timeout | null>(null);
  const [loadingCachedData, setLoadingCachedData] = useState(false);
  const { scrollY, setScrollYOffset } = useScrollRestoration('search-table');
  const { prevPath } = useLoaderData() as { prevPath: string };
  const shouldLoadCached = useMemo(() => prevPath === '/executive_summary', [prevPath]);
  const stableFilters = useDeepCompareMemo(filterDetails.filters);
  const isFilterApplied = useMemo(
    () => filterDetails.appliedFilters.length > 0,
    [filterDetails.appliedFilters]
  );

  const allProjectsCall: any = async (pageProps: any, filter: any) => {
    const nextPage = pageProps?.pageParam || 1;
    if (nextPage === 1) {
      setData([]);
      const scrollContainer = filterDetails.paginationState.scrollParentRef;
      if (scrollContainer) {
        scrollContainer.scrollTop = 0;
      }
    }
    try {
      const { data }: any = await makeAuthenticatedPostRequest<unknown>(
        searchProjects,
        { ...filter, page: nextPage, limit: filterDetails.paginationState.limit },
        {
          signal: pageProps.signal,
        }
      );

      const { setTotalCount, setPage, setShowCount } = filterDetails.paginationState;
      setTotalCount(data?.projects?.total_projects || 0);
      setShowCount(data?.projects?.total_count || 0);
      setPage(data?.projects?.current_page || 1);
      setData((prevData) => [...prevData, ...transformData(data?.projects?.data || [])]);
      return data;
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message || error.message;
      track(EVNT_ratings_table_error, EVENT_CREDIT_RATINGS_SEARCH_PAGE, {
        error_type: errorMessage,
      });
      throw new Error(errorMessage);
    }
  };

  const { data: filterData } = useQuery({
    queryKey: [`projects-filter-meta`],
    queryFn: async () => await makeAuthenticatedGetRequest(searchFilters, {}),
    select: (data) => data?.data?.data,
  });

  const {
    isFetching: isPending,
    isLoading,
    fetchNextPage,
    hasNextPage,
    data: projectsData,
    error,
  } = useInfiniteQuery<unknown, Error>({
    queryKey: ['get-all-projects', stableFilters],
    queryFn: async (props) => {
      return await allProjectsCall({ ...props }, filterDetails.filters);
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage: any) => {
      const total = lastPage?.projects?.total_count;
      if (total <= data.length) return null;
      if ((lastPage?.projects?.data || []).length === 0) return null;
      return (lastPage?.projects?.current_page || 1) + 1;
    },
    staleTime: shouldLoadCached ? Infinity : 300,
  });

  const { data: statsData, isLoading: statsLoading } = useQuery({
    queryKey: ['project-stats', stableFilters],
    queryFn: async ({ signal }) => {
      return makeAuthenticatedPostRequest(searchPageCharts, filterDetails.filters, {
        signal,
      });
    },
    select: (data) => data?.data?.result,
  });

  // debouce until cached data is updated
  useEffect(() => {
    if (cachedDataRef?.current) clearTimeout(cachedDataRef.current);
    setLoadingCachedData(true);
    cachedDataRef.current = setTimeout(() => {
      if (shouldLoadCached && projectsData) {
        const cachedData: any[] = transformCachedData(projectsData?.pages || []);
        const { setTotalCount, setPage, setShowCount } = filterDetails.paginationState;
        setTotalCount((projectsData?.pages[0] as any)?.projects?.total_projects || 0);
        setShowCount((projectsData?.pages[0] as any)?.projects?.total_count || 0);
        setPage(
          ((projectsData?.pageParams as any).length
            ? (projectsData?.pageParams as any)
            : [1]
          )?.pop()
        );
        setData(cachedData.flat());
      }
      setLoadingCachedData(false);
    }, 500);
    return () => {
      if (cachedDataRef.current) clearTimeout(cachedDataRef.current);
    };
  }, [projectsData?.pages]);

  useEffect(() => {
    if (!hasRestoredScroll.current && !showCharts && data.length > 0) {
      const scrollContainer = filterDetails.paginationState.scrollParentRef;

      if (!scrollContainer) return;

      scrollContainer.scrollTop = scrollY;
      setScrollYOffset(0); // reset scroll after set
      hasRestoredScroll.current = true;
    }
  }, [filterDetails.paginationState.scrollParentRef, showCharts, data.length]);

  const fetchMoreOnBottomReached = useCallback(() => {
    if (!isPending && hasNextPage) {
      fetchNextPage();
    }
  }, [isPending, hasNextPage]);

  const loaderRef = useInfiniteScroll({
    scrollParentRef: filterDetails.paginationState.scrollParentRef,
    fetchMoreOnBottomReached,
    enabled: !!filterDetails.paginationState.scrollParentRef && !!data.length && !showCharts,
  });

  useEffect(() => {
    if (filterData) {
      filterDetails.updateFilterMap(filterData);
    }
  }, [filterData]);

  useEffect(() => {
    if (filterDetails.nonPayloadFilters['view']) {
      setShowCharts(filterDetails.nonPayloadFilters['view'] === 'chart');
    }
  }, [filterDetails.nonPayloadFilters['view']]);

  useEffect(() => {
    if (filterDetails.searchParams.get('view') === 'chart') {
      setShowCharts(true);
    } else {
      setShowCharts(false);
    }
  }, [filterDetails.searchParams.get('view')]);

  useEffect(() => {
    if (filterDetails.allAppliedFilterCount === 0) {
      handleIsDropdownOpen(false);
    }
  }, [filterDetails.allAppliedFilterCount]);

  const updateScrollY = () => {
    const scrollYOffset = filterDetails.paginationState.scrollParentRef?.scrollTop;
    setScrollYOffset(scrollYOffset || 0);
  };

  const handleOnRowClicked = (row) => {
    updateScrollY();
    navigate(
      `/executive_summary?${new URLSearchParams({
        standard: row?.standard || '',
        id: row?.id || '',
        cp_no: String(row?.crediting_period_id) || '',
      }).toString()}`
    );
  };

  const handleOpenFilters = () => {
    setOpenFilters(true);
    track(EVNT_ratings_filter_panel_opened, EVENT_CREDIT_RATINGS_SEARCH_PAGE);
  };

  const toggleChartView = () => {
    setShowCharts((p) => {
      filterDetails.setSearchParams((prev) => {
        prev.set('view', p ? 'list' : 'chart');
        return prev;
      });
      if (!!p) {
        hasRestoredScroll.current = false;
      } else {
        hasRestoredScroll.current = true;
      }
      updateScrollY();
      return !p;
    });
    track(EVNT_ratings_view_toggled, EVENT_CREDIT_RATINGS_SEARCH_PAGE, {
      toggle_applied: showCharts ? 'Charts' : 'List',
    });
  };

  useEffect(() => {
    track(PGV_search_credit_ratings, EVENT_CREDIT_RATINGS_SEARCH_PAGE);
  }, []);

  useEffect(() => {
    const getSelectedParentValues = (key: string) =>
      filterData
        ?.find(({ name }) => name === key)
        ?.filters?.filter(({ filters = [] }) => {
          return filters?.some(({ name }) => {
            return Array.from(filterDetails?.getFilterValue(key) ?? {})?.includes(name);
          });
        })
        ?.map(({ display_name }) => display_name);

    const getArrayFromSelectedFilters = (key: string) =>
      Array.from(filterDetails?.getFilterValue(key) ?? {}) as string[];

    const getAppliedFilterCategories = () => {
      const appliedFilterKeys = new Set(
        Array.from(filterDetails?.appliedFilters)?.map(({ topLevelParentKey }) => topLevelParentKey)
      );
      return filterData
        ?.filter(({ name }) => appliedFilterKeys.has(name))
        ?.map(({ display_name }) => display_name);
    };

    if (isFilterApplied) {
      const appliedRegions = getSelectedParentValues('country');
      const appliedProjectCategories = getSelectedParentValues('project_type');

      const eventPayload = {
        filters_applied: getAppliedFilterCategories(),
        filter_project_category_values: appliedProjectCategories,
        filter_project_type_values: getArrayFromSelectedFilters('project_type'),
        filter_region_values: appliedRegions,
        filter_country_values: getArrayFromSelectedFilters('country'),
        filter_ghg_rating_values: getArrayFromSelectedFilters('ghg_rating'),
        filter_sdg_rating_values: getArrayFromSelectedFilters('sdg_rating'),
        filter_es_risks_values: getArrayFromSelectedFilters('esr'),
        filter_methodology_values: getArrayFromSelectedFilters('methodology'),
        filter_attributes_values: getArrayFromSelectedFilters('project_attribute'),
        filter_confirmed_sdg_values: getArrayFromSelectedFilters('confirmed_sdg'),
        filter_sdg_cert_values: getArrayFromSelectedFilters('sdg_certificate'),
      };
      track(EVNT_ratings_filters_applied, EVENT_CREDIT_RATINGS_SEARCH_PAGE, eventPayload);
    }
  }, [openFilters]);

  useEffect(() => {
    if (filterDetails.search?.length > 0) {
      track(EVNT_ratings_table_searched, EVENT_CREDIT_RATINGS_SEARCH_PAGE, {
        search_term: filterDetails.search,
      });
    }
  }, [filterDetails.search]);

  return (
    <Stack gap={1} px={3} py={'12px'}>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography level='h4'>Credit ratings</Typography>
        {isLoading ? (
          <Typography level='body-md' color='primary'>
            ... out of {filterDetails.paginationState.totalCount || '...'} projects
          </Typography>
        ) : (
          <Typography level='body-md' color='primary'>
            {showCharts ? statsData?.filteredCount : filterDetails.paginationState.showCount} out of{' '}
            {showCharts ? statsData?.totalCount : filterDetails.paginationState.totalCount} ratings
          </Typography>
        )}
      </Stack>
      <Stack gap={2}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Input
            placeholder='Search all Calyx-rated projects'
            sx={{ minWidth: '560px', display: showCharts ? 'none' : '' }}
            type='text'
            defaultValue={filterDetails.search}
            ref={filterDetails.inputRef}
            onChange={filterDetails.handleSearch}
            endDecorator={
              filterDetails.search?.length > 0 ? (
                <IconButton onClick={filterDetails.clearSearch}>
                  <MdCancel color={theme.palette.text.tertiary} size={24} />
                </IconButton>
              ) : (
                <FaSearch />
              )
            }
          />

          <Stack direction={'row'} gap={2} ml={'auto'}>
            <ButtonGroup>
              <Button
                variant={showCharts ? 'outlined' : 'solid'}
                color={showCharts ? 'neutral' : 'primary'}
                onClick={toggleChartView}
              >
                List
              </Button>
              <Button
                variant={showCharts ? 'solid' : 'outlined'}
                color={showCharts ? 'primary' : 'neutral'}
                onClick={toggleChartView}
              >
                Chart
              </Button>
            </ButtonGroup>
            {filterDetails.allAppliedFilterCount > 0 && (
              <Dropdown
                open={isDropdownOpen}
                onOpenChange={(_, isOpen: boolean) => {
                  handleIsDropdownOpen(isOpen);
                }}
              >
                <MenuButton
                  disabled={!filterDetails.appliedFilters.length}
                  endDecorator={isDropdownOpen ? <FaChevronUp /> : <FaChevronDown />}
                >
                  {`${filterDetails.allAppliedFilterCount} Filter${filterDetails.allAppliedFilterCount > 1 ? 's' : ''} Applied`}
                </MenuButton>
                <Menu
                  placement='bottom-end'
                  sx={{ width: '400px', p: 1 }}
                  autoFocus={isDropdownOpen}
                  ref={(node) => {
                    if (isDropdownOpen) node?.focus();
                  }}
                  onBlur={(e) => {
                    if (!e.relatedTarget) {
                      handleIsDropdownOpen(false);
                    }
                  }}
                >
                  <Stack
                    direction={'row'}
                    gap={1}
                    sx={{
                      p: 1,
                      pb: 2,
                      flexWrap: 'wrap',
                      maxHeight: '180px',
                      overflowY: 'auto',
                    }}
                  >
                    {filterDetails.appliedFilters.map(({ keyName, valueName, key, childKeys }) => (
                      <Chip
                        title={`${keyName}: ${valueName} ${childKeys.length ? `(${childKeys.length})` : ''}`}
                        endDecorator={
                          <ChipDelete
                            onDelete={() => {
                              filterDetails.removeAppliedFilter(key);
                            }}
                          />
                        }
                        variant='outlined'
                        color='primary'
                        key={key}
                        sx={{ maxWidth: '100%' }}
                      >
                        {keyName}: {valueName} {childKeys.length ? `(${childKeys.length})` : ''}
                      </Chip>
                    ))}
                  </Stack>
                  <ListDivider sx={{ background: (theme) => theme.palette['border']['border'] }} />
                  <Button
                    variant='outlined'
                    sx={{ ml: 'auto', mt: 1 }}
                    onClick={() => {
                      handleIsDropdownOpen(false);
                      filterDetails.clearFilter();
                    }}
                  >
                    Clear all filters
                  </Button>
                </Menu>
              </Dropdown>
            )}
            <Button variant='outlined' onClick={handleOpenFilters} endDecorator={<IoFilterSharp />}>
              Filter
            </Button>
          </Stack>
        </Stack>
        <FilterSideBar
          open={openFilters}
          title='All Filters'
          onClose={() => setOpenFilters(false)}
          sections={(filterData as any) || []}
          filterDetails={filterDetails}
          hasReset
        />
        {showCharts ? (
          <Charts data={statsData} isLoading={statsLoading} />
        ) : (
          <Table
            style={{
              '--TableCell-paddingY': '6px',
              '--TableCell-paddingX': (theme) => theme.spacing(2),
            }}
            rowKey='_id'
            bodyStyle={{ height: 'calc(100vh - 230px)' }}
            hasInfiniteData
            defaultSort={{ sdg_impact_rating: 'DESC', default: 'ASC' }}
            columns={columns}
            cellRenderOrder={cellRenderOrder}
            data={data}
            isLoading={isPending || isLoading || loadingCachedData}
            onRowClick={handleOnRowClicked}
            paginationState={filterDetails.paginationState}
            toggleSort={filterDetails.toggleSort}
            getSortValue={filterDetails.getSortValue}
            scrollArea='both'
            loaderRef={loaderRef}
            hasNextPage={hasNextPage}
            sort={filterDetails.filters['sort'] || ({} as any)}
            isStickyHeader
            error={
              error ? (
                <MessagePage
                  title='Something went wrong'
                  description='We couldn’t load your results. Refresh the page to try again.'
                  hasHeading={false}
                  iconProps={{
                    size: 56,
                  }}
                  headingProps={{
                    level: 'h4',
                  }}
                  descriptionProps={{
                    level: 'title-md',
                  }}
                  Icon={MdError}
                  action={() => {
                    window.location.reload();
                  }}
                  actionText='Refresh'
                />
              ) : null
            }
            noDataMessage={
              <MessagePage
                title='No Results Found'
                description={'Please adjust your filter selection or search terms.'}
                hasHeading={false}
                iconProps={{
                  size: 56,
                }}
                headingProps={{
                  level: 'h4',
                }}
                descriptionProps={{
                  level: 'title-md',
                  textAlign: 'center',
                }}
                Icon={IoSearchSharp}
              />
            }
            customCell={(data, key) => {
              if (key === 'project_name') {
                return (
                  <Stack alignItems={'start'}>
                    <Typography
                      level='body-sm'
                      fontWeight={400}
                      textAlign={'start'}
                      alignItems={'start'}
                      display={'flex'}
                      component={'div'}
                      sx={{
                        color: (theme) => theme.palette.text.primary,
                      }}
                    >
                      {data['is_new'] && (
                        <Chip color='primary' size='sm' component={'span'} variant='solid'>
                          New
                        </Chip>
                      )}
                      <Typography ml={0.5}>{data[key]}</Typography>
                    </Typography>
                  </Stack>
                );
              }
              if (key === 'ghg_rating') {
                return (
                  <Stack
                    display={'flex'}
                    justifyContent={'center'}
                    spacing={0.5}
                    height={'100%'}
                    alignItems={'center'}
                  >
                    <RatingCircle
                      type='ghg'
                      percentage={getGHGRatingPercentage(data.ghg_rating_value)}
                      rating={data.ghg_rating}
                      size='sm'
                    />
                  </Stack>
                );
              }

              if (key === 'sdg_impact_rating' && data.sdg_impact_rating !== 'Not certified') {
                return data.sdg_impact_rating ? (
                  <Stack justifyContent={'center'} alignItems={'center'}>
                    <RatingCircle
                      type='sdg'
                      percentage={getSDGRatingPercentage(data.sdg_impact_rating_value)}
                      rating={data.sdg_impact_rating}
                      size='sm'
                    />
                  </Stack>
                ) : null;
              }
              if (key === 'sdg_impact_rating' && data.sdg_impact_rating === 'Not certified') {
                return data.sdg_impact_rating ? (
                  <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
                    <Typography
                      level='body-sm'
                      fontWeight={400}
                      textAlign={'start'}
                      alignItems={'start'}
                      display={'flex'}
                      component={'div'}
                      sx={{
                        color: (theme) => theme.palette.text.primary,
                      }}
                    >
                      {data[key]}
                    </Typography>
                    <Tooltip
                      title={`Measures a project's contributions to the UN's Sustainable Development Goals (SDGs), on a scale of +1 (low impact) to +5 (high impact). Calyx Global only rates SDG contributions that have been certified by a recognized carbon crediting program and have received third-party verification.`}
                      sx={{ maxWidth: 300 }}
                      placement='right'
                    >
                      <Stack alignItems={'center'} justifyContent={'center'} ml={'4px'}>
                        <MdInfoOutline size={16} />
                      </Stack>
                    </Tooltip>
                  </Stack>
                ) : null;
              }
              if (key === 'esr') {
                return data.esr.risks.length > 0 ? (
                  <Stack direction={'row'} gap={1} alignItems={'center'}>
                    <Grid
                      container
                      rowGap={'2px'}
                      columnGap={'2px'}
                      columns={5}
                      minWidth={50}
                      width={50}
                    >
                      {data.esr.risks.map((risk, index) => (
                        <Grid
                          xs={1}
                          sx={{
                            width: '8px', // Fixed width for each box
                            height: '8px', // Fixed height for each box
                            backgroundColor: (theme) =>
                              getRiskAreaColorCode(theme)[
                                risk === 'no_risk' ? 'no_risks_identified' : risk
                              ],
                          }}
                          key={index}
                        />
                      ))}
                    </Grid>
                    <Typography
                      fontSize={'sm'}
                      textAlign={'start'}
                      fontWeight={'sm'}
                      lineHeight={'normal'}
                      color='primary'
                      sx={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}
                    >
                      {data.esr.description.map((risk, index) => (
                        <Typography component={'span'} whiteSpace={'nowrap'} key={risk}>
                          {risk}
                          {index === data.esr.description.length - 1 ? '' : ', '}
                        </Typography>
                      ))}
                    </Typography>
                  </Stack>
                ) : (
                  <Typography fontSize={'sm'} fontWeight={'sm'} color='primary' textAlign={'start'}>
                    Not screened
                  </Typography>
                );
              }
              if (key === 'project_type') {
                return (
                  <Grid container columnSpacing={1}>
                    <Grid xs={2} alignItems={'center'} justifyContent={'center'} display={'flex'}>
                      <Box
                        sx={{
                          width: '32px',
                          height: '32px',
                          objectFit: 'contain',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {data?.project_type?.logo && (
                          <Image
                            src={`${assetBaseUrl}/${data?.project_type?.logo}`}
                            style={{ height: '100%' }}
                          />
                        )}
                      </Box>
                    </Grid>
                    <Grid xs={10} alignItems={'center'} display={'flex'}>
                      <Typography
                        fontSize={'sm'}
                        fontWeight={'sm'}
                        color='primary'
                        textAlign={'start'}
                        lineHeight={'20px'}
                      >
                        {data?.project_type?.name}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              }
              if (key === 'project_attribute') {
                return (
                  <Stack
                    justifyContent={'flex-start'}
                    direction={'row'}
                    alignItems={'center'}
                    flexWrap={'wrap'}
                    padding={1}
                    gap={1}
                    sx={{ color: (theme) => theme.palette.text.primary, fontSize: 'sm' }}
                  >
                    {data.project_attribute?.length
                      ? data.project_attribute.map((attribute: string) => {
                          return (
                            <Chip variant='outlined' sx={{ fontSize: 'sm' }}>
                              {attribute}
                            </Chip>
                          );
                        })
                      : 'None'}
                  </Stack>
                );
              }
              if (key === 'sdg_certificate') {
                return (
                  <Stack spacing={0.5} alignItems={'start'}>
                    {data.sdg_certificate && data.sdg_certificate?.toLowerCase() !== 'none' ? (
                      <Chip variant='outlined' sx={{ fontSize: 'sm' }}>
                        {data.sdg_certificate}
                      </Chip>
                    ) : (
                      <Typography fontSize={'sm'} color='primary'>
                        None
                      </Typography>
                    )}
                  </Stack>
                );
              }
              return null;
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};

export { Search };

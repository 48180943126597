import { dark } from '../../theme/dark';
import { light } from '../../theme/light';

export const colors = [
  '#6B8E23',
  '#4682B4',
  '#D2691E',
  '#8FBC8F',
  '#DAA520',
  '#B0C4DE',
  '#20B2AA',
  '#778899',
  '#7B68EE',
  '#9ACD32',
  '#CD5C5C',
  '#FF8C00',
  '#BA55D3',
  '#9370DB',
  '#3CB371',
  '#40E0D0',
  '#FF6347',
  '#FFB6C1',
  '#FFD700',
  '#6A5ACD',
  '#ADFF2F',
  '#FF4500',
  '#D2B48C',
  '#8A2BE2',
  '#5F9EA0',
  '#D8BFD8',
  '#2E8B57',
  '#FF7F50',
  '#FF1493',
  '#48D1CC',
  '#C71585',
  '#7FFFD4',
  '#DC143C',
  '#FFA07A',
  '#8B0000',
  '#B22222',
  '#8B4513',
  '#DA70D6',
  '#BDB76B',
  '#556B2F',
  '#8FBC8F',
  '#483D8B',
  '#FF00FF',
  '#EE82EE',
  '#98FB98',
  '#66CDAA',
  '#9932CC',
  '#FA8072',
  '#D3D3D3',
  '#4682B4',
];

export const getSDGRatingColor = (sdgRating: string, isDarkMode: boolean) => {
  return (
    {
      '+5': isDarkMode ? dark.SDG[5] : light.SDG[5],
      '+4': isDarkMode ? dark.SDG[4] : light.SDG[4],
      '+3': isDarkMode ? dark.SDG[3] : light.SDG[3],
      '+2': isDarkMode ? dark.SDG[2] : light.SDG[2],
      '+1': isDarkMode ? dark.SDG[1] : light.SDG[1],
    }?.[sdgRating] ?? null
  );
};

export const getSDGRatingColorV2 = (sdgRating: string, isDarkMode: boolean) => {
  return (
    {
      '+5': isDarkMode ? dark.SDGV2[5] : light.SDGV2[5],
      '+4': isDarkMode ? dark.SDGV2[4] : light.SDGV2[4],
      '+3': isDarkMode ? dark.SDGV2[3] : light.SDGV2[3],
      '+2': isDarkMode ? dark.SDGV2[2] : light.SDGV2[2],
      '+1': isDarkMode ? dark.SDGV2[1] : light.SDGV2[1],
      'Not certified': isDarkMode ? dark.SDGV2['Not certified'] : light.SDGV2['Not certified'],
    }?.[sdgRating] ?? null
  );
};

export const getGHGratingColor = (ghgRating: string, isDarkMode: boolean) => {
  return (
    {
      AAA: isDarkMode ? dark.GHG.AAA : light.GHG.AAA,
      AA: isDarkMode ? dark.GHG.AA : light.GHG.AA,
      A: isDarkMode ? dark.GHG.A : light.GHG.A,
      BBB: isDarkMode ? dark.GHG.BBB : light.GHG.BBB,
      BB: isDarkMode ? dark.GHG.BB : light.GHG.BB,
      B: isDarkMode ? dark.GHG.B : light.GHG.B,
      C: isDarkMode ? dark.GHG.C : light.GHG.C,
      D: isDarkMode ? dark.GHG.D : light.GHG.D,
    }?.[ghgRating] ?? null
  );
};

export const getGHGratingColorV2 = (ghgRating: string, isDarkMode: boolean) => {
  return (
    {
      AAA: isDarkMode ? dark.GHGV2.AAA : light.GHGV2.AAA,
      AA: isDarkMode ? dark.GHGV2.AA : light.GHGV2.AA,
      A: isDarkMode ? dark.GHGV2.A : light.GHGV2.A,
      BBB: isDarkMode ? dark.GHGV2.BBB : light.GHGV2.BBB,
      BB: isDarkMode ? dark.GHGV2.BB : light.GHGV2.BB,
      B: isDarkMode ? dark.GHGV2.B : light.GHGV2.B,
      C: isDarkMode ? dark.GHGV2.C : light.GHGV2.C,
      D: isDarkMode ? dark.GHGV2.D : light.GHGV2.D,
    }?.[ghgRating] ?? null
  );
};

export const getSDGColor = (sdg: number) => {
  if (sdg < 1 || sdg > 17) return '';
  return [
    '#E5243B',
    '#DDA83A',
    '#4C9F38',
    '#C5192D',
    '#FF3A21',
    '#26BDE2',
    '#FCC30B',
    '#A21942',
    '#FD6925',
    '#DD1367',
    '#FD9D24',
    '#BF8B2E',
    '#3F7E44',
    '#0A97D9',
    '#56C02B',
    '#00689D',
    '#19486A',
  ][sdg - 1];
};

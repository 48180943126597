import React from 'react';

import { StyledTh } from './styles';

type ColumnProps = React.ThHTMLAttributes<HTMLTableCellElement> & {
  isSortable?: boolean;
  isPinned?: boolean;
};

const Column: React.FC<React.PropsWithChildren<ColumnProps>> = ({
  children,
  isSortable,
  isPinned,
  ...props
}) => {
  return (
    <StyledTh isSortable={isSortable} isPinned={isPinned} {...props}>
      {children}
    </StyledTh>
  );
};

export default Column;

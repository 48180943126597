import { useMemo, useState } from 'react';

import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Skeleton,
  Stack,
  Typography,
  styled,
  useTheme,
} from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

import { makeAuthenticatedGetRequest } from '../../../../services/axios';
import { projectSize } from '../../../../services/axios/endpoints';
import { numFormatter } from '../../../../utils/functions/number';

const StatusCircle = styled(Box)<{ color?: string }>(({ theme, color }) => ({
  border: '1px solid',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  borderColor: theme.palette.border.border,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  backgroundColor: color || theme.palette.border.border,
  width: 15,
  height: 15,
  borderRadius: '50%',
}));

const Line = styled(Box)<{ width?: string; color?: string }>(({ theme, width, color }) => ({
  width: width || '100%',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  background: color || theme.palette.border.border,
  height: '4px',
  borderRadius: '20px',
}));

const DashedLabel = styled(Box)(() => ({
  whiteSpace: 'nowrap',
  '::after': {
    content: `" "`,
    position: 'absolute',
    top: 0,
    right: 0,
    height: '13.5px',
    borderLeft: '1px dashed #808080',
    transform: 'translateY(-75%)',
  },
}));

const ProjectSize = () => {
  const [params] = useSearchParams();
  const theme = useTheme();
  const [value, setValue] = useState<'this_project' | 'all_project'>('this_project');
  const ghg_standard_code = params.get('standard');
  const project_id = params.get('id');
  const showProjectSizeWidget = useMemo(
    () => ghg_standard_code !== 'ACR' && ghg_standard_code !== 'CAR',
    [ghg_standard_code]
  );
  const { data, isLoading, isRefetching, isFetched } = useQuery({
    queryKey: [`project-size`, value],
    queryFn: async () =>
      makeAuthenticatedGetRequest(projectSize, {
        params: { project_id, ghg_standard_code, filter: value },
      }),
    select: (data) => data?.data.graph,
    enabled: showProjectSizeWidget,
  });

  const handleChange = (newValue: 'this_project' | 'all_project') => {
    setValue(newValue);
  };

  const medianPercentage = useMemo(
    () => Math.ceil((data?.median / data?.highestTotalEmission) * 100),
    [data]
  );
  const annualEmissionPercentage = useMemo(
    () => Math.ceil((data?.estimatedAnnualEmission / data?.highestTotalEmission) * 100),
    [data]
  );

  if (!showProjectSizeWidget || (isFetched && !data?.hasData)) return null;

  return (
    <Card sx={{ width: 1, gap: 0, p: (theme) => theme.spacing(3) }}>
      <Stack direction='row' justifyContent={'space-between'} alignItems={'center'}>
        <Typography fontSize={20} fontWeight={'xl'} color='primary'>
          Project Size
        </Typography>
        <ButtonGroup size='sm'>
          <Button
            onClick={() => handleChange('this_project')}
            variant={value === 'this_project' ? 'solid' : 'outlined'}
            color='primary'
          >
            This type
          </Button>
          <Button
            onClick={() => handleChange('all_project')}
            variant={value === 'all_project' ? 'solid' : 'outlined'}
            color='primary'
          >
            All projects
          </Button>
        </ButtonGroup>
      </Stack>
      <Stack gap={3}>
        <Typography
          level='body-md'
          sx={{ color: (theme) => theme.palette.text.secondary, mt: (theme) => theme.spacing(2) }}
        >
          Project's forecast of annual emission reduction/removal (tCO2e/year)
        </Typography>
        <Box sx={{ position: 'relative', height: '100px', mt: (theme) => theme.spacing(5) }}>
          {isLoading || isRefetching ? (
            <Skeleton height={100} />
          ) : (
            <Stack gap={1} sx={{ my: 4 }}>
              <Box sx={{ position: 'relative' }} width={`${medianPercentage}%`}>
                <DashedLabel fontSize={'sm'} fontWeight={'lg'}>
                  <Typography
                    component={'span'}
                    level='body-md'
                    color='primary'
                    fontWeight={'sm'}
                    sx={{
                      position: 'absolute',
                      transform:
                        medianPercentage > 80 ? 'translate(0, -140%)' : 'translate(80%, -140%)',
                      right: medianPercentage > 80 ? 0 : '',
                      width: medianPercentage > 80 ? 'fit-content' : '100%',
                    }}
                  >
                    Median {numFormatter(data?.median || 0)} tCO2e
                  </Typography>
                </DashedLabel>
              </Box>
              <Line sx={{ position: 'relative' }} width={'100%'}>
                <Typography
                  component={'span'}
                  level='body-md'
                  color='primary'
                  fontWeight={'sm'}
                  sx={{
                    right: 0,
                    position: 'absolute',
                    top: '-24px',
                    transform: 'translateY(-190%)',
                  }}
                >
                  Max {numFormatter(data?.highestTotalEmission || 0)} tCO2e
                </Typography>
              </Line>
              <Line
                color={theme.palette['SDGDisplay']['11']}
                width={`${annualEmissionPercentage}%`}
              ></Line>
              <Box sx={{ position: 'relative' }} width={`${annualEmissionPercentage}%`}>
                <DashedLabel fontSize={'sm'} fontWeight={'lg'}>
                  <Typography
                    component={'span'}
                    level='body-md'
                    color='primary'
                    fontWeight={'sm'}
                    sx={{
                      position: 'absolute',
                      transform: 'translate(77%, 50%)',
                      width: '100%',
                    }}
                  >
                    {numFormatter(data?.estimatedAnnualEmission || 0)} tCO2e
                  </Typography>
                </DashedLabel>
              </Box>
            </Stack>
          )}
        </Box>
      </Stack>
      <Stack direction='row' gap={3} mt={(theme) => theme.spacing(2)}>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'center' }}>
          <StatusCircle color={theme.palette['SDGDisplay']['11']} />
          <Typography level='body-sm' color='primary'>
            This project
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'center' }}>
          <StatusCircle color={theme.palette['border']['border']} />
          <Typography level='body-sm' color='primary'>
            Other projects
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
};

export default ProjectSize;

import { Box, styled, Typography } from '@mui/joy';

import { TableConfig } from '../../components/DataTable';
import { assetBaseUrl } from '../../services/axios/endpoints';
import { dark } from '../../theme/dark';
import { light } from '../../theme/light';

export const GHG = 'ghg';
export const SDG = 'sdg';

export const getProjectTypeColor = (projectType: string, isDarkMode: boolean) => {
  const projectTypeColorsDictionary = {
    forest_land: isDarkMode ? dark.graph.forestLand : light.graph.forestLand,
    households_community: isDarkMode
      ? dark.graph.householdCommunity
      : light.graph.householdCommunity,
    manufacturing_industry: isDarkMode
      ? dark.graph.manufacturingIndustry
      : light.graph.manufacturingIndustry,
    renewable_electricity: isDarkMode ? dark.graph.renewableEnergy : light.graph.renewableEnergy,
    renewable_energy: isDarkMode ? dark.graph.renewableEnergy : light.graph.renewableEnergy,
    waste: isDarkMode ? dark.graph.waste : light.graph.waste,
    durable_cdr: light.expert.light,
  };
  return projectTypeColorsDictionary?.[projectType];
};

export const getProjectGroupDetails = (data) => {
  if (!data) return { groups: [], details: {} };
  const details = {};
  data.map((d) => {
    d.data.map(({ name, key }) => {
      if (key in details) return;
      details[key] = name;
    });
  });
  const orderedDetails = Object.keys(details)
    .sort()
    .reduce((obj, key) => {
      obj[key] = details[key];
      return obj;
    }, {});
  return {
    groups: Object.keys(orderedDetails),
    details,
  };
};

export const RECENTLY_ADDED = 'recently_added';
export const MOST_RETIRED = 'most_retired';
export const MOST_ISSUED = 'most_issued';
export const FOLLOWING = 'following';

const ProjectTypeLogo = styled('img')(() => ({
  width: '32px',
  height: '32px',
  objectFit: 'contain',
  backgroundPosition: '50% 50%',
}));

export const RECENTLY_ADDED_TABLE_CONFIG: TableConfig = [
  {
    header: 'Project Name',
    dataType: 'string',
    accessorKey: 'name',
    cellWidth: '500px',
    prefixContent: (data) =>
      !!data?.logo ? (
        <Box
          sx={(theme) => ({ color: theme.palette.primary.solidDisabledColor })}
          marginRight={2}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <ProjectTypeLogo src={`${assetBaseUrl}/${data?.logo}`} />
        </Box>
      ) : (
        <Box width={'48px'} />
      ),
  },
  {
    header: 'GHG',
    dataType: 'ghgRating',
    accessorKey: 'ghgRating',
    considerPermission: true,
  },
  {
    header: 'SDG',
    dataType: 'sdgRating',
    accessorKey: 'sdgRating',
    considerPermission: true,
  },
];

export const MOST_RETIRED_TABLE_CONFIG: TableConfig = [
  {
    header: 'Project Name',
    dataType: 'string',
    accessorKey: 'name',
    cellWidth: '500px',
    prefixContent: (data) =>
      !!data?.logo ? (
        <Box
          sx={(theme) => ({ color: theme.palette.primary.solidDisabledColor })}
          marginRight={2}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <ProjectTypeLogo src={`${assetBaseUrl}/${data?.logo}`} />
        </Box>
      ) : (
        <Box width={'48px'} />
      ),
  },
  {
    header: 'Total',
    dataType: 'number',
    accessorKey: 'total',
    suffixContent: (
      <Typography>
        tCO<sub>2</sub>e
      </Typography>
    ),
    considerPermission: true,
  },
];

export const MOST_ISSUED_TABLE_CONFIG: TableConfig = [
  {
    header: 'Project Name',
    dataType: 'string',
    accessorKey: 'name',
    cellWidth: '500px',
    prefixContent: (data) =>
      !!data?.logo ? (
        <Box
          sx={(theme) => ({ color: theme.palette.primary.solidDisabledColor })}
          marginRight={2}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <ProjectTypeLogo src={`${assetBaseUrl}/${data?.logo}`} />
        </Box>
      ) : (
        <Box width={'48px'} />
      ),
  },
  {
    header: 'Total',
    dataType: 'number',
    accessorKey: 'total',
    suffixContent: (
      <Typography>
        tCO<sub>2</sub>e
      </Typography>
    ),
    considerPermission: true,
  },
];

export const FOLLOWING_TABLE_CONFIG: TableConfig = [
  {
    header: 'Project Name',
    dataType: 'string',
    accessorKey: 'name',
    cellWidth: '500px',
    prefixContent: (data) =>
      !!data?.logo ? (
        <Box
          sx={(theme) => ({ color: theme.palette.primary.solidDisabledColor })}
          marginRight={2}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <ProjectTypeLogo src={`${assetBaseUrl}/${data?.logo}`} />
        </Box>
      ) : (
        <Box width={'48px'} />
      ),
  },
  {
    header: 'GHG',
    dataType: 'ghgRating',
    accessorKey: 'ghgRating',
    considerPermission: true,
  },
  {
    header: 'SDG',
    dataType: 'sdgRating',
    accessorKey: 'sdgRating',
    considerPermission: true,
  },
];

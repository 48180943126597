import React, { useState } from 'react';

import {
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Link,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@mui/joy';
import { FaEye } from 'react-icons/fa';
import { FaEyeSlash } from 'react-icons/fa';
import { IoMdInformationCircleOutline } from 'react-icons/io';

import { ErrorType, SignUpInfo, TermsAccepted } from '../SignUp';

import { Terms } from './Terms';

const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  fontSize: theme.vars.fontSize.sm,
  fontWeight: 'normal',
}));

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  fontSize: theme.vars.fontSize.sm,
  fontWeight: theme.vars.fontWeight.md,
}));

const AccountForm: React.FC<{
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error: ErrorType;
  data: SignUpInfo;
  termsAccepted: TermsAccepted;
  signupType: string;
}> = ({ handleChange, error, data, termsAccepted, signupType }) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const theme = useTheme();

  const toggleShowPassword = () => {
    setShowPassword((v) => !v);
  };
  const toggleConfirmPassword = () => {
    setShowConfirmPassword((v) => !v);
  };

  return (
    <>
      <Typography fontSize={'xl'} fontWeight={'xl'} color='primary'>
        Enter your account details
      </Typography>
      <FormControl error={!!error.password} onChange={handleChange}>
        <StyledFormLabel>Password</StyledFormLabel>
        <Input
          type={showPassword ? 'text' : 'password'}
          name='password'
          defaultValue={data.password}
          endDecorator={
            showPassword ? (
              <FaEyeSlash size={20} onClick={toggleShowPassword} />
            ) : (
              <FaEye size={20} onClick={toggleShowPassword} />
            )
          }
        />
        {error.password && (
          <Stack direction={'row'} alignItems={'center'} gap={1}>
            <IoMdInformationCircleOutline
              size={14}
              style={{ marginTop: '6px', color: theme.vars.palette.danger[500] }}
            />
            <StyledFormHelperText>{error.password}</StyledFormHelperText>
          </Stack>
        )}
      </FormControl>
      <FormControl error={!!error.confirmPassword} onChange={handleChange}>
        <StyledFormLabel>Confirm Password</StyledFormLabel>
        <Input
          type={showConfirmPassword ? 'text' : 'password'}
          name='confirmPassword'
          defaultValue={data.confirmPassword}
          endDecorator={
            showConfirmPassword ? (
              <FaEyeSlash size={20} onClick={toggleConfirmPassword} />
            ) : (
              <FaEye size={20} onClick={toggleConfirmPassword} />
            )
          }
        />
        {error.confirmPassword && (
          <Stack direction={'row'} alignItems={'center'} gap={1}>
            <IoMdInformationCircleOutline
              size={14}
              style={{ marginTop: '6px', color: theme.vars.palette.danger[500] }}
            />
            <StyledFormHelperText>{error.confirmPassword}</StyledFormHelperText>
          </Stack>
        )}
      </FormControl>
      <Stack direction={'row'} alignItems={'flex-start'} gap={1}>
        <Checkbox
          name='terms1'
          sx={{ mt: 0.8 }}
          defaultChecked={termsAccepted.terms1}
          onChange={handleChange}
        />
        <Stack>
          <Typography fontSize={'sm'}>
            On behalf of my organization I agree to the{' '}
            <Link
              onClick={() => {
                setOpen(true);
              }}
            >
              Terms and Conditions
            </Link>
            {`, and specifically that the Calyx Global Data may only be used for my organization's
            internal business purposes${signupType === 'b2b2b' ? '' : ' which does not include using the data as an academic research resource'}.`}
          </Typography>
          {error.terms1 && (
            <Typography color='danger' fontSize={'sm'}>
              <IoMdInformationCircleOutline
                size={14}
                style={{ marginTop: '6px', color: theme.vars.palette.danger[500] }}
              />{' '}
              {error.terms1}
            </Typography>
          )}
        </Stack>
      </Stack>

      {signupType !== 'b2b2b' && (
        <Stack direction={'row'} alignItems={'flex-start'} gap={1}>
          <Checkbox
            name='terms2'
            sx={{ mt: 0.8 }}
            defaultChecked={termsAccepted.terms2}
            onChange={handleChange}
          />
          <Stack>
            <Typography fontSize={'sm'}>
              {`I agree on behalf of my organization that for the duration of my organization's
              subscription to Calyx Global services, my organization and Calyx Global may each
              display the other’s business logo, service mark or trademark in materials
              it makes publicly available online or in print or electronic form, in such manner 
              that denotes my organization's use of Calyx Global services but does not imply that either
              endorses the other’s services or business.`}
            </Typography>
            {error.terms2 && (
              <Typography color='danger' fontSize={'sm'}>
                <IoMdInformationCircleOutline
                  size={14}
                  style={{ marginTop: '6px', color: theme.vars.palette.danger[500] }}
                />{' '}
                {error.terms2}
              </Typography>
            )}
          </Stack>
        </Stack>
      )}
      <Terms open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export { AccountForm };

import { useMemo } from 'react';

import { Box, Divider, Stack, Typography } from '@mui/joy';
import { useRecoilValue } from 'recoil';

import LocationPinIcon from 'jsx:../../../assets/icons/location-pin.svg';

import Image from '../../../components/Image';
import { assetBaseUrl } from '../../../services/axios/endpoints';
import { projectState } from '../../../stores/project';
import { arrayToEnglishString } from '../../../utils/functions/array';

import { ActionBar } from './ActionBar';
import { Breadcrumbs } from './BreadCrumps';

const ProjectHeader: React.FC = () => {
  const { projectData } = useRecoilValue(projectState);
  const states = useMemo(
    () => arrayToEnglishString(projectData?.location?.states || []),
    [projectData?.location?.states]
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '50px',
          position: 'fixed',
          px: 2,
          width: 1,
          zIndex: 11,
          left: 0,
          top: '61px',
          background: (theme) => theme.palette.background.surface,
          borderBottom: (theme) => `1px solid ${theme.palette.primary.outlinedBorder}`,
        }}
      >
        <Breadcrumbs title={projectData?.title} />
      </Box>
      <Stack
        sx={{
          maxWidth: '1300px',
          mx: 'auto',
          position: 'sticky',
          top: '49px',
          zIndex: 11,
          background: (theme) => theme.palette.background.body,
        }}
      >
        <Stack sx={{ pt: 2, pb: 3 }} gap={1}>
          <Typography level='h4' color='primary'>
            {projectData?.title}
          </Typography>
          <Stack direction={'row'} gap={1}>
            <Typography
              fontWeight={'md'}
              fontSize={'md'}
              sx={{ color: (theme) => theme.palette.text.secondary }}
            >
              {projectData?.code}
            </Typography>
            <Divider orientation='vertical' sx={{ height: '25px' }} />
            <Stack direction={'row'} alignItems={'center'} gap={0.5}>
              <LocationPinIcon width={24} height={24} />
              <Typography level='body-md' fontWeight={'xl'}>
                {states}
                {!!projectData?.location.state ? ',' : ''} {projectData?.location.country}
              </Typography>
              {projectData?.location?.flag && (
                <Stack
                  sx={{ width: '18px', ml: 1 }}
                  direction={'row'}
                  alignContent={'center'}
                  justifyContent={'center'}
                >
                  <Image
                    src={`${assetBaseUrl}/${projectData?.location?.flag}`}
                    style={{ width: '100%', height: '100%' }}
                  />
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
        <ActionBar />
      </Stack>
    </>
  );
};

export default ProjectHeader;

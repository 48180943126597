import { useEffect, useRef, useCallback } from 'react';

type UseInfiniteScrollProps = {
  scrollParentRef: HTMLDivElement | HTMLBodyElement | null;
  fetchMoreOnBottomReached: (containerRefElement?: HTMLDivElement | HTMLBodyElement | null) => void;
  enabled?: boolean;
};

const useInfiniteScroll = ({
  scrollParentRef,
  fetchMoreOnBottomReached,
  enabled = true,
}: UseInfiniteScrollProps) => {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const sentinelRef = useRef<HTMLDivElement | null>(null);

  // Attach sentinel when it's available
  const setSentinelRef = useCallback(
    (node: HTMLDivElement | null) => {
      sentinelRef.current = node;
      if (!node || !scrollParentRef || !enabled) return;

      // Cleanup previous observer
      if (observerRef.current) observerRef.current.disconnect();

      // Attach new observer
      observerRef.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            fetchMoreOnBottomReached(scrollParentRef);
          }
        },
        {
          root: scrollParentRef,
          rootMargin: '0px 0px 400%',
          threshold: 0,
        }
      );

      observerRef.current.observe(node);
    },
    [enabled, fetchMoreOnBottomReached, scrollParentRef]
  );

  // Reattach observer if scrollParentRef changes
  useEffect(() => {
    if (!scrollParentRef || !sentinelRef.current || !enabled) return;

    if (observerRef.current) observerRef.current.disconnect();

    observerRef.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          fetchMoreOnBottomReached(scrollParentRef);
        }
      },
      {
        root: scrollParentRef,
        rootMargin: '0px 0px 400%',
        threshold: 0,
      }
    );

    observerRef.current.observe(sentinelRef.current);

    return () => observerRef.current?.disconnect();
  }, [scrollParentRef, enabled]);

  return setSentinelRef;
};

export default useInfiniteScroll;

import { useMemo } from 'react';

import { Card, Divider, Grid, Skeleton, Stack, Typography } from '@mui/joy';
import { SxProps, TypographySystem } from '@mui/joy/styles/types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { track } from '../../../../services/analytics';
import {
  EVENT_EXECUTIVE_SUMMARY_PAGE,
  EVNT_exec_ESR_screening_tile_clicked,
} from '../../../../services/analytics/events';
import { projectState } from '../../../../stores/project';
import { getRiskAreaColorCode } from '../../../../utils/constants/colorCode';
import { formatRisks } from '../../../../utils/constants/envAndrisks';

const EnvAndSocialRisk = ({ sxProps }: { sxProps?: SxProps }) => {
  const { projectData, isLoading } = useRecoilValue(projectState);
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const esrRisks = useMemo(() => {
    if (!projectData?.esrRisks) return [];
    const risksCopy = [...projectData.esrRisks];
    return risksCopy.sort((a, b) => {
      const order = ['critical', 'high', 'moderate', 'low', 'no_risks_identified'];
      return (
        order.indexOf(a?.maxRiskLevel || 'no_risks_identified') -
        order.indexOf(b?.maxRiskLevel || 'no_risks_identified')
      );
    });
  }, [projectData]);

  const formatedRisks = useMemo(() => formatRisks(esrRisks), [esrRisks]);

  if (!esrRisks.length) return null;

  if (isLoading) {
    return (
      <Card sx={{ position: 'relative', minHeight: '80px', p: 2, mb: 3 }}>
        <Skeleton width={'90%'} height={'60%'} />
      </Card>
    );
  }

  const handleWidgetClick = () => {
    track(EVNT_exec_ESR_screening_tile_clicked, EVENT_EXECUTIVE_SUMMARY_PAGE, {
      project_id: projectData?.projectId,
      page_url: `${pathname}${search}`,
    });
    navigate({ pathname: '/esr_rating/detailed_risk_findings', search });
  };

  return (
    <Card
      sx={{
        mb: 2,
        gap: 0,
        p: (theme) => theme.spacing(3),
        minHeight: 114,
        cursor: 'pointer',
        ...sxProps,
      }}
      onClick={handleWidgetClick}
    >
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        height={1}
        width={1}
        my={'auto'}
        alignItems={'center'}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          gap={2}
          width={1}
        >
          <Stack>
            <Typography level={'sub1' as keyof TypographySystem} color='primary'>
              Env. & social risks
            </Typography>
            <Stack direction={'row'} flexWrap={'wrap'} alignItems={'center'} gap={1}>
              {formatedRisks.map((risk, index) => (
                <>
                  <Typography key={index} fontSize={'md'} fontWeight={'md'} color='primary'>
                    {risk}
                  </Typography>
                  {index !== formatedRisks.length - 1 && (
                    <Divider
                      sx={{ height: (theme) => theme.spacing(2), my: 'auto', width: '1px' }}
                    />
                  )}
                </>
              ))}
            </Stack>
          </Stack>
          <Grid container rowGap={'2px'} columnGap={'2px'} columns={5} minWidth={70} width={70}>
            {esrRisks.map((risk, index) => (
              <Grid
                xs={1}
                sx={{
                  width: '11px', // Fixed width for each box
                  height: '11px', // Fixed height for each box
                  backgroundColor: (theme) =>
                    getRiskAreaColorCode(theme)[risk?.maxRiskLevel || 'no_risks_identified'],
                }}
                key={index}
              />
            ))}
          </Grid>
        </Stack>
      </Stack>
    </Card>
  );
};

export default EnvAndSocialRisk;

export const formatRisks = (esrRisks) => {
  const risks = {
    critical: 0,
    high: 0,
    moderate: 0,
    low: 0,
    no_risk: 0,
  };

  (esrRisks || [])?.forEach((esr_safeguard_area) => {
    const level = esr_safeguard_area.maxRiskLevel;
    if (risks.hasOwnProperty(level)) {
      risks[level] += 1;
    }
  });

  const riskLabels = {
    critical: 'crit',
    high: 'high',
    moderate: 'mod',
    low: 'low',
  };

  return Object.entries(risks)
    .filter(([level, count]) => count > 0 && level !== 'no_risk')
    .map(([level, count]) => `${count} ${riskLabels[level]}`);
};

export const formatedRiskBoxes = (esrRisks) => {
  const risks = {
    critical: 0,
    high: 0,
    moderate: 0,
    low: 0,
    no_risk: 0,
  };

  (esrRisks || [])?.forEach((esr_safeguard_area) => {
    const level = esr_safeguard_area.maxRiskLevel;
    if (risks.hasOwnProperty(level)) {
      risks[level] += 1;
    }
  });

  const boxes = Object.keys(risks).flatMap((level) => Array(risks[level]).fill(level));
  return boxes;
};

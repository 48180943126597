import { useEffect, useMemo, useRef } from 'react';

import { Box, Button, Card, Chip, Skeleton, Stack, Typography } from '@mui/joy';
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';

import ArticleContent from '../../components/ArticleContent';
import Image from '../../components/Image';
import MessagePage from '../../components/MessagePage';
import { track } from '../../services/analytics';
import {
  EVENT_INSIGHTS_HUB_PAGE,
  EVNT_research_hub_resource_downloaded,
  PGV_research_hub_content_detail,
} from '../../services/analytics/events';
import { getAssetURL } from '../../services/axios/endpoints';
import useFetchAllArticlesByCategory from '../../services/strapi/useFetchAllArticlesByCategory';
import useFetchStrapiArticle from '../../services/strapi/useFetchStrapiArticle';
import { theme } from '../../theme';
import { Article } from '../../types/strapi';
import { dateFormat } from '../../utils/functions/date';

import NextUpArticles from './components/NextUpArticles';

export const InsightsHubArticle = () => {
  const { slug, category } = useParams();
  const navigate = useNavigate();
  const { hideLayoutNavigation } = useOutletContext<{
    hideLayoutNavigation: () => void;
  }>();
  const { data, isLoading, refetch } = useFetchStrapiArticle({ slug: `${slug}` });
  const { data: allArticles, isLoading: isAllArticlesLoading } = useFetchAllArticlesByCategory({
    category: null,
  });
  const topRef = useRef<HTMLDivElement>(null);

  const filterFn = ({ url }: Article) => slug !== url;

  const nextUpArticles = useMemo(() => {
    const articles = allArticles?.filter(filterFn)?.slice(0, 3) ?? [];
    const nextUp = data?.next_up_articles?.slice(0, 3) ?? [];
    return [...nextUp, ...articles]?.slice(0, 3);
  }, [data, allArticles]);

  useEffect(() => {
    refetch();
  }, [slug, category]);

  useEffect(() => {
    topRef.current?.scrollIntoView({ behavior: 'instant', block: 'start', inline: 'start' });
  }, [isLoading]);

  const isResourceType = useMemo(() => !!data?.resource_post_file_for_platform?.url, [data]);

  useEffect(() => {
    if (data) {
      track(PGV_research_hub_content_detail, EVENT_INSIGHTS_HUB_PAGE, {
        title: data?.title,
        category: data?.post_category?.display_name,
        subscriber_only: data?.visibility === 'subscriber_only' ? 'yes' : 'no',
        feature: data?.is_featured ?? 'no',
      });
    }
  }, [data]);

  const handleDownloadResource = () => {
    track(EVNT_research_hub_resource_downloaded, EVENT_INSIGHTS_HUB_PAGE, {
      category,
      title: data?.title,
    });
  };

  const isArticleDataNotFound = useMemo(() => {
    const isNotFound = !isLoading && !data?.title;
    if (isNotFound) hideLayoutNavigation();
    return isNotFound;
  }, [hideLayoutNavigation, isLoading, data]);

  const handleGoToResearchHub = () => {
    navigate('/research-hub');
  };

  return isArticleDataNotFound ? (
    <Box height={'600px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
      <MessagePage
        title='Page not found'
        description='The page you are looking for doesn’t exist or has been moved.'
        hasAction
        actionText='Go to research hub'
        hasHeading={false}
        action={handleGoToResearchHub}
      />
    </Box>
  ) : (
    <Stack
      direction='column'
      spacing={3}
      width={'100%'}
      maxWidth={'1117px'}
      margin={'auto'}
      ref={topRef}
      sx={{ scrollMarginTop: '200px' }}
    >
      <Card>
        <Stack direction={'column'} spacing={3}>
          <Stack direction={'column'} spacing={1}>
            {isLoading ? (
              <Skeleton variant='rectangular' width={'100%'} height={'36px'} />
            ) : (
              <Typography fontSize={'xl3'} fontWeight={500} color='primary' lineHeight={'133%'}>
                {data?.title}
              </Typography>
            )}
            {isLoading ? (
              <Skeleton variant='rectangular' width={'40%'} height={'16px'} />
            ) : (
              <Typography fontSize={'md'} fontWeight={500} color='primary' lineHeight={'142%'}>
                {dateFormat(data?.published_date).monthddyyyy}
              </Typography>
            )}
            {isLoading ? (
              <Skeleton
                variant='rectangular'
                width={'150px'}
                height={'36px'}
                sx={{ borderRadius: '50px' }}
              />
            ) : (
              <Chip variant='outlined' color='primary' size='lg'>
                {data?.post_category?.display_name}
              </Chip>
            )}
          </Stack>
          <Box
            gap={2}
            display={'grid'}
            gridTemplateColumns={{
              xs: 'minmax(0, 1fr)',
              md: isResourceType ? 'repeat(2,minmax(0, 1fr))' : 'minmax(0, 1fr)',
            }}
          >
            {isLoading ? (
              <Skeleton
                variant='rectangular'
                width={'100%'}
                height={{ xs: '200px', lg: '500px' }}
              />
            ) : (
              <Image
                src={data?.cover?.url}
                style={{ width: '100%', borderRadius: theme.radius.md }}
              />
            )}
            <Stack direction={'column'} spacing={2}>
              {isLoading ? (
                <Stack direction={'column'} width={'100%'} spacing={1}>
                  {Array(25)
                    .fill('')
                    .map((_, i) => {
                      return (
                        <Skeleton key={i} variant='rectangular' width={'100%'} height={'16px'} />
                      );
                    })}
                </Stack>
              ) : (
                <Box width={'100%'} overflow={'hidden'}>
                  <ArticleContent>
                    <div dangerouslySetInnerHTML={{ __html: data?.content }} />
                  </ArticleContent>
                </Box>
              )}
              {isResourceType ? (
                <Link
                  to={data?.resource_post_file_for_platform?.url}
                  target='_blank'
                  onClick={handleDownloadResource}
                >
                  <Button sx={{ width: 'max-content' }} color='primary' size='lg'>
                    Download resource
                  </Button>
                </Link>
              ) : null}
            </Stack>
          </Box>
          {isResourceType || !data?.author?.name ? null : (
            <Card variant='soft'>
              <Stack direction={'row'} spacing={3}>
                <Box width={'50px'}>
                  <Image
                    src={getAssetURL('calyx.png')}
                    style={{ width: '50px', aspectRatio: '1/1', borderRadius: '100%' }}
                  />
                </Box>
                <Stack direction={'column'}>
                  <Typography
                    sx={(theme) => {
                      return { color: theme.palette.text.tertiary };
                    }}
                    level='title-sm'
                  >
                    About the author
                  </Typography>
                  <Typography level='h4'>{data?.author?.name}</Typography>
                  <Typography
                    sx={(theme) => {
                      return { color: theme.palette.text.secondary };
                    }}
                    level='body-sm'
                  >
                    {data?.author?.title}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          )}
        </Stack>
      </Card>
      <NextUpArticles articles={nextUpArticles} isLoading={isLoading || isAllArticlesLoading} />
    </Stack>
  );
};

export const getDateSuffix = (d: number) => {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export function dateFormat(dateStr: string) {
  const formatedDate = new Date(dateStr);
  const day = days[formatedDate?.getDay()];
  const month = months[formatedDate?.getMonth()];
  const date = formatedDate?.getDate();
  const suffix = getDateSuffix(formatedDate?.getDate());
  const year = formatedDate?.getFullYear();

  const utcMonth = months[formatedDate?.getUTCMonth()];
  const utcYear = formatedDate?.getUTCFullYear();

  return {
    day,
    month,
    date,
    suffix,
    year,
    utcYear,
    utcMonth,
    mmddyyyy: `${month} ${date}${suffix}, ${year}`,
    mmyyyy: `${utcMonth} ${utcYear}`,
    dayddmmyyyy: `${day}, ${date} ${month} ${year}`,
    monthddyyyy: `${month} ${date}, ${year}`,
  };
}

export const formatTimelineDate = (dateString: string) => {
  const formatDateType = (dateString: string) =>
    `${Number(dateString?.split('-')?.[2])}${getDateSuffix(Number(dateString?.split('-')?.[2]))} ${months[Number(dateString?.split('-')?.[1]) - 1]} ${dateString?.split('-')?.[0]}`;

  const formatMonthType = (dateString: string) =>
    `${months[Number(dateString?.split('-')?.[1]) - 1]} ${dateString?.split('-')?.[0]}`;

  const formatYearType = (dateString: string) => `${dateString?.split('-')?.[0]}`;

  const partArray = dateString?.split(':|:');
  if (partArray?.[1] === 'single') {
    if (partArray?.[0] === 'date') {
      return formatDateType(partArray?.[2]);
    }
    if (partArray?.[0] === 'month') {
      return formatMonthType(partArray?.[2]);
    }
    if (partArray?.[0] === 'year') {
      return formatYearType(partArray?.[2]);
    }
  }
  if (partArray?.[1] === 'period') {
    const start = partArray?.[2]?.split(',')?.[0];
    const end = partArray?.[2]?.split(',')?.[1];
    if (partArray?.[0] === 'date') {
      return `${formatDateType(start)} - ${formatDateType(end)}`;
    }
    if (partArray?.[0] === 'month') {
      return `${formatMonthType(start)} - ${formatMonthType(end)}`;
    }
    if (partArray?.[0] === 'year') {
      return `${formatYearType(start)} - ${formatYearType(end)}`;
    }
  }
};

export const isMoreThanNDays = (date1: string, date2: string, days: number): boolean => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  const diffTime = Math.abs(d2.getTime() - d1.getTime());
  const diffDays = diffTime / (1000 * 60 * 60 * 24);

  return diffDays > days;
};

import { Box, Stack, Tab, tabClasses, TabList, Tabs, Typography } from '@mui/joy';
import { IoMdLock } from 'react-icons/io';
import { MdError } from 'react-icons/md';
import { TbBulb } from 'react-icons/tb';
import { useRecoilValue } from 'recoil';

import MessagePage from '../../../../components/MessagePage';
import { projectState } from '../../../../stores/project';
import { ReadMoreFallback } from '../../ghg_rating/components/readMoreSection/ReadMoreFallback';

import DetailedRiskFindings from './detailedRiskFindings/DetailedRiskFindings';
import FurtherSearch from './furtherResearch/FurtherResearch';
import ProjectComparison from './projectComparison/ProjectComparison';
import SourcesAndMethodology from './sourcesAndMethodology/SourcesAndMethodology';

const DeepDiveAnalysis: React.FC<{
  data: any;
  error: boolean;
  projectComparisonData: any;
  projectComparisonType: 'category' | 'safeguard';
  isProjectComparisonLoading: boolean;
  selectedTabIndex: number;
  setProjectComparisonType: (value: 'category' | 'safeguard') => void;
  handleTab: (event: React.SyntheticEvent | null, value: number | string | null) => void;
  scrollReadMoreIntoView: (param: React.RefObject<HTMLDivElement> | string) => void;
}> = ({
  data,
  error,
  selectedTabIndex,
  projectComparisonData,
  projectComparisonType,
  isProjectComparisonLoading,
  setProjectComparisonType,
  scrollReadMoreIntoView,
  handleTab,
}) => {
  const { projectData, projectCreditingData } = useRecoilValue(projectState);
  const hasPermissionForReadMore = data?.read_more_access ?? false;

  return (
    <>
      <Box
        height={'56px'}
        width={'lg'}
        sx={{
          background: (theme) => theme.palette['linearGradient']['special'],
        }}
        display={'flex'}
        alignItems={'center'}
        paddingX={3}
      >
        <Stack
          direction={'row'}
          gap={1}
          alignItems={'center'}
          sx={(theme) => {
            return { color: theme.palette.common.white };
          }}
        >
          {hasPermissionForReadMore ? <TbBulb size={20} /> : <IoMdLock size={20} />}
          <Typography
            fontSize={'lg'}
            fontWeight={'md'}
            sx={(theme) => {
              return { color: theme.palette.common.white };
            }}
          >
            Deep-Dive Analysis
          </Typography>
        </Stack>
      </Box>
      <Box sx={{ p: 3 }}>
        <Tabs aria-label='Pipeline' value={selectedTabIndex} onChange={handleTab}>
          <TabList
            sx={() => {
              return {
                pt: 1,
                justifyContent: 'space-between',
                [`&& .${tabClasses.root}`]: {
                  flex: 'initial',
                  bgcolor: 'transparent',
                  '&:hover': {
                    bgcolor: 'transparent',
                  },
                },
              };
            }}
          >
            {[
              { title: 'Detailed risk findings', link: '/esr_rating/detailed_risk_findings' },
              { title: 'Project comparison', link: '/esr_rating/project_comparison' },
              { title: 'Further research', link: '/esr_rating/further_research' },
              { title: 'Sources & methodology', link: '/esr_rating/sources_and_methodology' },
            ].map(({ title }, index: number) => {
              return (
                <Tab indicatorInset value={index} key={index}>
                  <Typography fontSize={'lg'} paddingX={3} paddingY={1} fontWeight={'md'}>
                    {title}
                  </Typography>
                </Tab>
              );
            })}
          </TabList>
        </Tabs>
        {hasPermissionForReadMore ? (
          selectedTabIndex !== 1 && error ? (
            <MessagePage
              title='Something went wrong'
              description='We couldn’t load the information. Refresh the page to try again.'
              hasHeading={false}
              iconProps={{
                size: 56,
              }}
              headingProps={{
                level: 'h4',
              }}
              descriptionProps={{
                level: 'title-md',
              }}
              Icon={MdError}
              action={() => {
                window.location.reload();
              }}
              actionText='Refresh'
              border={(theme) => `1px solid ${theme.palette['border']['border']}`}
              background={(theme) => theme.palette.background.body}
              borderRadius={(theme) => theme.spacing(1)}
            />
          ) : (
            <Stack gap={4} py={3}>
              {selectedTabIndex === 0 && (
                <DetailedRiskFindings
                  data={data.detailed_risk_findings}
                  scrollReadMoreIntoView={scrollReadMoreIntoView}
                />
              )}
              {selectedTabIndex === 1 && (
                <ProjectComparison
                  data={projectComparisonData}
                  projectComparisonType={projectComparisonType}
                  handleProjectComparisonType={setProjectComparisonType}
                  isLoading={isProjectComparisonLoading}
                  error={error}
                />
              )}
              {selectedTabIndex === 2 && <FurtherSearch data={data.further_research} />}
              {selectedTabIndex === 3 && (
                <SourcesAndMethodology data={data.methodology_n_sources} />
              )}
            </Stack>
          )
        ) : (
          <ReadMoreFallback
            project={projectData}
            tab='ESR'
            projectCreditingData={projectCreditingData}
            selectedTab={'detailed_risk_findings'}
          />
        )}
      </Box>
    </>
  );
};

export default DeepDiveAnalysis;
